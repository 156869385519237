import React, { useState, useEffect } from 'react';
import { Card, Link, Table } from 'components';
import { getStrategyList } from 'api';
import { t } from 'utils';

const StrategyList = () => {
  const [state, setState] = useState({ loading: true, data: [] });

  useEffect(() => {
    getStrategyList().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  const { loading, data } = state;

  if (loading) {
    return <Card title={t('strategy.list')} loading />;
  }

  return (
    <Card
      title={t('strategy.list')}
      extra={<Link to="/strategies/new">{t('strategy.new')}</Link>}
    >
      <Table
        columns={[
          { key: 'type', title: t('strategy.type') },
          { key: 'name', title: t('strategy.name') },
          { key: 'display', title: t('strategy.display') },
          {
            key: 'action',
            render: ({ key }) => (
              <div className="text-right">
                <Link to={`/strategies/${key}`}>{t('view')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/strategies/${key}/edit`}>{t('edit')}</Link>
              </div>
            )
          }
        ]}
        rows={data.map(d => ({
          key: d.id,
          type: d.type,
          name: d.name,
          display: d.display
        }))}
      />
    </Card>
  );
};

export default StrategyList;
