import get from 'lodash/get';
import React, { Fragment } from 'react';
import { Card, Pair } from 'components';
import { t } from 'utils';

const Task = ({ task, fund, strategy }) => {
  return (
    <Fragment>
      <Card>
        {task.status ? (
          <Pair
            label={t('task.status')}
            value={t(`constants.task.status.${task.status}`)}
          />
        ) : null}
        {fund ? <Pair label={t('task.fund_id')} value={fund.display} /> : null}
        <Pair label={t('task.strategy_id')} value={strategy.display} />
      </Card>
      <TaskVars task={task} strategy={strategy} />
      <TaskConfigs task={task} strategy={strategy} />
    </Fragment>
  );
};

const TaskVars = ({ task, strategy }) => {
  const { name } = strategy;
  let fields = [];
  let prefix = '';

  if (name === 'btc_position_transit') {
    prefix = 'btc';
    fields = [
      'base',
      'counter',
      'baseline_diff',
      'task_volume',
      'trading_type'
    ];
  } else if (name === 'btc_grid_arbitrage') {
    prefix = 'btc';
    fields = [
      'base',
      'counter',
      'baseline_diff',
      'allowed_direction',
      'line_count',
      'line_width',
      'counter_volume_unit',
      'base_contract_holdings',
      'highest_trading_line',
      'lowest_trading_line',
      'grids',
      'last_direction',
      'last_trading_line',
      'insufficient_volume',
      'counter_holdings',
      'unhedged_base_volume'
    ];
  } else if (name === 'hsce_grid_arbitrage') {
    prefix = 'hsce';
    fields = [
      'base',
      'counter',
      'baseline_diff',
      'allowed_direction',
      'exchange_rate_today',
      'exchange_rate_yesterday',
      'net_worth_yesterday',
      'index_close_yesterday',
      'last_trading_line',
      'last_direction',
      'base_holdings',
      'unhedged_base_volume',
      'insufficient_volume',
      'counter_holdings',
      'grids',
      'highest_trading_line',
      'lowest_trading_line'
    ];
  } else if (name === 'hsce_position_transit') {
    prefix = 'hsce.position_transit';
    fields = ['base', 'counter', 'baseline_diff', 'task_volume'];
  }

  return (
    <Card title={t('task.title.vars')}>
      {fields.map(x => {
        const label = t(`task.vars.${prefix}.${x}`);
        let value = get(task, `vars.${x}`);

        if (
          value &&
          (x === 'allowed_direction' ||
            x === 'last_direction' ||
            x === 'trading_type')
        ) {
          value = t(`constants.task.${prefix}.${x}.${value}`);
        }

        if (value && (x === 'base' || x === 'counter')) {
          if (
            name !== 'hsce_grid_arbitrage' &&
            name !== 'hsce_position_transit'
          ) {
            value = t(`constants.contracts.${value}`);
          }
        }

        return <Pair key={x} label={label} value={value} />;
      })}
    </Card>
  );
};

const TaskConfigs = ({ task, strategy }) => {
  const { name } = strategy;
  const prefix = name === 'hsce_grid_arbitrage' ? 'hsce' : 'btc';
  const fields =
    name === 'hsce_grid_arbitrage'
      ? [
          'max_volume_per_trade',
          'base_volume_percentage',
          'counter_volume_percentage',
          'counter_market_price_offset'
        ]
      : [
          'max_volume_per_trade',
          'base_volume_percentage',
          'counter_volume_percentage',
          'taker_price_threshold',
          'base_maker_price_offset',
          'counter_price_spread',
          'counter_market_price_offset'
        ];

  return (
    <Card title={t('task.title.configs')}>
      {fields.map(x => (
        <Pair
          key={x}
          label={t(`task.configs.${prefix}.${x}`)}
          value={get(task, `configs.${x}`)}
        />
      ))}
    </Card>
  );
};

export default Task;
