import { request } from 'utils';

export const getHSCEStats = params => request.get('/hsce/stats', params);
export const getHSCEPrices = params => request.get('/hsce/prices', params);
export const getHSCEDiffs = params => request.get('/hsce/diffs', params);
export const getHSCEExchange = params => request.get('/hsce/exchange', params);

export const updateHSCETask = (id, params) =>
  request.put(`/hsce/tasks/${id}`, params);

export const getHSCEBalanceList = params =>
  request.get('/hsce/balance', params);
export const getHSCEBalance = id => request.get(`/hsce/balance/${id}`);
export const getHsceBalanceTemplate = fundId =>
  request.get(`/hsce/balance/template?fund_id=${fundId}`);
export const calculateHsceBalance = params =>
  request.post('/hsce/balance/cal', params);
export const createHSCEBalance = params =>
  request.post('/hsce/balance', params);
export const updateHSCEBalance = (id, params) =>
  request.put(`/hsce/balance/${id}`, params);

export const getHSCEFundList = params => request.get('/hsce/funds', params);
export const getHSCEFundDetails = (id, params) =>
  request.get(`/hsce/funds/${id}/details`, params);
