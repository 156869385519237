import React from 'react';
import { AdminLayout, Card, StrategyForm } from 'components';
import { createStrategy } from 'api';
import { t } from 'utils';

const StrategyNewPage = ({ navigate }) => {
  return (
    <AdminLayout title={t('strategy.new')}>
      <Card title={t('strategy.new')}>
        <StrategyForm
          values={{ name: '', display: '', fund_ids: [] }}
          onSubmit={(values, { setSubmitting }) => {
            createStrategy(values)
              .then(() => {
                setSubmitting(false);
                navigate('/strategies');
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        />
      </Card>
    </AdminLayout>
  );
};

export default StrategyNewPage;
