import React, { useState } from 'react';
import { Button } from 'components';
import { getHSCEBalanceList } from 'api';
import { t, formatDate } from 'utils';

let link = null;

const HSCEBalanceDownloadButton = ({ fundId, ...props }) => {
  const [loading, setLoading] = useState(false);

  function handleClick(e) {
    e.preventDefault();

    setLoading(true);
    getHSCEBalanceList({ fund_id: fundId }).then(({ data }) => {
      const csv = `${COLUMNS}\n${format(data)}`;
      const href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

      init();
      link.setAttribute('href', href);
      link.setAttribute('download', 'hsce.csv');

      link.click();
      setLoading(false);
    });
  }

  return <Button {...props} loading={loading} onClick={handleClick} />;
};

function init() {
  if (link) return link;
  link = document.createElement('a');
  document.body.appendChild(link);

  return link;
}

function format(data) {
  return data
    .map(d =>
      [
        formatDate(d.date),
        d.details.usdcnh,
        d.details.hkdcnh,
        d.details.np_origin,
        d.details.wp_origin,
        d.origin,
        d.details.np_units,
        d.details.wp_units,
        d.units,
        d.details.np_value,
        d.details.wp_value,
        d.total,
        d.nav,
        d.details.dividends,
        d.details.acc_corr,
        d.acc_nav,
        d.ar,
        d.details.others,
        d.acc_net,
        d.details.pure_nav,
        d.details.pure_acc_corr,
        d.details.pure_acc_nav,
        d.details.div_value,
        d.details.etf_holdings,
        d.details.etf_price,
        d.details.etf_value,
        d.details.hhi_holdings,
        d.details.hhi_price,
        d.details.hhi_value,
        JSON.stringify(d.note)
      ].join(',')
    )
    .join('\r\n');
}

const COLUMNS = [
  t('hscebalance.date'),
  t('hscebalance.details.usdcnh'),
  t('hscebalance.details.hkdcnh'),
  t('hscebalance.details.np_origin'),
  t('hscebalance.details.wp_origin'),
  t('hscebalance.details.origin'),
  t('hscebalance.details.np_units'),
  t('hscebalance.details.wp_units'),
  t('hscebalance.details.units'),
  t('hscebalance.details.np_value'),
  t('hscebalance.details.wp_value'),
  t('hscebalance.details.total'),
  t('hscebalance.nav'),
  t('hscebalance.details.dividends'),
  t('hscebalance.details.acc_corr'),
  t('hscebalance.acc_nav'),
  t('hscebalance.ar'),
  t('hscebalance.details.others'),
  t('hscebalance.acc_net'),
  t('hscebalance.details.pure_nav'),
  t('hscebalance.details.pure_acc_corr'),
  t('hscebalance.details.pure_acc_nav'),
  t('hscebalance.details.div_value'),
  t('hscebalance.details.etf_holdings'),
  t('hscebalance.details.etf_price'),
  t('hscebalance.details.etf_value'),
  t('hscebalance.details.hhi_holdings'),
  t('hscebalance.details.hhi_price'),
  t('hscebalance.details.hhi_value'),
  t('hscebalance.note')
].join(',');

export default HSCEBalanceDownloadButton;
