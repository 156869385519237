import React from 'react';
import { connect } from 'react-redux';
import { BTCLayout, Card, BTCDividendForm } from 'components';
import { t, getParams, navigate } from 'utils';
import { createBTCDividend } from 'api';

const BTCDividendNewPage = ({ fundId }) => {
  function handleSubmit(values, { setSubmitting }) {
    createBTCDividend({ ...values, fund_id: fundId })
      .then(({ data }) => {
        navigate(`/dividends/${data.id}`);
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  }

  return (
    <BTCLayout>
      <Card title={t('btcdividend.new')}>
        <BTCDividendForm onSubmit={handleSubmit} />
      </Card>
    </BTCLayout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || ''
  };
};

export default connect(mapStateToProps)(BTCDividendNewPage);
