import React from 'react';
import { Select } from 'components';
import { t } from 'utils';

const TYPES = ['BASE_SELL_COUNTER_BUY', 'BASE_BUY_COUNTER_SELL'];

const TaskLastDirectionSelect = props => {
  return (
    <Select style={{ width: '100%' }} {...props}>
      <option value="" />
      {TYPES.map(x => (
        <option key={x} value={x}>
          {t(`constants.task.hsce.last_direction.${x}`)}
        </option>
      ))}
    </Select>
  );
};

export default TaskLastDirectionSelect;
