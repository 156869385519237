import React, { useState, useEffect } from 'react';
import { AdminLayout, Card, StrategyForm } from 'components';
import { getStrategy, updateStrategy } from 'api';
import { t } from 'utils';

const StrategyEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getStrategy(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateStrategy(id, values)
      .then(() => {
        setSubmitting(false);
        navigate(`/strategies/${id}`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data } = state;

  if (loading) return <AdminLayout loading />;

  return (
    <AdminLayout title={t('strategy.edit')}>
      <Card title={t('strategy.edit')}>
        <StrategyForm values={data} onSubmit={handleSubmit} />
      </Card>
    </AdminLayout>
  );
};

export default StrategyEditPage;
