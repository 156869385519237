import flatten from 'lodash/flatten';
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'components';
import { getHSCEStats } from 'api';
import { t } from 'utils';

const HSCEStats = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  function load() {
    return getHSCEStats().then(({ data }) => {
      var diffs = data.diffs.map(s => ({
        title:
          t(`constants.task.direction.hsce.${s.direction}`) +
          ' - ' +
          t(`constants.task.hsce.${s.type}`),
        value: s.diff.toFixed(3)
      }));

      var exrates = data.exrates
        .filter(x => x.symbol === 'HKDCNH')
        .map(x => ({
          title: t('hsce.stats.exrate') + ' - ' + x.symbol,
          value: x.value.toFixed(4)
        }));

      var stats = flatten([diffs.slice(0, 2), exrates, diffs.slice(2)]);
      setData(stats);
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, []);

  if (loading) return <Card loading style={{ marginBottom: 20 }} />;

  return (
    <Row>
      {data.map(({ title, value }) => (
        <Col sm={12} md key={title} style={{ marginBottom: 20 }}>
          <Card>
            <StatTitle>{title}</StatTitle>
            <StatValue>{value}</StatValue>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const StatTitle = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
`;

const StatValue = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 100px;
  color: #5e72e4;
`;

export default HSCEStats;
