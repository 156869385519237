import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { Loading } from 'components';
import { request } from 'utils';

class Query extends Component {
  state = {
    loading: true,
    data: null,
    error: null
  };

  load = () => {
    const { query, variables, get, params } = this.props;

    this.setState({ loading: true });

    if (query) {
      request.query(query, variables).then(resp => {
        this.setState({
          data: resp.data,
          loading: false
        });
      });
    } else if (get) {
      request.get(get, params).then(resp => {
        this.setState({
          data: resp.data,
          loading: false
        });
      });
    }
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.get !== prevProps.get ||
      !isEqual(this.props.params, prevProps.params)
    ) {
      return this.load();
    }

    if (
      this.props.query !== prevProps.query ||
      !isEqual(this.props.variables, prevProps.variables)
    ) {
      return this.load();
    }
  }

  render() {
    const { data, loading, error } = this.state;

    if (loading) {
      return <Loading />;
    }

    return this.props.children({ data, loading, error });
  }
}

export default Query;
