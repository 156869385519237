import React from 'react';
import { Layout, AccountList } from 'components';

const AccountListPage = () => {
  return (
    <Layout>
      <AccountList />
    </Layout>
  );
};

export default AccountListPage;
