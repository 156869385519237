import React from 'react';
import { BondLayout, Card, BondBalanceForm, alert } from 'components';
import { createBondBalance } from 'api';
import { t, getParams, startOfToday, navigate } from 'utils';

const BondBalanceNewPage = () => {
  const params = getParams();
  const fundId = params.fund_id || '';

  function handleSubmit(values, { setSubmitting }) {
    if (!fundId) return alert('fund required');

    createBondBalance({ ...values, fund_id: fundId })
      .then(({ data }) => {
        navigate(`/bond/balance/${data.id}`);
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  }

  return (
    <BondLayout>
      <Card title={t('bondbalance.new')}>
        <BondBalanceForm
          initialValues={{ date: startOfToday() }}
          onSubmit={handleSubmit}
        />
      </Card>
    </BondLayout>
  );
};

export default BondBalanceNewPage;
