import React, { useState, useEffect } from 'react';
import { BTCLayout, Card, BTCDividendForm } from 'components';
import { getBTCDividend, updateBTCDividend } from 'api';
import { t, navigate } from 'utils';

const BTCDividendEditPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getBTCDividend(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateBTCDividend(id, values).then(({ data }) => {
      setSubmitting(false);
      navigate(`/dividends/${data.id}`);
    });
  }

  if (loading) return <BTCLayout loading />;

  return (
    <BTCLayout>
      <Card title={t('btcdividend.edit')}>
        <BTCDividendForm initialValues={data} onSubmit={handleSubmit} />
      </Card>
    </BTCLayout>
  );
};

export default BTCDividendEditPage;
