import React from 'react';
import {
  Select,
  Form,
  FormGroup,
  FormSubmit,
  UserSpaceSelect
} from 'components';
import { t } from 'utils';

export const USER_ROLES = [
  'admin',
  'partner',
  'manager',
  'trader',
  'dev',
  'risk'
];

const UserForm = ({ user, onSubmit }) => {
  return (
    <Form initialValues={user} onSubmit={onSubmit} validate={validate}>
      <FormGroup label={t('user.name')} name="name" />
      <FormGroup label={t('user.email')} name="email" />
      <FormGroup label={t('user.mobile')} name="mobile" />

      {user.id ? null : (
        <FormGroup label={t('user.password')} name="password" type="password" />
      )}

      <FormGroup label={t('user.role')} name="role" component={Select}>
        <option value="" />
        {USER_ROLES.map(x => (
          <option key={x} value={x}>
            {t(`constants.user.role.${x}`)}
          </option>
        ))}
      </FormGroup>

      <FormGroup
        label={t('user.spaces')}
        name="spaces"
        component={UserSpaceSelect}
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

function validate(values) {
  const errors = {};

  if (!values.name) {
    errors.name = '不能为空';
  }

  if (!values.email) {
    errors.email = '不能为空';
  }

  if (!values.mobile) {
    errors.mobile = '不能为空';
  }

  if (!values.password && !values.id) {
    errors.password = '不能为空';
  }

  if (!values.role) {
    errors.role = '不能为空';
  }

  return errors;
}

export default UserForm;
