import React from 'react';
import { AdminLayout, Card, UserForm } from 'components';
import { createUser } from 'api';
import { t } from 'utils';

const UserNewPage = ({ navigate }) => {
  return (
    <AdminLayout title={t('user.new')}>
      <Card title={t('user.new')}>
        <UserForm
          user={{
            name: '',
            email: '',
            mobile: '',
            password: '',
            role: 'trader',
            spaces: []
          }}
          onSubmit={(values, { setSubmitting }) =>
            createUser(values).then(user => {
              setSubmitting(false);
              navigate(`/users/${user.id}`);
            })
          }
        />
      </Card>
    </AdminLayout>
  );
};

export default UserNewPage;
