import styled from '@emotion/styled';
import React, { Component } from 'react';
import { request } from 'utils';
import { Loading, Table } from 'components';

class MarketTopStockList extends Component {
  state = {
    loading: true,
    marketType: '1',
    data: []
  };

  load = () => {
    request.query(QUERY, { date: this.props.date }).then(({ data }) => {
      this.setState({
        loading: false,
        data: data.ts_ggt_top10
          .concat(data.ts_hsgt_top10)
          .sort((a, b) => a.rank - b.rank)
      });
    });
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.load();
    }
  }

  render() {
    const { data, loading, marketType } = this.state;

    if (loading) return <Loading />;

    // todo: memoize-one
    return (
      <div>
        <Tabs>
          <TabItem
            active={this.state.marketType === '1'}
            onClick={() => this.setState({ marketType: '1' })}
          >
            沪股通
          </TabItem>
          <TabItem
            active={this.state.marketType === '2'}
            onClick={() => this.setState({ marketType: '2' })}
          >
            上证港股通
          </TabItem>
          <TabItem
            active={this.state.marketType === '3'}
            onClick={() => this.setState({ marketType: '3' })}
          >
            深股通
          </TabItem>
          <TabItem
            active={this.state.marketType === '4'}
            onClick={() => this.setState({ marketType: '4' })}
          >
            深圳港股通
          </TabItem>
        </Tabs>
        <Table
          columns={[
            { key: 'rank' },
            { key: 'ts_code' },
            { key: 'name' },
            { key: 'close' }
          ]}
          rows={data.filter(x => x.market_type === marketType)}
        />
      </div>
    );
  }
}

const Tabs = styled.div``;

const TabItem = styled.span`
  display: inline-block;
  padding: 5px 8px;
  margin-right: 6px;
  cursor: pointer;
  border-radius: 4px;
  background-color: ${p => (p.active ? p.theme.primaryColor : '#fff')};
  color: ${p => (p.active ? '#fff' : 'inherit')};
`;

const QUERY = `
  query($date: date!) {
    ts_ggt_top10(where: { trade_date: { _eq: $date } }) {
      rank
      ts_code
      name
      close
      market_type
      rank
    }
    ts_hsgt_top10(where: { trade_date: { _eq: $date } }) {
      rank
      ts_code
      name
      close
      market_type
    }
  }
`;

export default MarketTopStockList;
