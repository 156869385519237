import React from 'react';
import { Layout } from 'components';
import { MdStats, MdPerson, MdAlarm, MdJournal } from 'icons';
import { Nav, NavItem } from './styled';

const AdminLayout = ({ children, ...props }) => {
  const header = (
    <Nav>
      <NavItem href="/admin" Icon={MdStats} text="menu.admin.index" />
      <NavItem href="/users" Icon={MdPerson} text="menu.admin.users" />
      <NavItem href="/monitors" Icon={MdAlarm} text="menu.admin.monitors" />
      <NavItem href="/users/logs" Icon={MdJournal} text="menu.admin.logs" />
    </Nav>
  );

  return (
    <Layout {...props} header={header}>
      {children}
    </Layout>
  );
};

export default AdminLayout;
