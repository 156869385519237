import React from 'react';
import { Layout, Card, GridForm } from 'components';
import { createGrid } from 'api';
import { t } from 'utils';

const GridNewPage = ({ navigate }) => {
  return (
    <Layout title={t('grid.new')}>
      <Card title={t('grid.new')}>
        <GridForm
          values={{ fund_id: '', name: '', type: '' }}
          onSubmit={(values, { setSubmitting }) => {
            createGrid(values)
              .then(({ data }) => {
                setSubmitting(false);
                navigate(`/grids/${data.id}`);
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        />
      </Card>
    </Layout>
  );
};

export default GridNewPage;
