import React, { Component, Fragment } from 'react';
import { Dialog, Table, Button, Query } from 'components';

class ConceptDialog extends Component {
  state = {
    isOpen: false
  };

  componentDidUpdate(prevProps) {
    if (this.props.concept && this.props.concept !== prevProps.concept) {
      this.setState({ isOpen: true });
    }
  }

  render() {
    const { concept, date } = this.props;

    if (!concept) return null;

    return (
      <Dialog isOpen={this.state.isOpen}>
        <Query get={`/fupan/concept/${concept}?date=${date}`}>
          {({ data }) => (
            <Fragment>
              <Table
                columns={[
                  { key: 'ts_code' },
                  { key: 'open' },
                  { key: 'close' },
                  { key: 'high' },
                  { key: 'low' }
                ]}
                rows={data}
              />
              <div style={{ textAlign: 'right' }}>
                <Button
                  appearance="primary"
                  onClick={() => this.setState({ isOpen: false })}
                >
                  确认
                </Button>
              </div>
            </Fragment>
          )}
        </Query>
      </Dialog>
    );
  }
}

export default ConceptDialog;
