import styles from './monitor.form.module.scss';
import React, { Component } from 'react';
import {
  NumberInput,
  Select,
  Form,
  FormGroup,
  FormSubmit,
  Button
} from 'components';
import { t } from 'utils';
import {
  MONITOR_STATUS,
  MONITOR_VARS,
  EXCHANGES,
  BITMEX_PROMPTS,
  BITMEX_SYMBOLS,
  OKEX_PROMPTS,
  OKEX_SYMBOLS
} from 'constants';

class MonitorForm extends Component {
  constructor(props) {
    super(props);

    const { monitor } = props;
    if (monitor && monitor.template) {
      var prompts = BITMEX_PROMPTS;
      var symbols = BITMEX_SYMBOLS;
      if (monitor.exchange === 'OKEX') {
        prompts = OKEX_PROMPTS;
        symbols = OKEX_SYMBOLS;
      }
      this.state = {
        vars: MONITOR_VARS[monitor.template],
        prompts: prompts,
        symbols: symbols
      };
    } else {
      this.state = {
        vars: [],
        prompts: BITMEX_PROMPTS,
        symbols: BITMEX_SYMBOLS
      };
    }
  }

  handleChange = ({ values, set }, name, value) => {
    if (name === 'template') {
      this.setState({
        vars: MONITOR_VARS[value]
      });
      values = {};
    }
    if (name === 'exchange') {
      if (value === 'BITMEX') {
        set(values, 'prompt', BITMEX_PROMPTS[0]);
        set(values, 'symbol', BITMEX_SYMBOLS[0]);
        this.setState({ prompts: BITMEX_PROMPTS, symbols: BITMEX_SYMBOLS });
      } else {
        set(values, 'prompt', OKEX_PROMPTS[0]);
        set(values, 'symbol', OKEX_SYMBOLS[0]);
        this.setState({ prompts: OKEX_PROMPTS, symbols: OKEX_SYMBOLS });
      }
    }

    return set(values, name, value);
  };

  validate = values => {
    const errors = {};

    if (!values.template) {
      errors.template = '不能为空';
    }

    if (!values.exchange) {
      errors.exchange = '不能为空';
    }

    if (!values.prompt) {
      errors.prompt = '不能为空';
    }

    if (!values.symbol) {
      errors.symbol = '不能为空';
    }

    if (!values.upperLimit) {
      errors.upperLimit = '不能为空';
    }

    if (!values.lowerLimit) {
      errors.upperLimit = '不能为空';
    }

    if (values.lowerLimit > values.upperLimit) {
      errors.upperLimit = '阈值颠倒';
    }

    if (!values.status) {
      errors.status = '不能为空';
    }

    return errors;
  };

  render() {
    const { monitor, onSubmit } = this.props;
    const { vars, prompts, symbols } = this.state;

    return (
      <Form
        initialValues={monitor}
        onSubmit={onSubmit}
        onChange={this.handleChange}
        validate={this.validate}
      >
        {!monitor.id && (
          <FormGroup text={t('monitor.template')}>
            <Select name="template">
              {Object.keys(MONITOR_VARS).map(x => (
                <option value={x} key={x}>
                  {t(`constants.monitor_template.${x}`)}
                </option>
              ))}
            </Select>
          </FormGroup>
        )}
        {monitor.id && (
          <div className={styles.header}>
            <span className={styles.id}>{monitor.id}</span>
          </div>
        )}

        {!monitor.id && (
          <div className={styles.fields}>
            <FormGroup text="交易所" className={styles.thirdfield}>
              <Select name="exchange">
                {EXCHANGES.map(x => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup text="合约期限" className={styles.thirdfield}>
              <Select name="prompt">
                {prompts.map(x => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Select>
            </FormGroup>

            <FormGroup text="币对" className={styles.thirdfield}>
              <Select name="symbol">
                {symbols.map(x => (
                  <option value={x} key={x}>
                    {x}
                  </option>
                ))}
              </Select>
            </FormGroup>
          </div>
        )}

        <FormGroup text="阈值">
          <div className={styles.fields}>
            <NumberInput
              className={styles.thirdfield}
              name="lowerLimit"
              placeholder="下界"
            />
            <span className={styles.split}> {'-'} </span>
            <NumberInput
              className={styles.thirdfield}
              name="upperLimit"
              placeholder="上界"
            />
            <span className={styles.thirdfield} />
          </div>
        </FormGroup>

        <FormGroup text={t('monitor.status')}>
          <Select name="status">
            {MONITOR_STATUS.map(x => (
              <option value={x} key={x}>
                {t(`constants.monitor_status.${x}`)}
              </option>
            ))}
          </Select>
        </FormGroup>

        <FormSubmit>{t('submit')}</FormSubmit>
      </Form>
    );
  }
}

export default MonitorForm;
