import React from 'react';
import styled from '@emotion/styled';
import { Loading as UILoading } from '@swiftcarrot/kit';

// todo: fix props
export const Loading = ({
  isLoading,
  timedOut,
  retry,
  pastDelay,
  ...props
}) => <UILoading {...props} />;

export const Empty = styled.div`
  text-align: center;
  color: #999;
  padding: 20px 0;
`;

export { Helmet } from 'react-helmet';
export { Link } from '@reach/router';
export {
  Kit,
  Container,
  Row,
  Col,
  Card,
  Button,
  NumberInput,
  TextInput,
  SimpleFormat,
  Select,
  Steps,
  alert,
  confirm,
  Dialog,
  DateInput,
  Form,
  FormGroup,
  FormControl,
  FormSubmit,
  useForm,
  Table,
  Checkbox
} from '@swiftcarrot/kit';

export DataTable from './data-table';
export Pair from './Pair';
export LogItem from './log-item';
export Avatar from './avatar';
export Pagination from './pagination';
export AccessControl from './AccessControl';
export LoginForm from './LoginForm';
export SeriesChart from './SeriesChart';
export Query from './query';
export * from './layouts';
export * from './tasks';
export * from './users';
export * from './accounts';
export * from './strategies';
export * from './gateways';
export * from './contracts';
export * from './funds';
export * from './grids';
export * from './hsce';
export * from './btc';
export * from './bond';
