import { request } from 'utils';

export const createTask = params => request.post(`/tasks`, params);
export const getTask = id => request.get(`/tasks/${id}`);
export const getTaskLatest = id => request.get(`/tasks/${id}/latest`);
export const updateTask = (id, params) => request.put(`/tasks/${id}`, params);
export const getTaskList = params => request.get('/tasks', params);
export const getTaskStrategies = params =>
  request.get('/tasks/strategies', params);
export const getTaskLogs = id => request.get(`/tasks/${id}/logs`);
export const updateTaskStatus = (id, status) =>
  request.put(`/tasks/${id}/status`, { status });
export const getTaskOrders = id => request.get(`/tasks/${id}/orders`);
