import React, { useState, useEffect } from 'react';
import {
  HSCELayout,
  Card,
  HSCETaskForm,
  Row,
  Link,
  Col,
  Button,
  HSCETaskStatus,
  HSCEFundDetails
} from 'components';
import { getFund, getFundTasks, updateHSCETask } from 'api';
import { t, canCreateTask } from 'utils';
import { useCurrentUser } from 'context';

const HSCEFundPage = ({ name }) => {
  const [loading, setLoading] = useState(true);
  const [fund, setFund] = useState(null);
  const [task, setTask] = useState(null);

  useEffect(() => {
    setLoading(true);
    getFund(name)
      .then(({ data }) => {
        setFund(data);
        return getFundTasks(data.id);
      })
      .then(({ data }) => {
        const tasks = data;

        if (tasks.length) {
          var t = tasks[0];
          if (
            t.status === 'paused' ||
            t.status === 'confirmed' ||
            t.status === 'pending'
          ) {
            setTask(t);
          } else {
            setTask(null);
          }
        } else {
          setTask(null);
        }
        setLoading(false);
      });
  }, [name]);

  if (loading) {
    return <HSCELayout loading />;
  }

  return (
    <HSCELayout fluid title={fund.display}>
      <HSCETask task={task} fund={fund} />
      <HSCEFundDetails id={fund.id} task={task} />
    </HSCELayout>
  );
};

const HSCETask = ({ task, fund }) => {
  const { user } = useCurrentUser();

  function handleTaskSubmit(values, { setSubmitting }) {
    updateHSCETask(task.id, values)
      .then(() => {
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  if (!task) {
    return (
      <Card
        title={fund.display}
        extra={
          canCreateTask(user, fund) ? (
            <Button
              appearance="primary"
              component={Link}
              to={`/tasks/new?fund_id=${fund.id}`}
            >
              {t('task.new')}
            </Button>
          ) : null
        }
      />
    );
  }

  if (task.status === 'pending') {
    return (
      <Card
        title="任务待审核"
        extra={
          <Button
            appearance="primary"
            component={Link}
            to={`/tasks/${task.id}`}
          >
            {t('view')}
          </Button>
        }
      />
    );
  }

  return (
    <Row>
      <Col md={6}>
        <Card
          title={t('fund.task')}
          extra={<Link to={`/tasks/${task.id}`}>{t('view')}</Link>}
        >
          <HSCETaskForm
            task={task}
            fund={fund}
            strategy={{ name: task.strategy.name }}
            onSubmit={handleTaskSubmit}
          />
        </Card>
      </Col>
      <Col md={6}>
        <HSCETaskStatus id={task.id} strategy={task.strategy.name} />
      </Col>
    </Row>
  );
};

export default HSCEFundPage;
