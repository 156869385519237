import React, { useState, useEffect } from 'react';
import { BondLayout, Card, BondTaskForm, BondTaskDialog } from 'components';
import { createBondTask, getFund } from 'api';
import { t, getParams } from 'utils';

const BondTaskNewPage = ({ navigate }) => {
  const [loading, setLoading] = useState(true);
  const [fund, setFund] = useState(null);
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const params = getParams();

  useEffect(() => {
    getFund(params.fund_id).then(({ data: fund }) => {
      setFund(fund);
      setLoading(false);
    });
  }, [params.fund_id]);

  function handleSubmit(values, { setSubmitting }) {
    setValues(values);
    setOpen(true);
    setSubmitting(false);
  }

  function handleDialogSubmit({ log_entry_created }, { setSubmitting }) {
    return createBondTask({ ...values, log_entry_created })
      .then(({ data }) => {
        setSubmitting(false);
        navigate(`/bond/tasks/${data.id}`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card title={t('bondtask.new')}>
        <BondTaskDialog
          isOpen={open}
          onCancel={() => setOpen(false)}
          onSubmit={handleDialogSubmit}
        />

        <BondTaskForm
          fund={fund}
          initialValues={{
            auditors: [],
            trade_side: 'buy',
            fund_id: fund.id
          }}
          onSubmit={handleSubmit}
        />
      </Card>
    </BondLayout>
  );
};

export default BondTaskNewPage;
