/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useState } from 'react';
import {
  Row,
  Col,
  TextInput,
  ContractSelect,
  BTCTradingTypeSelect,
  BTCAllowedDirectionSelect,
  BTCLastDirectionSelect,
  HSCEAllowedDirectionSelect,
  HSCELastDirectionSelect,
  TaskLongShortSelect,
  GridSelect,
  FormGroup
} from 'components';
import { t } from 'utils';
import { Title } from './styled';

const TaskVarFields = ({ task, strategy }) => {
  const [tab, setTab] = useState(0);
  const { name } = strategy;
  const paused = task.status === 'paused';

  if (name === 'btc_position_transit') {
    return (
      <Fragment>
        <Row>
          <Group
            prefix="btc"
            name="base"
            component={ContractSelect}
            strategy={strategy}
            match="bitmex"
            disabled={paused}
          />
          <Group
            prefix="btc"
            name="counter"
            component={ContractSelect}
            strategy={strategy}
            match="okex"
            disabled={paused}
          />
        </Row>
        <Row>
          <Group prefix="btc" name="baseline_diff" disabled={paused} />
          <Group prefix="btc" name="task_volume" disabled={paused} />
          <Group
            prefix="btc"
            name="trading_type"
            component={BTCTradingTypeSelect}
            disabled={paused}
          />
        </Row>
      </Fragment>
    );
  } else if (name === 'btc_grid_arbitrage') {
    return (
      <Fragment>
        <Row>
          <Group
            prefix="btc"
            name="base"
            component={ContractSelect}
            strategy={strategy}
            match="bitmex"
            disabled={paused}
          />
          <Group
            prefix="btc"
            name="counter"
            component={ContractSelect}
            strategy={strategy}
            match="okex"
            disabled={paused}
          />
        </Row>
        <Row>
          <Group prefix="btc" name="baseline_diff" disabled={paused} />
          <Group
            prefix="btc"
            name="allowed_direction"
            component={BTCAllowedDirectionSelect}
            disabled={paused}
          />
        </Row>

        <Title>{t('task.title.grid')}</Title>

        <Tabs>
          <Tab onClick={() => setTab(0)} active={tab === 0}>
            均网格交易
          </Tab>
          <Tab onClick={() => setTab(1)} active={tab === 1}>
            可变网格交易
          </Tab>
        </Tabs>

        {tab === 0 ? (
          <Fragment>
            <Row>
              <Group prefix="btc" name="line_count" disabled={paused} />
              <Group prefix="btc" name="line_width" disabled={paused} />
            </Row>
            <Row>
              <Group
                prefix="btc"
                name="counter_volume_unit"
                disabled={paused}
              />
              <Group
                prefix="btc"
                name="base_contract_holdings"
                component={TaskLongShortSelect}
                disabled={paused}
              />
            </Row>
            <Row>
              <Group
                prefix="btc"
                name="highest_trading_line"
                disabled={paused}
              />
              <Group
                prefix="btc"
                name="lowest_trading_line"
                disabled={paused}
              />
            </Row>
          </Fragment>
        ) : null}

        {tab === 1 ? (
          <Fragment>
            <Row>
              <Group prefix="btc" name="grids" disabled={paused} />
              <Group
                prefix="btc"
                name="last_direction"
                component={BTCLastDirectionSelect}
                disabled={paused}
              />
            </Row>

            <Row>
              <Group prefix="btc" name="last_trading_line" disabled={paused} />
              <Group
                prefix="btc"
                name="insufficient_volume"
                disabled={paused}
              />
            </Row>

            <Row>
              <Group
                prefix="btc"
                name="counter_holdings"
                component={TaskLongShortSelect}
                disabled={paused}
              />
              <Group
                prefix="btc"
                name="unhedged_base_volume"
                disabled={paused}
              />
            </Row>
            <Row>
              <Group
                prefix="btc"
                name="highest_trading_line"
                disabled={paused}
              />
              <Group
                prefix="btc"
                name="lowest_trading_line"
                disabled={paused}
              />
            </Row>
          </Fragment>
        ) : null}
      </Fragment>
    );
  } else if (name === 'hsce_grid_arbitrage') {
    return (
      <Fragment>
        <Row>
          <Group prefix="hsce" name="base" disabled={paused} />
          <Group prefix="hsce" name="counter" disabled={paused} />
        </Row>
        <Row>
          <Group prefix="hsce" name="baseline_diff" />
          <Group
            prefix="hsce"
            name="allowed_direction"
            component={HSCEAllowedDirectionSelect}
            disabled={paused}
          />
        </Row>
        <Row>
          <Group prefix="hsce" name="exchange_rate_today" />
          <Group prefix="hsce" name="exchange_rate_yesterday" />
          <Group prefix="hsce" name="net_worth_yesterday" />
          <Group prefix="hsce" name="index_close_yesterday" />
        </Row>
        <Row>
          <Group prefix="hsce" name="last_trading_line" disabled={paused} />
          <Group
            prefix="hsce"
            name="last_direction"
            component={HSCELastDirectionSelect}
            disabled={paused}
          />
        </Row>

        <Row>
          <Group prefix="hsce" name="base_holdings" disabled={paused} />
          <Group prefix="hsce" name="unhedged_base_volume" disabled={paused} />
          <Group prefix="hsce" name="insufficient_volume" disabled={paused} />
          <Group prefix="hsce" name="counter_holdings" disabled={paused} />
        </Row>

        <Title>{t('task.title.grid')}</Title>

        <Row>
          <Group
            prefix="hsce"
            name="grids"
            component={GridSelect}
            disabled={paused}
          />
          <Group prefix="hsce" name="highest_trading_line" disabled={paused} />
          <Group prefix="hsce" name="lowest_trading_line" disabled={paused} />
        </Row>
      </Fragment>
    );
  } else if (name === 'hsce_position_transit') {
    return (
      <Row>
        <Group prefix="hsce.position_transit" name="base" disabled={paused} />
        <Group
          prefix="hsce.position_transit"
          name="counter"
          disabled={paused}
        />
        <Group prefix="hsce.position_transit" name="baseline_diff" />
        <Group
          prefix="hsce.position_transit"
          name="task_volume"
          disabled={paused}
        />
      </Row>
    );
  }

  return null;
};

const Group = ({ prefix, name, ...props }) => {
  return (
    <Col>
      <FormGroup
        {...props}
        label={t(`task.vars.${prefix ? `${prefix}.` : ''}${name}`)}
        name={`vars.${name}`}
      />
    </Col>
  );
};

Group.defaultProps = {
  component: TextInput,
  disabled: false
};

const Tabs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Tab = ({ active, ...props }) => {
  return (
    <div
      {...props}
      css={[
        css`
          padding: 10px 0;
          flex: 1;
          text-align: center;
          cursor: pointer;
        `,
        active
          ? css`
              font-weight: bold;
              color: #5e72e4;
              border-top: 2px solid #5e72e4;
              border-left: 2px solid #5e72e4;
              border-right: 2px solid #5e72e4;
              border-top-left-radius: 3px;
              border-top-right-radius: 3px;
            `
          : css`
              border-bottom: 2px solid #5e72e4;
            `
      ]}
    />
  );
};

export default TaskVarFields;
