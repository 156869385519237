import React from 'react';
import {
  AdminLayout,
  Row,
  Col,
  AccountList,
  StrategyList,
  GatewayList,
  ContractList,
  GridList,
  FundList
} from 'components';
import { t } from 'utils';

const AdminDashboardPage = () => {
  return (
    <AdminLayout title={t('menu.admin.index')} fluid>
      <Row>
        <Col md={6}>
          <FundList />
          <ContractList />
          <GridList />
        </Col>
        <Col md={6}>
          <StrategyList />
          <AccountList />
          <GatewayList />
        </Col>
      </Row>
    </AdminLayout>
  );
};

export default AdminDashboardPage;
