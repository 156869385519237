import styled from '@emotion/styled';
import React, { useState } from 'react';
import { NumberInput, Select } from 'components';

const TaskLongShortSelect = ({ name, value, onChange, disabled }) => {
  const [selected, setSelected] = useState('short');
  const [number, setNumber] = useState(value);

  function change(value) {
    onChange(`${value}`, name);
  }

  function handleInputChange(value) {
    setNumber(value);

    if (value === 'long' && value !== '') {
      change(value * -1);
    } else {
      change(value);
    }
  }

  function handleSelectChange(value) {
    setSelected(value);

    if (value === 'long' && number !== '') {
      change(number * -1);
    } else {
      change(number);
    }
  }

  return (
    <Container>
      <NumberInput
        value={number}
        onChange={handleInputChange}
        disabled={disabled}
      />
      <Select
        value={selected}
        onChange={handleSelectChange}
        disabled={disabled}
      >
        <option value="short">空头</option>
        <option value="long">多头</option>
      </Select>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;

  div {
    width: 100px;
    margin-left: 10px;
  }
`;

TaskLongShortSelect.defaultProps = {
  disabled: false
};

export default TaskLongShortSelect;
