import React from 'react';
import { useForm } from 'components';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import { getBondSecurities } from 'api';

const BondSecuritySelect = () => {
  const { values, setValues, getValue } = useForm();

  function handleChange({ label, value }) {
    setValues({
      ...values,
      securities_code: value,
      securities_name: label
    });
  }

  function loadOptions(value) {
    return getBondSecurities({ q: value }).then(({ data }) =>
      data.map(d => ({ label: d.name, value: d.code }))
    );
  }

  return (
    <AsyncSelect
      value={{
        value: getValue('securities_code'),
        label: getValue('securities_name')
      }}
      cacheOptions
      defaultOptions
      components={{ Option: CustomOption }}
      onChange={handleChange}
      loadOptions={loadOptions}
    />
  );
};

const CustomOption = props => {
  const { data } = props;

  return (
    <components.Option {...props}>
      {data.label} <small>{data.value}</small>
    </components.Option>
  );
};

export default BondSecuritySelect;
