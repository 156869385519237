import moment from 'moment';
import styled from '@emotion/styled';
import React from 'react';
import { Link } from '@reach/router';
import { t } from 'utils';
import Avatar from './avatar';

const LogItem = ({ log }) => {
  return (
    <Container>
      {log.user && <Avatar id={log.user.id} />}
      {log.user && <Name to={`/users/${log.user.id}`}>{log.user.name}</Name>}
      <Action>{t(`constants.actions.${log.action}`)}</Action>
      <Link to={logLink(log)}>{log.target_id}</Link>
      <Time>{moment(log.created).format('LLL')}</Time>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
`;

const Name = styled(Link)`
  margin-left: 10px;
  margin-right: 10px;
`;

const Action = styled.span`
  margin-right: 10px;
`;

const Time = styled.span`
  margin-left: auto;
`;

const logLink = ({ action, target_id: id }) => {
  switch (action) {
    case 'create_contract':
    case 'update_contract':
      return `/contracts/${id}/edit`;
    case 'create_nav':
    case 'update_nav':
      return `/navs/${id}`;
    case 'create_grid':
    case 'update_grid':
      return `/grids/${id}`;
    case 'create_strategy':
    case 'update_strategy':
      return `/strategies/${id}`;
    case 'create_gateway':
    case 'update_gateway':
      return `/gateways/${id}`;
    case 'create_user':
    case 'update_user':
      return `/users/${id}`;
    case 'create_account':
    case 'update_account':
      return `/accounts/${id}`;
    case 'create_hsce_balance':
    case 'update_hsce_balance':
      return `/balance/${id}`;
    case 'create_fund':
    case 'update_fund':
      return `/funds/${id}`;
    case 'create_bond_task':
    case 'update_bond_task':
    case 'update_bond_task_status':
      return `/bond/tasks/${id}`;
    default:
      return `/tasks/${id}`;
  }
};

export default LogItem;
