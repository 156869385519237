/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import zipObject from 'lodash/zipObject';
import flatten from 'lodash/flatten';
import { useState, useEffect } from 'react';
import { Card, Pair, Row, Col } from 'components';
import { getTaskLatest } from 'api';
import { t } from 'utils';

const COLORS = ['#2e7d32', '#b71c1c', '#66bb6a', '#e53935'];

const HSCETaskStatus = ({ id, strategy }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);
  const [diffs, setDiffs] = useState([]);

  function load() {
    return getTaskLatest(id).then(({ data }) => {
      setStatus(
        zipObject(data[0].Series[0].columns, data[0].Series[0].values[0])
      );

      var diffs = data[1].Series
        ? flatten(
            data[1].Series.map(s => ({
              direction: s.tags.direction,
              type: s.tags.type,
              diff: s.values[0][1]
            }))
          )
        : [];

      diffs.sort((x, y) => {
        return x.type < y.type ||
          (x.type === y.type && x.direction < y.direction)
          ? 1
          : -1;
      });
      setDiffs(diffs);
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, [id]);

  if (loading) {
    if (strategy === 'hsce_position_transit') {
      return <Card title={t('task.position_status')} loading />;
    }
    return <Card title={t('task.grid_status')} loading />;
  }

  if (strategy === 'hsce_position_transit') {
    return (
      <Card title={t('task.position_status')}>
        <Row>
          <Col>
            <Pair
              label={t('task.vars.hsce.filled_volume')}
              value={status.filled_volume}
            />
            <Pair
              label={t('task.vars.hsce.task_volume')}
              value={status.task_volume}
            />
          </Col>
        </Row>
      </Card>
    );
  }

  return (
    <Card title={t('task.grid_status')}>
      <Row>
        <Col>
          <Pair
            label={t('task.vars.hsce.base_holdings')}
            value={status.base_holdings}
          />
          <Pair
            label={t('task.vars.hsce.counter_holdings')}
            value={status.counter_holdings}
          />
          <Pair
            label={t('task.vars.hsce.last_trading_line')}
            value={status.last_trading_line}
          />
          <Pair
            label={t('task.vars.hsce.last_direction')}
            value={t(`constants.task.direction.hsce.${status.last_direction}`)}
          />
          <Pair
            label={t('task.vars.hsce.insufficient_volume')}
            value={status.insufficient_volume}
          />
          <Pair
            label={t('task.vars.hsce.unhedged_base_volume')}
            value={status.unhedged_base_volume}
          />
        </Col>
        <Col>
          {diffs.map((d, i) => (
            <DiffStat
              key={i}
              color={[COLORS[i]]}
              direction={t(`constants.task.direction.hsce.${d.direction}`)}
              type={t(`constants.task.hsce.${d.type}`)}
              value={d.diff.toFixed(2)}
            />
          ))}
        </Col>
      </Row>
    </Card>
  );
};

const DiffStat = ({ color, direction, type, value }) => {
  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;

        & + & {
          padding-top: 8px;
          border-top: 1px solid #ececec;
          margin-top: 8px;
        }
      `}
    >
      <div
        css={css`
          margin-right: auto;
          font-size: 12px;
        `}
      >
        {direction}
        <br />
        {type}
      </div>
      <div
        css={css`
          font-size: 22px;
          font-weight: bold;
          text-align: right;
        `}
        style={{ color }}
      >
        {value}
      </div>
    </div>
  );
};

export default HSCETaskStatus;
