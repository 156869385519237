import styled from '@emotion/styled';
import React from 'react';
import { alert, Footer, LoginForm } from 'components';
import { auth } from 'utils';
import { useCurrentUser } from 'context';
import config from 'config';

const LoginPage = () => {
  const { setToken } = useCurrentUser();

  function handleSubmit(values, { setSubmitting }) {
    auth
      .post('/token', values)
      .then(({ token }) => {
        setSubmitting(false);
        setToken(token);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
        alert('登录失败');
      });
  }

  return (
    <Container>
      <Logo>
        <img src={config.auth_logo} width="250" />
      </Logo>
      <LoginForm
        initialValues={{
          username: '',
          password: ''
        }}
        onSubmit={handleSubmit}
      />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  width: 368px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
`;

const Logo = styled.div`
  margin-bottom: 20px;
  text-align: center;
`;

export default LoginPage;
