import styled from '@emotion/styled';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  HSCETradeHistoryTable,
  HSCELocalOrdersTable
} from 'components';
import { t } from 'utils';
import { getHSCEFundDetails } from 'api';

const HSCEFundDetails = ({ id, task }) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);

  function load() {
    return getHSCEFundDetails(id).then(({ data }) => {
      setDetails(data);
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, [id]);

  if (loading) {
    return <Card loading style={{ marginTop: 20 }} />;
  }

  return (
    <Fragment>
      <Row style={{ marginTop: 20 }}>
        <Col md={6}>
          <Card title={t('hsce.base_account')}>
            <Account account={details.base_account} />
          </Card>
        </Col>
        <Col md={6}>
          <Card title={t('hsce.counter_account')}>
            <Account account={details.counter_account} />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col md={6}>
          <HSCELocalOrdersTable task={task} />
        </Col>
        <Col md={6}>
          <Card title={t('hsce.remote_orders')}>
            {details.remote_orders.length ? (
              <Table
                columns={COLUMNS}
                rows={details.remote_orders.map(d => ({
                  ...d,
                  key: d.client_side_id
                }))}
              />
            ) : (
              <Placeholder>暂无挂单</Placeholder>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const Account = ({ account }) => (
  <Fragment>
    <Row style={{ marginBottom: 15 }}>
      <Col>
        <b>{t('hsce.balance.total')}</b>
        <br />
        {account.balance.total.toFixed(2)}
      </Col>
      <Col>
        <b>{t('hsce.balance.available')}</b>
        <br />
        {account.balance.available.toFixed(2)}
      </Col>
      <Col>
        <b>{t('hsce.position.amount')}</b>
        <br />
        {account.position.amount}
      </Col>
      <Col>
        <b>{t('hsce.position.available')}</b>
        <br />
        {account.position.available}
      </Col>
    </Row>
    <HSCETradeHistoryTable data={account.trade_history} />
  </Fragment>
);

const COLUMNS = [
  { key: 'create_time', title: t('hsce.order.create_time') },
  { key: 'symbol', title: t('hsce.order.symbol') },
  { key: 'volume', title: t('hsce.order.volume') },
  { key: 'price', title: t('hsce.order.price') },
  {
    key: 'filled_volume',
    title: t('hsce.order.filled_volume')
  },
  {
    key: 'avg_price',
    title: t('hsce.order.avg_price')
  },
  {
    key: 'side',
    title: t('hsce.order.side'),
    render: ({ side }) => (
      <span className={side === 'BUY' ? 'red' : 'green'}>
        {t(`constants.order.side.${side}`)}
      </span>
    )
  },
  {
    key: 'status',
    title: t('hsce.order.status'),
    render: ({ status }) => t(`constants.order.status.${status}`)
  }
];

const Placeholder = styled.div`
  text-align: center;
  padding: 0 20px;
  color: #999;
`;

export default HSCEFundDetails;
