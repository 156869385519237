import styles from './monitor-list.page.module.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { AdminLayout, Button } from 'components';
import { t } from 'utils';
import { getMonitors } from '../../actions';
import { MONITOR_PREFIX } from '../../constants';
import MonitorItem from './MonitorItem';

class MonitorListPage extends Component {
  state = {
    loading: true,
    monitors: []
  };

  load = () => {
    this.setState({ loading: true });
    this.props.dispatch(getMonitors()).then(({ data }) => {
      const alerts = data.reduce((x, a) => {
        if (a.id.startsWith(MONITOR_PREFIX)) {
          const b = Object.keys(a.vars).reduce((x, k) => {
            x[k] = a.vars[k].value;
            return x;
          }, {});
          b.id = a.id;
          b.template = a['template-id'];
          b.status = a.status;
          x.push(b);
        }
        return x;
      }, []);
      this.setState({ monitors: alerts, loading: false });
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { loading, monitors } = this.state;

    if (loading) {
      return <AdminLayout loading />;
    }

    return (
      <AdminLayout title={t('monitor.list')}>
        <div className={styles.header}>
          <Button appearance="primary" component={Link} to="/monitors/new">
            {t('monitor.new')}
          </Button>
        </div>

        <div>
          {monitors.map(monitor => (
            <MonitorItem key={monitor.id} monitor={monitor} />
          ))}
        </div>
      </AdminLayout>
    );
  }
}

export default connect()(MonitorListPage);
