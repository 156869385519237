import React from 'react';
import { Layout, GridList } from 'components';
import { t } from 'utils';

const GridListPage = () => {
  return (
    <Layout title={t('grid.list')}>
      <GridList />
    </Layout>
  );
};

export default GridListPage;
