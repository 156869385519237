import React from 'react';
import {
  DateInput,
  NumberInput,
  Form,
  FormGroup,
  FormSubmit
} from 'components';
import { t } from 'utils';

const BTCBalanceForm = props => {
  return (
    <Form {...props}>
      <FormGroup
        label={t('btcbalance.date')}
        name="date"
        component={DateInput}
      />

      <FormGroup
        label={t('btcbalance.equity')}
        name="equity"
        component={NumberInput}
      />

      <FormGroup
        label={t('btcbalance.units')}
        name="units"
        component={NumberInput}
      />

      <FormGroup
        label={t('btcbalance.nav')}
        name="nav"
        component={NumberInput}
      />

      <FormGroup
        label={t('btcbalance.acc_nav')}
        name="acc_nav"
        component={NumberInput}
      />

      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default BTCBalanceForm;
