import React from 'react';
import { Form, FormGroup, FormSubmit, FundSelect, Select } from 'components';
import { t } from 'utils';
import { USER_SPACES } from 'constants';

const StrategyForm = ({ values, onSubmit }) => {
  return (
    <Form initialValues={values} onSubmit={onSubmit}>
      <FormGroup label={t('strategy.type')} name="type" component={Select}>
        <option value="" />
        {USER_SPACES.map(d => (
          <option key={d}>{d}</option>
        ))}
      </FormGroup>
      <FormGroup label={t('strategy.name')} name="name" />
      <FormGroup label={t('strategy.display')} name="display" />
      <FormGroup
        label={t('strategy.fund_ids')}
        name="fund_ids"
        component={FundSelect}
        multiple
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default StrategyForm;
