import React, { useEffect, useState } from 'react';
import { Layout } from 'components';
import { MdAnalytics, MdCash, MdTrendingUp } from 'icons';
import { getHSCEFundList } from 'api';
import { Nav, NavItem } from './styled';

const HSCE_FUNDINGS = ['hhi_jh'];

const HSCELayout = ({ children, ...props }) => {
  const header = <Nav></Nav>;

  return (
    <Layout {...props} space="hsce" header={header}>
      {children}
    </Layout>
  );
};

export default HSCELayout;
