import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  UserSelect,
  DateInput,
  Checkbox
} from 'components';
import { t, canCloseFund, useCurrentUser } from 'utils';

const FundForm = ({ fund, ...props }) => {
  const { user } = useCurrentUser();

  return (
    <Form {...props}>
      <FormGroup label={t('fund.name')} name="name" />
      <FormGroup label={t('fund.display')} name="display" />
      <FormGroup
        label={t('fund.manager_ids')}
        name="manager_ids"
        component={UserSelect}
        multiple
      />
      <FormGroup
        label={t('fund.trader_ids')}
        name="trader_ids"
        component={UserSelect}
        multiple
      />
      <FormGroup
        label={t('fund.established_at')}
        name="established_at"
        component={DateInput}
      />
      <FormGroup label={t('fund.api')} name="api" />
      {canCloseFund(user, fund) ? (
        <FormGroup
          label={t('fund.closed')}
          name="closed"
          component={Checkbox}
        />
      ) : null}
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default FundForm;
