import qs from 'qs';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import {
  Layout,
  Select,
  TaskStatusSelect,
  TaskItem,
  AccessControl,
  Button
} from 'components';
import { getTaskList, getFundList } from 'api';
import { t, getParams } from 'utils';

const TaskListPage = ({ navigate, userId, userRole, status, fundId }) => {
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    if (!funds.length) {
      getFundList().then(({ data }) => {
        let fundId = null;
        const funds = data.filter(
          d => userRole === 'admin' || userId === d.manager_id
        );

        if (userRole === 'admin') {
          fundId = fundId || 'all';
        } else {
          fundId = fundId || (funds.length ? funds[0].id : 'all');
        }

        setFunds(funds);
        getTaskList({
          status: status === 'all' ? '' : status,
          fund_id: fundId === 'all' ? '' : fundId
        }).then(({ data }) => {
          setLoading(false);
          setTasks(data);
        });
      });
    } else {
      getTaskList({
        status: status === 'all' ? '' : status,
        fund_id: fundId === 'all' ? '' : fundId
      }).then(({ data }) => {
        setLoading(false);
        setTasks(data);
      });
    }
  }, [status, fundId]);

  function handleFundChange(fundId) {
    navigate(`/tasks?${qs.stringify({ status, fund_id: fundId })}`);
  }

  function handleStatusChange(status) {
    navigate(`/tasks?${qs.stringify({ status, fund_id: fundId })}`);
  }

  function newTask() {
    navigate('/tasks/new');
  }

  if (loading) {
    return <Layout loading />;
  }

  return (
    <Layout>
      <Head>
        <Filter>
          <Select
            style={{ marginRight: 10 }}
            onChange={handleFundChange}
            value={fundId}
          >
            <option value="all">{t('all')}</option>
            {funds.map(fund => (
              <option key={fund.id} value={fund.id}>
                {fund.display}
              </option>
            ))}
          </Select>

          <TaskStatusSelect onChange={handleStatusChange} value={status}>
            <option value="all">{t('all')}</option>
          </TaskStatusSelect>
        </Filter>

        <AccessControl allowedRoles={['admin', 'manager', 'trader']}>
          <Button appearance="primary" onClick={newTask}>
            {t('task.new')}
          </Button>
        </AccessControl>
      </Head>

      {tasks.map(task => (
        <TaskItem key={task.id} task={task} />
      ))}
    </Layout>
  );
};

const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const Filter = styled.div`
  margin-right: auto;
`;

const mapStateToProps = ({ user }, { location }) => {
  const params = getParams(location);
  return {
    userId: user.id,
    userRole: user.role,
    fundId: params.fund_id || '',
    status: params.status || 'all'
  };
};

export default connect(mapStateToProps)(TaskListPage);
