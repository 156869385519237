import styled from '@emotion/styled';
import React, { Fragment } from 'react';
import { Card } from 'components';
import { MdCheckmarkCircle, MdHelpCircle } from 'icons';
import { navigate } from 'utils';

const TaskItem = ({ task }) => {
  const { strategy } = task;
  const { name } = strategy;

  return (
    <Card interactive onClick={() => task.id && navigate(`/tasks/${task.id}`)}>
      <Container>
        <Icon>
          <TaskIcon task={task} />
        </Icon>
        <Info>
          <Title>
            <span>{task.fund.display}</span>
            <span>{task.vars.base}</span>
            <span>{task.vars.counter}</span>
          </Title>
          <Params>
            {name === 'hsce_grid_arbitrage' ? <Fragment /> : null}
          </Params>
        </Info>
      </Container>
    </Card>
  );
};

const TaskIcon = ({ task }) => {
  const { status } = task;

  if (status === 'confirmed') {
    return <MdCheckmarkCircle style={{ color: '#47B881' }} />;
  } else if (status === 'pending') {
    return <MdHelpCircle style={{ color: '#aaa' }} />;
  } else if (status === 'finished') {
    return <MdCheckmarkCircle style={{ color: '#aaa' }} />;
  }

  return null;
};

const Container = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

const Icon = styled.div`
  width: 50px;
  margin-right: 17px;

  svg {
    max-width: 100%;
    fill: currentColor;
  }
`;

const Info = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const Title = styled.div`
  color: #333;
  line-height: 1;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 600;

  span {
    display: inline-block;
    margin-right: 5px;

    div {
      display: inline-block;
      margin-right: 10px;
    }
  }
`;

const Params = styled.div`
  color: #999;
  span {
    display: inline-block;
    margin-right: 5px;
  }
`;

export default TaskItem;
