import React, { useState, useEffect } from 'react';
import { AdminLayout, Link, Card, Pair, LogItem } from 'components';
import { getUser, getUserLogs } from 'api';
import { t } from 'utils';

const UserPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getUser(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <AdminLayout loading />;

  return (
    <AdminLayout>
      <Card
        title={data.name}
        extra={<Link to={`/users/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('user.name')} value={data.name} />
        <Pair
          label={t('user.role')}
          value={t(`constants.user.role.${data.role}`)}
        />
        <Pair label={t('user.email')} value={data.email} />
        <Pair label={t('user.mobile')} value={data.mobile} />
      </Card>

      <UserLogs id={id} />
    </AdminLayout>
  );
};

const UserLogs = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getUserLogs(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <Card title={t('user.logs')} loading />;

  return (
    <Card title={t('user.logs')}>
      {data.map(d => (
        <LogItem key={d.id} log={d} />
      ))}
    </Card>
  );
};

export default UserPage;
