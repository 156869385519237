export const TASK_TYPES = ['position', 'grid'];

export const TASK_STATUSES = [
  'pending',
  'confirmed',
  'finished',
  'archived',
  'paused'
];

export const EXCHANGES = ['BITMEX', 'OKEX'];
export const BITMEX_PROMPTS = ['quarterly', 'perpetual'];
export const OKEX_PROMPTS = ['weekly', 'biweekly', 'quarterly'];
export const BITMEX_SYMBOLS = [
  'XBTUSD',
  'XBTM19',
  'XBTU19',
  'ETHUSD',
  'ETHH19'
];
export const OKEX_SYMBOLS = ['BTCUSD', 'ETHUSD'];

export const MONITOR_VARS = {
  future_price_threshold: {
    idVar: 'string',
    exchange: 'string',
    symbol: 'string',
    prompt: 'string',
    display: 'string',
    crit: 'float',
    suppress: 'duration'
  }
};

export const MONITOR_PREFIX = 'FUTURE_';

export const MONITOR_STATUS = ['enabled', 'disabled'];

export const USER_SPACES = ['btc', 'hsce', 'bond', 'risk'];
