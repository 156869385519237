/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Layout } from 'components';
import { MdAnalytics, MdCash, MdTrendingUp } from 'icons';
import { t } from 'utils';
import { Nav, NavItem } from './styled';
import { Dropdown, DropdownContent, Item, Caret } from './styled';

const BTCLayout = ({ children, ...props }) => {
  const header = (
    <Nav>
      <NavItem href="/" Icon={MdAnalytics} text="menu.btc.index" />
      <NavItem href="/btc/cutter" Icon={MdTrendingUp} text="menu.btc.cutter" />
      <NavItem href="/btc/pts" Icon={MdTrendingUp} text="menu.btc.pts" />
      <NavItem href="/btc/shenyu" Icon={MdTrendingUp} text="menu.btc.shenyu" />
      <NavItem
        Icon={MdCash}
        text="menu.btc.balance"
        component="div"
        css={css`
          position: relative;
          &:hover {
            .dropdown {
              display: block;
            }
          }
        `}
      >
        <Dropdown
          className="dropdown"
          css={css`
            top: 54px;
          `}
        >
          <Caret />
          <DropdownContent>
            <Item to="/balance">{t('menu.btc.balance')}</Item>
            <Item to="/fund_units">{t('menu.btc.fund_units')}</Item>
            <Item to="/dividends">{t('menu.btc.dividends')}</Item>
          </DropdownContent>
        </Dropdown>
      </NavItem>
    </Nav>
  );

  return (
    <Layout {...props} space="btc" header={header}>
      {children}
    </Layout>
  );
};

export default BTCLayout;
