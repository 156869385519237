/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import {
  Layout,
  Card,
  Row,
  Col,
  HSCERiskChart,
  HSCETradeHistoryTable,
  Button,
  Form,
  FormGroup,
  FormSubmit,
  Table,
  confirm
} from 'components';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter
} from '@swiftcarrot/kit';

export const formatNumber = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const HSCERiskPage = () => {
  const [open, setOpen] = useState(false);

  function handleSubmit(values, { setSubmitting }) {
    confirm('紧急中止内外盘?')
      .then(() => {
        window.location.reload();
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  return (
    <Layout fluid>
      <Card title="行情数据">
        <HSCERiskChart />
      </Card>

      <Row style={{ marginTop: 20, marginBottom: 20 }}>
        <Col>
          <Account title="内盘" />
        </Col>
        <Col>
          <Account title="外盘" />
        </Col>
      </Row>

      <Card title="投资限制">
        <Table
          getRowKey={row => row.type}
          columns={[
            { key: 'type', title: '' },
            { key: 'sell', title: '内盘' },
            { key: 'buy', title: '外盘' }
          ]}
          rows={[
            {
              type: '交易品种',
              sell: <Stat>510900.SH</Stat>,
              buy: (
                <Fragment>
                  <Stat>HHI2003</Stat>
                  <Stat>HHI2004</Stat>
                </Fragment>
              )
            },
            {
              type: '最大持仓',
              sell: (
                <Fragment>
                  <Stat>{formatNumber(48000000)}</Stat> 股
                </Fragment>
              ),
              buy: (
                <Fragment>
                  <Stat>{formatNumber(120)}</Stat> 手
                </Fragment>
              )
            },
            {
              type: '单次交易量最大值',
              sell: (
                <Fragment>
                  <Stat>{formatNumber(1200000)}</Stat> 股
                </Fragment>
              ),
              buy: (
                <Fragment>
                  <Stat>{formatNumber(3)}</Stat> 手
                </Fragment>
              )
            },
            {
              type: '可用不低于',
              sell: (
                <Fragment>
                  <Stat>{formatNumber(100000)}</Stat> 人民币/元
                </Fragment>
              ),
              buy: (
                <Fragment>
                  <Stat>{formatNumber(16000)}</Stat> 美元
                </Fragment>
              )
            }
          ]}
        />
      </Card>

      <RuleDialog
        isOpen={open}
        onCancel={() => setOpen(false)}
        onConfirm={() => setOpen(false)}
      />
      <Card title="风控数据">
        <ul>
          <li>
            底仓比例（内盘：外盘）
            <Stat />
          </li>
          <li>
            杠杆比例（外盘期货合约价值/外盘当前总权益）
            <Stat />
          </li>
          <li>
            当前需补缴保证金金额（美元） <Stat />
          </li>
        </ul>
      </Card>
      <Card title="紧急中止">
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <Form onSubmit={handleSubmit}>
            <FormSubmit
              component={Button}
              appearance="primary"
              intent="danger"
              style={{
                fontSize: 18,
                padding: '20px 50px'
              }}
            >
              紧急中止内外盘
            </FormSubmit>
          </Form>
        </div>
      </Card>
    </Layout>
  );
};

const Account = ({ title }) => {
  return (
    <Card title={title}>
      <Row>
        <Col>
          <b>总资产(人民币)</b>
          <br />
          {formatNumber(0)}
        </Col>
        <Col>
          <b>可用资金(人民币)</b>
          <br />
          {formatNumber(0)}
        </Col>

        <Col>
          <b>已用资金(人民币)</b>
          <br />
          {formatNumber(0)}
        </Col>
      </Row>
      <Row>
        <Col>
          <b>持仓品种</b>
          <br />
        </Col>
        <Col>
          <b>数量(股)</b>
          <br />
          {formatNumber(0)}
        </Col>

        <Col>
          <b>持仓总市值(人民币)</b>
          <br />
          {formatNumber(0)}
        </Col>
      </Row>

      <HSCETradeHistoryTable
        data={[
          {
            client_side_id: '1',
            create_time: '',
            symbol: '',
            volume: '',
            price: '0',
            filled_volume: '0',
            avg_price: '0',
            side: 'BUY',
            status: 'FILLED'
          }
        ]}
      />
    </Card>
  );
};

const RuleDialog = ({ onCancel, onConfirm, ...props }) => {
  function handleSubmit(values, { action, setSubmitting }) {
    if (action == 'cancel') {
      onCancel();
      setSubmitting(false);
    } else {
      onConfirm();
      setSubmitting(false);
    }
  }

  return (
    <Dialog {...props}>
      <Form initialValues={{ rule: '' }} onSubmit={handleSubmit}>
        <DialogHeader>添加交易限制</DialogHeader>
        <DialogBody>
          <FormGroup label="交易限制" name="rule" multiline />
        </DialogBody>
        <DialogFooter>
          <FormSubmit appearance="default" action="cancel">
            取消
          </FormSubmit>
          <FormSubmit appearance="primary" style={{ marginLeft: 8 }}>
            确定
          </FormSubmit>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

const Stat = props => {
  return (
    <span
      css={css`
        display: inline-block;
        min-width: 50px;
        min-height: 30px;
        margin-left: 4px;
        margin-right: 4px;
        border: 1px solid #ccc;
        padding: 5px 8px;
        border-radius: 5px;
        vertical-align: middle;
      `}
      {...props}
    />
  );
};

export default HSCERiskPage;
