import React, { useState, useEffect } from 'react';
import { getContracts } from 'api';
import { Select } from 'components';

const ContractSelect = ({ strategy, match, ...props }) => {
  const [options, setOptions] = useState([]);
  const type = strategy ? strategy.type : null;

  useEffect(() => {
    getContracts({ type }).then(({ data }) => {
      if (match) {
        const m = new RegExp(match, 'i');
        setOptions(data.filter(d => d.name.match(m)));
      } else {
        setOptions(data);
      }
    });
  }, [type, match]);

  return (
    <Select fullWidth includeBlank {...props}>
      {options.map(x => (
        <option key={x.id} value={x.name}>
          {x.display}
        </option>
      ))}
    </Select>
  );
};

export default ContractSelect;
