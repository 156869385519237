/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import {
  BondLayout,
  Card,
  Pair,
  Link,
  Button,
  Row,
  Col,
  FormGroup,
  Form,
  FormSubmit,
  NumberInput,
  SimpleFormat,
  BondTaskLogList,
  BondTaskDialog
} from 'components';
import { getBondTask, updateBondTaskStatus } from 'api';
import { t, useCurrentUser, canEditBondTask } from 'utils';

const BondTaskPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState(null);
  const { user } = useCurrentUser();

  useEffect(() => {
    getBondTask(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting, action }) {
    setOpen(true);
    setSubmitting(false);
    setAction(action);
  }

  function handleDialogSubmit({ log_entry_created }) {
    return updateBondTaskStatus(id, {
      ...data.task,
      edge_to_run: action,
      log_entry_created
    })
      .then(() => {
        window.location.reload();
      })
      .catch(err => {
        console.error(err);
      });
  }

  if (loading) return <BondLayout loading />;

  const { task, edge_list: edgeList } = data;

  return (
    <BondLayout>
      <BondTaskDialog
        isOpen={open}
        onCancel={() => setOpen(false)}
        onSubmit={handleDialogSubmit}
      />
      <Card
        title={task.current_vertex_display}
        extra={
          <Fragment>
            {canEditBondTask(user, task) ? (
              <Link to={`/bond/tasks/${task.id}/edit`}>{t('edit')}</Link>
            ) : null}
          </Fragment>
        }
      >
        <Pair label={t('bondtask.file_no')} value={task.file_no} />
        <Pair
          label={t('bondtask.securities_code')}
          value={task.securities_code}
        />
        <Pair
          label={t('bondtask.securities_name')}
          value={task.securities_name}
        />
        <Pair
          label={t('bondtask.trade_side')}
          value={t(`constants.bondtask.trade_side.${task.trade_side}`)}
        />
        <Pair
          label={t('bondtask.expectation_price')}
          value={task.expectation_price}
        />
        <Pair
          label={t('bondtask.expectation_amount')}
          value={task.expectation_amount}
        />

        <Pair
          label={t('bondtask.transaction_price')}
          value={task.transaction_price}
        />
        <Pair
          label={t('bondtask.transaction_amount')}
          value={task.transaction_amount}
        />

        <Pair
          style={{ display: 'block' }}
          label={t('bondtask.remarks')}
          value={<SimpleFormat>{task.remarks}</SimpleFormat>}
        />

        {edgeList ? (
          <Form onSubmit={handleSubmit} style={{ marginTop: 40 }}>
            {task.current_vertex_id === 'v2' ? (
              <Row>
                <Col>
                  <FormGroup
                    name="transaction_price"
                    label={t('bondtask.transaction_price')}
                    component={NumberInput}
                  />
                </Col>
                <Col>
                  <FormGroup
                    name="transaction_amount"
                    label={t('bondtask.transaction_amount')}
                    component={NumberInput}
                  />
                </Col>
              </Row>
            ) : null}

            <div
              css={css`
                display: flex;
              `}
            >
              <Button
                appearance="minimal"
                component={Link}
                to={`/bond/funds/${task.fund.name}`}
                css={css`
                  margin-right: auto;
                `}
              >
                {t('back')}
              </Button>

              {edgeList.map(edge =>
                canUpdateTask(user, task, edge) ? (
                  <FormSubmit
                    key={edge.id}
                    component={Button}
                    action={edge.id}
                    style={{ marginLeft: 15 }}
                  >
                    {edge.display}
                  </FormSubmit>
                ) : null
              )}
            </div>
          </Form>
        ) : null}
      </Card>

      <BondTaskLogList id={task.id} />
    </BondLayout>
  );
};

const canUpdateTask = (user, task, edge) => {
  if (edge.type === 'creator') {
    return user.id === task.created_by;
  } else if (edge.type === 'auditor') {
    return task.auditors.indexOf(user.id) >= 0;
  }

  return false;
};

export default BondTaskPage;
