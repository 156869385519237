import React from 'react';
import { t } from 'utils';
import { Select } from 'components';

const TYPES = ['all', 'btc', 'hsce'];

const ContractTypeSelect = props => {
  return (
    <Select {...props} style={{ width: '100%' }}>
      {TYPES.map(x => (
        <option key={x} value={x}>
          {t(`constants.contract.type.${x}`)}
        </option>
      ))}
    </Select>
  );
};

export default ContractTypeSelect;
