import './global.scss';
import React, { useState, useEffect } from 'react';
import { Kit } from 'components';
import { Provider } from 'react-redux';
import { getCurrentUser, getContracts } from 'api';
import { Router, Redirect } from '@reach/router';
import { LoginPage } from 'pages';
import { updateConstants } from 'locales';
import { CurrentUserContext } from 'context';
import store from './store';

function redirectRisk(user) {
  if (user.role === 'risk' && window.location.pathname !== '/risk') {
    window.location.pathname = '/risk';
  }
}

const App = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(window.localStorage.getItem('token'));
  const setUserToken = token => {
    window.localStorage.setItem('token', token);
    setToken(token);
  };
  const value = { token, user, setToken: setUserToken, setUser };

  useEffect(() => {
    setLoading(true);
    if (token) {
      getCurrentUser()
        .then(({ data: user }) => {
          getContracts()
            .then(({ data: contracts }) => {
              updateConstants('contracts', contracts);
              setUser(user);
              redirectRisk(user);
              setLoading(false);
            })
            .catch(err => {
              console.error(err);
              setUser(user);
              redirectRisk(user);
              setLoading(false);
            });
        })
        .catch(err => {
          console.error(err);
          setUser(null);
          setLoading(false);
        });
    } else {
      setUser(null);
      setLoading(false);
    }
  }, [token]);

  if (loading) return null;

  if (!user) {
    return (
      <Kit>
        <CurrentUserContext.Provider value={value}>
          <Router>
            <Protected path="*" />
            <LoginPage path="/login" />
          </Router>
        </CurrentUserContext.Provider>
      </Kit>
    );
  }

  return (
    <Kit>
      <Provider store={store}>
        <CurrentUserContext.Provider value={value}>
          {children}
        </CurrentUserContext.Provider>
      </Provider>
    </Kit>
  );
};

const Protected = ({ location }) => {
  const to = `${location.pathname}${location.search}`;
  window.sessionStorage.setItem('to', to);
  return <Redirect to="/login" noThrow />;
};

export default App;
