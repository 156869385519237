import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import userReducer from './user.reducer';

const rootReducer = combineReducers({ user: userReducer });
const initialState = {};

try {
  if (window.localStorage.user) {
    initialState.user = JSON.parse(window.localStorage.user);
  }
} catch (e) {
  console.error(e);
}

const store = createStore(rootReducer, initialState, applyMiddleware(thunk));

window._store = store;

export default store;
