import React from 'react';
import { Form, FormGroup, FormSubmit } from 'components';
import { t } from 'utils';

const LoginForm = props => {
  return (
    <Form {...props}>
      <FormGroup label={t('login.username')} name="username" />
      <FormGroup label={t('login.password')} type="password" name="password" />
      <FormSubmit block>{t('login.submit')}</FormSubmit>
    </Form>
  );
};

export default LoginForm;
