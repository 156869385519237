/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment, useState, useEffect } from 'react';
import {
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  XAxis,
  YAxis,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  Crosshair
} from 'react-vis';
import { Loading, DateInput } from 'components';
import { t, formatDate } from 'utils';
import { getHSCEBalanceList } from 'api';
import { MdRemove } from 'icons';

const HSCEBalanceChart = ({ fundId }) => {
  const [state, setState] = useState({ loading: true, series: [] });
  const [disabled, setDisabled] = useState({});
  const [index, setIndex] = useState(-1);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const { loading, series } = state;

  useEffect(() => {
    const params = start || end ? { start, end } : { page: 1 };

    getHSCEBalanceList({ fund_id: fundId, ...params }).then(({ data }) => {
      setState({
        loading: false,
        series: [
          {
            title: t('hscebalance.acc_nav'),
            data: data.map(d => ({
              x: new Date(d.date).getTime(),
              y: d.acc_nav
            }))
          }
        ]
      });
    });
  }, [fundId, start, end]);

  function handleLegendClick(item, i) {
    if (disabled[i]) {
      setDisabled({ ...disabled, [i]: false });
    } else {
      setDisabled({ ...disabled, [i]: true });
    }
  }

  function handleMouseLeave() {
    setIndex(-1);
  }

  function handleNearestX(value, { index }) {
    setIndex(index);
  }

  if (loading) return <Loading />;

  let max = null;
  let min = null;

  if (series[0].data.length) {
    max = series[0].data[0].x;
    min = series[0].data[series[0].data.length - 1].x;
  }

  return (
    <Fragment>
      <div
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        <DiscreteColorLegend
          items={series.map(({ title }, i) => ({
            title,
            disabled: disabled[i]
          }))}
          orientation="horizontal"
          onItemClick={handleLegendClick}
        />
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-left: auto;
            input {
              height: 35px;
            }
          `}
        >
          <DateInput
            style={{ width: 200 }}
            value={start}
            onChange={v => setStart(v)}
          />
          <MdRemove width="20" color="#999" style={{ margin: '0 8px' }} />
          <DateInput
            style={{ width: 200 }}
            value={end}
            onChange={v => setEnd(v)}
          />
        </div>
      </div>

      <FlexibleWidthXYPlot
        height={300}
        margin={{ left: 55, right: 40, top: 10, bottom: 40 }}
        onMouseLeave={handleMouseLeave}
      >
        <Crosshair
          values={index >= 0 ? series.map(s => s.data[index]) : null}
          titleFormat={values => ({
            title: '时间',
            value: formatDate(values[0].x)
          })}
          itemsFormat={values =>
            values.map((v, i) => ({
              title: series[i].title,
              value: v ? v.y.toFixed(4) : ''
            }))
          }
        />
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis tickFormat={formatDate} tickValues={[min, max]} />
        <YAxis />
        <LineSeries
          data={series[0].data}
          curve="curveMonotoneX"
          onNearestX={handleNearestX}
          {...(disabled[0] ? { opacity: 0.2 } : null)}
        />
        {series.slice(1).map((s, i) => (
          <LineSeries
            key={i}
            data={s.data}
            curve="curveMonotoneX"
            {...(disabled[i + 1] ? { opacity: 0.2 } : null)}
          />
        ))}
      </FlexibleWidthXYPlot>
    </Fragment>
  );
};

export default HSCEBalanceChart;
