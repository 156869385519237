import React, { useState, useEffect } from 'react';
import { Layout, Card, ContractForm } from 'components';
import { getContract, updateContract } from 'api';
import { t } from 'utils';

const ContractEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getContract(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateContract(id, values).then(({ data }) => {
      setSubmitting(false);
      navigate(`/contracts/${data.id}`);
    });
  }

  if (loading) return <Layout loading />;

  return (
    <Layout title={t('contract.edit')}>
      <Card title="修改信息" style={{ marginBottom: 20 }}>
        <ContractForm contract={data} onSubmit={handleSubmit} />
      </Card>
    </Layout>
  );
};

export default ContractEditPage;
