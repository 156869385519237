import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { AdminLayout, Card, Link, Table } from 'components';
import { getUsers } from 'api';
import { t, avatarUrl } from 'utils';

const UserListPage = () => {
  const [state, setState] = useState({ loading: true, data: [] });

  useEffect(() => {
    getUsers().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  const { loading, data } = state;

  if (loading) {
    return <AdminLayout loading />;
  }

  return (
    <AdminLayout title={t('user.list')}>
      <Card
        title={t('user.list')}
        extra={<Link to="/users/new">{t('user.new')}</Link>}
      >
        <Table
          columns={[
            {
              key: 'avatar',
              render: user => <Avatar src={avatarUrl(user.key)} />
            },
            { key: 'name', title: t('user.name') },
            {
              key: 'role',
              title: t('user.role'),
              render: user => t(`constants.user.role.${user.role}`)
            },
            { key: 'email', title: t('user.email') },
            { key: 'mobile', title: t('user.mobile') },
            {
              key: 'action',
              render: user => (
                <div className="text-right">
                  <Link to={`/users/${user.key}`}>{t('view')}</Link>
                  &nbsp;|&nbsp;
                  <Link to={`/users/${user.key}/edit`}>{t('edit')}</Link>
                </div>
              )
            }
          ]}
          rows={data.map(d => ({
            key: d.id,
            name: d.name,
            role: d.role,
            email: d.email,
            mobile: d.mobile
          }))}
        />
      </Card>
    </AdminLayout>
  );
};

const Avatar = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

export default UserListPage;
