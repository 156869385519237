import React, { Fragment, useState } from 'react';
import {
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  XAxis,
  YAxis,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  Crosshair
} from 'react-vis';
import { formatTime } from 'utils';

const SeriesChart = ({
  series,
  xDomain,
  xTickFormat,
  xTickValues,
  index,
  setIndex
}) => {
  const [disabled, setDisabled] = useState({});

  function handleLegendClick(item, i) {
    if (disabled[i]) {
      setDisabled({ ...disabled, [i]: false });
    } else {
      setDisabled({ ...disabled, [i]: true });
    }
  }

  function handleMouseLeave() {
    setIndex(null);
  }

  function handleNearestX(value, { index }) {
    setIndex(index);
  }

  const items = series.map(({ title }, i) => ({
    title,
    disabled: disabled[i]
  }));

  return (
    <Fragment>
      <DiscreteColorLegend
        items={items}
        orientation="horizontal"
        onItemClick={handleLegendClick}
      />

      <FlexibleWidthXYPlot
        xDomain={xDomain}
        height={300}
        margin={{ left: 55, right: 10, top: 10, bottom: 40 }}
        onMouseLeave={handleMouseLeave}
      >
        <Crosshair
          values={index ? series.map(s => s.data[index]) : null}
          titleFormat={values => ({
            title: '时间',
            value: values[0] ? formatTime(values[0].t || values[0].x) : ''
          })}
          itemsFormat={values =>
            values
              .map((v, i) => ({
                title: series[i].title,
                value: v ? v.y.toFixed(4) : ''
              }))
              .sort((a, b) => b.value - a.value)
          }
        />
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis tickFormat={xTickFormat} tickValues={xTickValues} />
        <YAxis />
        <LineSeries
          data={series[0].data}
          curve="curveMonotoneX"
          onNearestX={handleNearestX}
          {...(disabled[0] ? { opacity: 0.2 } : null)}
        />
        {series.slice(1).map((s, i) => (
          <LineSeries
            key={i}
            data={s.data}
            curve="curveMonotoneX"
            {...(disabled[i + 1] ? { opacity: 0.2 } : null)}
          />
        ))}
      </FlexibleWidthXYPlot>
    </Fragment>
  );
};

SeriesChart.defaultProps = {
  xTickFormat: formatTime
};

export default SeriesChart;
