import React from 'react';
import {
  Form,
  FormSubmit,
  FormGroup,
  DateInput,
  NumberInput
} from 'components';
import { t } from 'utils';

const BTCDividendForm = props => {
  return (
    <Form {...props}>
      <FormGroup
        label={t('btcdividend.date')}
        name="date"
        component={DateInput}
      />
      <FormGroup
        label={t('btcdividend.dividends')}
        name="dividends"
        component={NumberInput}
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default BTCDividendForm;
