import range from 'lodash/range';
import styled from '@emotion/styled';
import qs from 'qs';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FlexibleWidthXYPlot,
  VerticalBarSeries,
  XAxis,
  YAxis
} from 'react-vis';
import { Layout, DateInput, Card, Table, Query } from 'components';
import { colors, navigate } from 'utils';
import ConceptDialog from './ConceptDialog';
import MarketTopStockList from './MarketTopStockList';
import CloseHighStockList from './CloseHighStockList';

class FupanPage extends Component {
  state = {
    concept: null
  };

  load = date => {
    console.log(date);
  };

  handleDateChange = date => {
    navigate(
      `/fupan?` + qs.stringify({ date: moment(date).format('YYYYMMDD') })
    );
  };

  componentDidMount() {
    this.load(this.props.date);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.date !== this.props.date) {
      this.load(this.props.date);
    }
  }

  render() {
    const { date } = this.props;
    const dateValue = new Date(
      `${date.substr(0, 4)}-${date.substr(4, 2)}-${date.substr(6, 2)}`
    );

    return (
      <Layout title="复盘">
        <Card style={{ marginBottom: 20 }}>
          <DateInput value={dateValue} onChange={this.handleDateChange} />
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Heading>大盘综述</Heading>
          <Title>指数涨跌幅</Title>
          <Query
            query={`
              query IndexDaily($date: date!) {
                ts_index_daily(where: { trade_date: { _eq: $date } }) {
                  ts_code
                  open
                  close
                  high
                  low
                }
              }
            `}
            variables={{ date }}
          >
            {({ data }) => (
              <Table
                columns={[
                  { key: 'ts_code' },
                  { key: 'open' },
                  { key: 'close' },
                  { key: 'low' },
                  { key: 'high' }
                ]}
                rows={data.ts_index_daily}
              />
            )}
          </Query>

          <Title>涨跌分布</Title>
          <Query get="/fupan/daily" params={{ date }}>
            {({ data }) => {
              const counts = data.reduce((x, d) => {
                const level =
                  d.level === 0
                    ? 0
                    : parseInt((d.level + (d.level > 0 ? 1 : -1)) / 2, 10) * 2;
                x[level] =
                  x[level] !== undefined ? x[level] + d.count : d.count;
                return x;
              }, {});

              return (
                <FlexibleWidthXYPlot xType="ordinal" height={300}>
                  <XAxis />
                  <YAxis />
                  <VerticalBarSeries
                    colorType="literal"
                    data={range(-10, 11, 2).map(i => ({
                      x: i === 10 ? '涨停' : i === -10 ? '跌停' : `${i}%`,
                      y: counts[i] || 0,
                      color: i > 0 ? colors.red : colors.green
                    }))}
                  />
                </FlexibleWidthXYPlot>
              );
            }}
          </Query>

          <Title>涨跌停家数</Title>
          <Query
            query={`
              query AGStockDaily($date: date!) {
                close_high: ag_stock_daily_aggregate(
                  where: {
                    trade_date: { _eq: $date }
                    close_high: { _eq: true }
                  }
                ) {
                  aggregate {
                    count
                  }
                }
                close_low: ag_stock_daily_aggregate(
                  where: {
                    trade_date: { _eq: $date }
                    close_low: { _eq: true }
                  }
                ) {
                  aggregate {
                    count
                  }
                }
                reach_high: ag_stock_daily_aggregate(
                  where: {
                    trade_date: { _eq: $date }
                    reach_high: { _eq: true }
                  }
                ) {
                  aggregate {
                    count
                  }
                }
                reach_low: ag_stock_daily_aggregate(
                  where: {
                    trade_date: { _eq: $date }
                    reach_low: { _eq: true }
                  }
                ) {
                  aggregate {
                    count
                  }
                }
              }
            `}
            variables={{ date }}
          >
            {({ data }) => (
              <table>
                <tbody>
                  <tr>
                    <td>涨停家数</td>
                    <td>{data.close_high.aggregate.count}</td>
                  </tr>
                  <tr>
                    <td>跌停家数</td>
                    <td>{data.close_low.aggregate.count}</td>
                  </tr>
                  <tr>
                    <td>达到涨停家数</td>
                    <td>{data.reach_high.aggregate.count}</td>
                  </tr>
                  <tr>
                    <td>达到跌停家数</td>
                    <td>{data.reach_low.aggregate.count}</td>
                  </tr>
                  <tr>
                    <td>涨停打开家数</td>
                    <td>
                      {data.reach_high.aggregate.count -
                        data.close_high.aggregate.count}
                    </td>
                  </tr>
                  <tr>
                    <td>跌停打开家数</td>
                    <td>
                      {data.reach_low.aggregate.count -
                        data.close_low.aggregate.count}
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </Query>

          <Title>融资融券余额</Title>
          <Query
            query={`
              query TSMargin($date: date!) {
                ts_margin(where: { trade_date: { _eq: $date } }) {
                  rzye
                  rqye
                }
              }
            `}
            variables={{ date }}
          >
            {({ data }) => (
              <Table
                columns={[{ key: 'rzye' }, { key: 'rqye' }]}
                rows={data.ts_margin}
              />
            )}
          </Query>
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Heading>资金流向</Heading>
          <Title>沪港通、深港通</Title>
          <Query
            query={`
              query MoneyFlow($date: date!) {
                ts_moneyflow_hsgt(where: { trade_date: { _eq: $date } }) {
                  hgt
                  sgt
                  ggt_ss
                  ggt_sz
                }
              }
            `}
            variables={{ date }}
          >
            {({ data }) => (
              <Table
                columns={[
                  { key: 'hgt', title: '沪股通' },
                  { key: 'sgt', title: '深股通' },
                  { key: 'ggt_ss', title: '上证港股通' },
                  { key: 'ggt_sz', title: '深圳港股通' }
                ]}
                rows={data.ts_moneyflow_hsgt}
              />
            )}
          </Query>
          <Title>十大交易股票</Title>
          <MarketTopStockList date={date} />
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Heading>涨停股</Heading>
          <CloseHighStockList date={date} />
        </Card>

        <Card style={{ marginBottom: 20 }}>
          <Heading>热门概念</Heading>

          <ConceptDialog concept={this.state.concept} date={date} />
          <Query get="/fupan/concepts" params={{ date }}>
            {({ data }) => (
              <Table
                onRowClick={row => this.setState({ concept: row.concept_code })}
                columns={[
                  'rank',
                  'concept_code',
                  'trade_date',
                  'name',
                  'pct_chg'
                ].map(k => ({ key: k }))}
                rows={data}
              />
            )}
          </Query>
        </Card>
      </Layout>
    );
  }
}

const Heading = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
`;

const Title = styled.div`
  margin-top: 10px;
  font-size: 14px;
`;

const mapStateToProps = (state, { location }) => {
  const params = location.search ? qs.parse(location.search.substr(1)) : null;
  const date = params ? params.date : moment().format('YYYYMMDD');

  return { date };
};

export default connect(mapStateToProps)(FupanPage);
