/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Fragment, useState, useEffect } from 'react';
import { HSCELayout, Row, Col, Link, Card, SimpleFormat } from 'components';
import { getHSCEBalance } from 'api';
import { t, formatDate } from 'utils';

const HSCEBalancePage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getHSCEBalance(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <HSCELayout loading />;

  return (
    <HSCELayout>
      <Card
        title={formatDate(data.date)}
        extra={<Link to={`/balance/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Row>
          <Group label={t('hscebalance.date')} value={formatDate(data.date)} />
          <Group
            label={t('hscebalance.details.usdcnh')}
            value={data.details.usdcnh}
          />
          <Group
            label={t('hscebalance.details.hkdcnh')}
            value={data.details.hkdcnh}
          />
        </Row>

        <Title>投入资产</Title>

        <Row>
          <Group
            label={t('hscebalance.details.np_origin')}
            value={data.details.np_origin}
          />
          <Group
            label={t('hscebalance.details.wp_origin')}
            value={data.details.wp_origin}
          />
          <Group label={t('hscebalance.details.origin')} value={data.origin} />
        </Row>

        <Title>产品份额</Title>

        <Row>
          <Group
            label={t('hscebalance.details.np_units')}
            value={data.details.np_units}
          />
          <Group
            label={t('hscebalance.details.wp_units')}
            value={data.details.wp_units}
          />
          <Group label={t('hscebalance.details.units')} value={data.units} />
        </Row>

        <Title>当日资产</Title>

        <Row>
          <Group
            label={t('hscebalance.details.np_value')}
            value={data.details.np_value}
          />
          <Group
            label={t('hscebalance.details.wp_value')}
            value={data.details.wp_value}
          />
          <Group label={t('hscebalance.details.total')} value={data.total} />
        </Row>

        <Title>净值计算</Title>

        <Row>
          <Group label={t('hscebalance.nav')} value={data.nav} />
          <Group
            label={t('hscebalance.details.dividends')}
            value={data.details.dividends}
          />
          <Group
            label={t('hscebalance.details.acc_corr')}
            value={data.details.acc_corr}
          />
          <Group label={t('hscebalance.acc_nav')} value={data.acc_nav} />
          <Group
            label={t('hscebalance.ar')}
            value={`${(data.ar * 100).toFixed(2)}%`}
          />
        </Row>

        <Row>
          <Group
            label={t('hscebalance.details.others')}
            value={data.details.others}
          />
          <Group
            label={t('hscebalance.details.pure_nav')}
            value={data.details.pure_nav}
          />
          <Group
            label={t('hscebalance.details.pure_acc_corr')}
            value={data.details.pure_acc_corr}
          />
          <Group
            label={t('hscebalance.details.pure_acc_nav')}
            value={data.details.pure_acc_nav}
          />
        </Row>

        <Row>
          <Group label={t('hscebalance.acc_net')} value={data.acc_net} />
          <Group
            label={t('hscebalance.details.div_value')}
            value={data.details.div_value}
          />
        </Row>

        <Title>当日收盘仓位</Title>

        <Row>
          <Group
            label={t('hscebalance.details.etf_holdings')}
            value={data.details.etf_holdings}
          />
          <Group
            label={t('hscebalance.details.etf_price')}
            value={data.details.etf_price}
          />
          <Group
            label={t('hscebalance.details.etf_value')}
            value={data.details.etf_value}
          />
        </Row>

        <Row>
          <Group
            label={t('hscebalance.details.hhi_holdings')}
            value={data.details.hhi_holdings}
          />
          <Group
            label={t('hscebalance.details.hhi_price')}
            value={data.details.hhi_price}
          />
          <Group
            label={t('hscebalance.details.hhi_value')}
            value={data.details.hhi_value}
          />
        </Row>

        <Pair
          label={t('hscebalance.note')}
          value={<SimpleFormat>{data.note}</SimpleFormat>}
        />
      </Card>
    </HSCELayout>
  );
};

const Pair = ({ label, value }) => {
  return (
    <Fragment>
      <label
        css={css`
          display: block;
          margin-bottom: 8px;
          color: #333;
          font-weight: bold;
        `}
      >
        {label}
      </label>
      <div
        css={css`
          margin-bottom: 12px;
          min-height: 37px;
        `}
      >
        {value}
      </div>
    </Fragment>
  );
};

const Group = props => {
  return (
    <Col>
      <Pair {...props} />
    </Col>
  );
};

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 4px 0;
  margin-bottom: 25px;
  color: #333;
  border-left: 4px solid #5e72e4;
  padding-left: 10px;
`;

export default HSCEBalancePage;
