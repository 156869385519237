import React, { useState, useEffect } from 'react';
import { BondLayout, Link, Card, Pair } from 'components';
import { getBondBalance } from 'api';
import { t, formatDate } from 'utils';

const BondBalancePage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getBondBalance(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card
        title={formatDate(data.date)}
        extra={<Link to={`/bond/balance/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('bondbalance.date')} value={formatDate(data.date)} />
        <Pair label={t('bondbalance.equity')} value={data.equity} />
        <Pair label={t('bondbalance.share')} value={data.share} />
        <Pair label={t('bondbalance.nav')} value={data.nav} />
        <Pair label={t('bondbalance.acc_nav')} value={data.acc_nav} />
        <Pair
          label={t('bondbalance.annualized_returns')}
          value={`${(data.annualized_returns * 100).toFixed(2)}%`}
        />
      </Card>
    </BondLayout>
  );
};

export default BondBalancePage;
