import React from 'react';
import { Select } from 'components';
import { t } from 'utils';

const TYPES = [
  'BASE_OPEN_SHORT_COUNTER_OPEN_LONG',
  'BASE_OPEN_LONG_COUNTER_OPEN_SHORT',
  'BASE_SETTLE_LONG_COUNTER_SETTLE_SHORT',
  'BASE_SETTLE_SHORT_COUNTER_SETTLE_LONG'
];

const BTCTradingTypeSelect = props => {
  return (
    <Select style={{ width: '100%' }} {...props}>
      <option value="" />
      {TYPES.map(x => (
        <option key={x} value={x}>
          {t(`constants.task.btc.trading_type.${x}`)}
        </option>
      ))}
    </Select>
  );
};

export default BTCTradingTypeSelect;
