/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState } from 'react';
import { NumberInput, alert, confirm, Button } from 'components';
import { createBTCPingCang } from 'api';

const BTCPingCangForm = ({ prefix, position, fundId }) => {
  const [value, setValue] = useState(0);
  const max = position.amount;

  function handleSubmit() {
    if (value < 0 || value > max) {
      return alert('不符合');
    }

    confirm('确认平仓').then(() => {
      createBTCPingCang({
        fund_id: fundId,
        xchange: prefix,
        symbol: position.symbol,
        trade_side: position.trade_side,
        current_qty: position.amount,
        settle_qty: value
      }).then(({ data }) => {
        if (data.code === 500) {
          alert(data.message);
        } else if (data.code === 200) {
          alert('平仓成功').then(() => {
            window.location.reload();
          });
        }
      });
    });
  }

  function up() {
    setValue(Math.min(value + 100, max));
  }

  function down() {
    setValue(Math.max(value - 100, 0));
  }

  return (
    <div
      css={css`
        display: flex;
      `}
    >
      <NumberInput
        value={value}
        onChange={value => setValue(value)}
        step={100}
        max={position.amount}
      />
      <div
        css={css`
          display: flex;
        `}
      >
        <div>
          <button onClick={up}>+</button>
          <button onClick={down}>-</button>
        </div>
        <Button onClick={handleSubmit}>平仓</Button>
      </div>
    </div>
  );
};

export default BTCPingCangForm;
