import { createContext, useContext } from 'react';

export const CurrentUserContext = createContext({
  token: null,
  user: null,
  setUser: null,
  setToken: null
});

export const useCurrentUser = () => {
  const context = useContext(CurrentUserContext);
  return context;
};
