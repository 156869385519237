/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Layout, Link, Button, confirm, Task } from 'components';
import { getTask, updateTaskStatus } from 'api';
import {
  t,
  useCurrentUser,
  canConfirmTask,
  canPauseTask,
  canResumeTask,
  canEditTask,
  canDeleteTask,
  canFinishTask
} from 'utils';

const TaskPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const [submitting, setSubmitting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [pausing, setPausing] = useState(false);
  const { loading, data: task } = state;
  const { user } = useCurrentUser();

  useEffect(() => {
    getTask(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function confirmTask() {
    if (submitting) return;

    confirm('确认任务?').then(() => {
      setSubmitting(true);
      updateTaskStatus(id, 'confirmed').then(() => {
        navigate(`/${task.strategy.type}/${task.fund.name}`);
      });
    });
  }

  function pauseTask() {
    if (pausing) return;

    confirm('暂停任务?').then(() => {
      setPausing(true);
      updateTaskStatus(id, 'paused').then(() => {
        navigate(`/tasks?status=paused`);
      });
    });
  }

  function startTask() {
    if (submitting) return;

    confirm('启动任务?').then(() => {
      setSubmitting(true);
      updateTaskStatus(id, 'confirmed').then(() => {
        navigate(`/tasks?status=confirmed`);
      });
    });
  }

  function finishTask() {
    if (submitting) return;

    confirm('停止任务?').then(() => {
      setSubmitting(true);
      updateTaskStatus(id, 'finished').then(() => {
        navigate(`/tasks?status=finished`);
      });
    });
  }

  function deleteTask() {
    if (deleting) return;

    confirm('删除任务?').then(() => {
      setDeleting(true);
      updateTaskStatus(id, 'archived').then(() => {
        // todo: fix
        // this.setState({ task, deleting: false });
      });
    });
  }

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 20px;

          button {
            margin-left: 8px;
          }
        `}
      >
        <div
          css={css`
            margin-right: auto;
          `}
        >
          {task.created.name} 于 {moment(task.created_at).format('lll')} 创建
        </div>

        {canEditTask(user, task.fund) &&
        (task.status === 'pending' || task.status === 'paused') ? (
          <Button component={Link} to={`/tasks/${task.id}/edit`}>
            {t('edit')}
          </Button>
        ) : null}

        {canDeleteTask(user, task.fund) && task.status === 'pending' ? (
          <Button onClick={deleteTask} loading={deleting}>
            {t('delete')}
          </Button>
        ) : null}

        {canConfirmTask(user, task.fund) && task.status === 'pending' ? (
          <Button
            appearance="primary"
            onClick={confirmTask}
            loading={submitting}
          >
            {t('confirm')}
          </Button>
        ) : null}

        {canPauseTask(user, task.fund) && task.status === 'confirmed' ? (
          <Button onClick={pauseTask}>{t('pause')}</Button>
        ) : null}

        {canResumeTask(user, task.fund) && task.status === 'paused' ? (
          <Button appearance="primary" onClick={startTask} loading={submitting}>
            {t('start')}
          </Button>
        ) : null}

        {canFinishTask(user, task.fund) && task.status !== 'finished' ? (
          <Button onClick={finishTask}>{t('finish')}</Button>
        ) : null}
      </div>

      <Task task={task} strategy={task.strategy} fund={task.fund} />
    </Layout>
  );
};

export default TaskPage;
