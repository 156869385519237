/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import SimpleBar from 'simplebar-react';

const Table = props => {
  return (
    <div
      {...props}
      css={css`
        font-size: 13px;
      `}
    />
  );
};

Table.Head = props => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        height: 32px;
        flex-shrink: 0;
        background: #f5f6f7;
        border-bottom: 1px solid #e4e7eb;
      `}
    />
  );
};

Table.Body = props => {
  return (
    <SimpleBar
      {...props}
      css={css`
        flex: 1;
      `}
    />
  );
};

Table.Row = props => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        height: 48px;
        border-bottom: 1px solid #edf0f2;
      `}
    />
  );
};

Table.TextHeaderCell = ({ children, ...props }) => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        align-items: center;
        padding-left: 4px;
        padding-right: 4px;
        min-width: 0;
        flex: 1;
      `}
    >
      <span
        css={css`
          flex: 1;
          letter-spacing: 0;
          color: #425a70;
        `}
      >
        {children}
      </span>
    </div>
  );
};

Table.TextCell = ({ children, ...props }) => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1;
        padding-right: 4px;
        padding-left: 4px;
      `}
    >
      <span
        css={css`
          flex: 1;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          letter-spacing: 0;
        `}
      >
        {children}
      </span>
    </div>
  );
};

export default Table;
