import styled from '@emotion/styled';
import React from 'react';
import { avatarUrl } from 'utils';

const Avatar = ({ id, ...props }) => <Image src={avatarUrl(id)} {...props} />;

Avatar.defaultProps = {
  size: 30
};

const Image = styled.img`
  border-radius: 50%;
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};
`;

export default Avatar;
