import React, { useState, useEffect } from 'react';
import { BTCLayout, BTCFundUnitForm, Card } from 'components';
import { getBTCFundUnit, updateBTCFundUnit } from 'api';
import { t, navigate } from 'utils';

const BTCFundUnitEditPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getBTCFundUnit(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateBTCFundUnit(id, values).then(({ data }) => {
      setSubmitting(false);
      navigate(`/fund_units/${data.id}`);
    });
  }

  if (loading) return <BTCLayout loading />;

  return (
    <BTCLayout>
      <Card title={t('btcfundunit.edit')}>
        <BTCFundUnitForm initialValues={data} onSubmit={handleSubmit} />
      </Card>
    </BTCLayout>
  );
};

export default BTCFundUnitEditPage;
