/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormSubmit,
  Button,
  HSCEAllowedDirectionSelect
} from 'components';
import { updateTaskStatus } from 'api';
import { confirm } from 'components';
import {
  t,
  useCurrentUser,
  canPauseTask,
  canResumeTask,
  canFinishTask
} from 'utils';

const HSCETaskForm = ({ task, strategy, fund, onSubmit }) => {
  const [pausing, setPausing] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const { user } = useCurrentUser();

  function pauseTask() {
    if (pausing) return;
    confirm('暂停任务?').then(() => {
      updateTaskStatus(task.id, 'paused').then(() => {
        setPausing(false);
        window.location.reload();
      });
    });
  }

  function resumeTask() {
    if (resuming) return;
    confirm('启动任务?').then(() => {
      updateTaskStatus(task.id, 'confirmed').then(() => {
        setResuming(false);
        window.location.reload();
      });
    });
  }

  function finishTask() {
    if (finishing) return;
    confirm('停止任务?').then(() => {
      updateTaskStatus(task.id, 'finished').then(() => {
        setFinishing(false);
        window.location.reload();
      });
    });
  }

  return (
    <Form
      initialValues={{
        baseline_diff: task.vars.baseline_diff,
        exchange_rate_today: task.vars.exchange_rate_today,
        allowed_direction: task.vars.allowed_direction,
        exchange_rate_yesterday: task.vars.exchange_rate_yesterday,
        net_worth_yesterday: task.vars.net_worth_yesterday,
        index_close_yesterday: task.vars.index_close_yesterday
      }}
      onSubmit={onSubmit}
    >
      <TaskFields strategyName={strategy.name} />

      <div
        css={css`
          text-align: right;
          margin-top: 10px;
          button {
            margin-left: 10px;
          }
        `}
      >
        {task.status === 'confirmed' && canPauseTask(user, fund) ? (
          <Button onClick={pauseTask} loading={pausing}>
            {t('task.pause')}
          </Button>
        ) : null}
        {task.status === 'paused' && canResumeTask(user, fund) ? (
          <Button onClick={resumeTask} loading={resuming}>
            {t('task.resume')}
          </Button>
        ) : null}

        {canFinishTask(user, fund) ? (
          <Button onClick={finishTask} loading={finishing}>
            {t('task.finish')}
          </Button>
        ) : null}
        <FormSubmit>{t('update')}</FormSubmit>
      </div>
    </Form>
  );
};

const TaskFields = ({ strategyName }) => {
  switch (strategyName) {
    case 'btc_position_transit':
      return null;
    case 'btc_grid_arbitrage':
      return null;
    case 'hsce_position_transit':
      return (
        <Fragment>
          <Row>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.baseline_diff')}
                name="baseline_diff"
              />
            </Col>
          </Row>
        </Fragment>
      );
    case 'hsce_grid_arbitrage':
      return (
        <Fragment>
          <Row>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.baseline_diff')}
                name="baseline_diff"
              />
            </Col>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.exchange_rate_today')}
                name="exchange_rate_today"
              />
            </Col>
            <Col>
              <FormGroup
                label={t(`task.vars.hsce.allowed_direction`)}
                name="allowed_direction"
                component={HSCEAllowedDirectionSelect}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.exchange_rate_yesterday')}
                name="exchange_rate_yesterday"
              />
            </Col>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.net_worth_yesterday')}
                name="net_worth_yesterday"
              />
            </Col>
            <Col>
              <FormGroup
                label={t('task.vars.hsce.index_close_yesterday')}
                name="index_close_yesterday"
              />
            </Col>
          </Row>
        </Fragment>
      );
  }
};

export default HSCETaskForm;
