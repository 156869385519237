import React from 'react';
import { Layout, GatewayList } from 'components';

const GatewayListPage = () => {
  return (
    <Layout>
      <GatewayList />
    </Layout>
  );
};

export default GatewayListPage;
