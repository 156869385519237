import { connect } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { BTCLayout, BTCFundUnitList, Select, Pagination } from 'components';
import { getParams, navigate } from 'utils';
import { getBTCFundList } from 'api';

const BTCFundUnitListPage = ({ fundId, page }) => {
  const [state, setState] = useState({
    loading: true,
    funds: [],
    fund: null
  });
  const { loading, funds, fund } = state;

  useEffect(() => {
    getBTCFundList().then(({ data: funds }) => {
      const fund = fundId ? funds.filter(d => d.id === fundId)[0] : funds[0];
      setState({ loading: false, funds, fund });
    });
  }, [fundId, page]);

  function handleFundChange(value) {
    navigate(`/fund_units?fund_id=${value}`);
  }

  function handlePageChange(page) {
    navigate(`/fund_units?fund_id=${fund.id}&page=${page}`);
  }

  if (loading) {
    return <BTCLayout loading />;
  }

  return (
    <BTCLayout>
      <BTCFundUnitList
        title={
          <Select value={fund.id} onChange={handleFundChange}>
            {funds.map(d => (
              <option key={d.id} value={d.id}>
                {d.display}
              </option>
            ))}
          </Select>
        }
        fundId={fund.id}
        page={page}
      />
      <Pagination current={page} onChange={handlePageChange} />
    </BTCLayout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || '',
    page: params.page ? parseInt(params.page, 10) : 1
  };
};

export default connect(mapStateToProps)(BTCFundUnitListPage);
