import React, { useState, useEffect } from 'react';
import {
  BTCLayout,
  Card,
  Button,
  Link,
  Row,
  Col,
  BTCTaskForm,
  BTCFundDetails,
  BTCTaskStatus
} from 'components';
import { getFund, getFundTasks } from 'api';
import { t, canCreateTask } from 'utils';
import { useCurrentUser } from 'context';

const BTCFundPage = ({ name }) => {
  const [loading, setLoading] = useState(true);
  const [fund, setFund] = useState(null);
  const [tasks, setTasks] = useState([]);
  const { user } = useCurrentUser();

  useEffect(() => {
    setLoading(true);
    getFund(name).then(({ data }) => {
      setFund(data);

      getFundTasks(data.id, { status: 'confirmed' }).then(({ data }) => {
        setTasks(data);
        setLoading(false);
      });
    });
  }, [name]);

  if (loading) {
    return <BTCLayout loading />;
  }

  return (
    <BTCLayout fluid title={fund.display}>
      <Card
        title={fund.display}
        extra={
          canCreateTask(user, fund) ? (
            <Button
              appearance="primary"
              component={Link}
              to={`/tasks/new?fund_id=${fund.id}`}
            >
              {t('task.new')}
            </Button>
          ) : null
        }
      />

      {tasks.map(task => (
        <Row key={task.id} style={{ marginTop: 20 }}>
          <Col>
            <Card
              title="任务"
              extra={<Link to={`/tasks/${task.id}`}>{t('view')}</Link>}
            >
              <BTCTaskForm
                task={task}
                fund={fund}
                strategy={{ name: task.strategy.name }}
              />
            </Card>
          </Col>
          <Col>
            <BTCTaskStatus task={task} />
          </Col>
        </Row>
      ))}

      <BTCFundDetails id={fund.id} />
    </BTCLayout>
  );
};

export default BTCFundPage;
