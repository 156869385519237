import React from 'react';
import { Layout, FundList } from 'components';
import { t } from 'utils';

const FundListPage = () => {
  return (
    <Layout title={t('fund.list')}>
      <FundList />
    </Layout>
  );
};

export default FundListPage;
