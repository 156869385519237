import React, { useState, useEffect } from 'react';
import { Card, SeriesChart, Empty } from 'components';
import { getHSCEPrices } from 'api';
import { formatTime } from 'utils';

const HSCEPrices = ({ index, setIndex }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [day, setDay] = useState(null);

  function load() {
    return getHSCEPrices().then(({ data }) => {
      if (!data[0].Series) return;

      const date = data[0].Series[0].values[0][0];
      const day = {
        open: new Date(
          date.replace(/T\d\d:\d\d:\d\dZ/, 'T01:30:00Z')
        ).getTime(),
        break: new Date(
          date.replace(/T\d\d:\d\d:\d\dZ/, 'T03:30:00Z')
        ).getTime(),
        resume: new Date(
          date.replace(/T\d\d:\d\d:\d\dZ/, 'T05:00:00Z')
        ).getTime(),
        close: new Date(
          date.replace(/T\d\d:\d\d:\d\dZ/, 'T07:00:00Z')
        ).getTime()
      };

      day.rest = day.resume - day.break;

      setDay(day);
      setData(
        data[0].Series.map(s => ({
          title: s.tags.symbol,
          data: s.values
            .map(v => ({
              x: new Date(v[0]).getTime(),
              y: v[1]
            }))
            .filter(
              ({ x, y }) =>
                y &&
                ((x >= day.open && x <= day.break) ||
                  (x >= day.resume && x <= day.close))
            )
            .map(({ x, y }) => ({
              t: x,
              x: x >= day.resume ? x - day.rest : x,
              y
            }))
            .sort((a, b) => a.x - b.x)
        }))
      );
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, []);

  if (loading) return <Card loading />;

  if (!data.length) {
    return (
      <Card>
        <Empty>暂无数据</Empty>
      </Card>
    );
  }

  return (
    <Card>
      <SeriesChart
        index={index}
        setIndex={setIndex}
        series={data}
        xDomain={[day.open, day.close - day.rest]}
        xTickFormat={x => formatTime(x > day.break ? x + day.rest : x)}
        xTickValues={[day.open, day.break, day.close - day.rest]}
      />
    </Card>
  );
};

export default HSCEPrices;
