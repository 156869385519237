import React, { useState, useEffect } from 'react';
import { BTCLayout, Card, Link, Pair } from 'components';
import { getBTCDividend } from 'api';
import { t, formatDate } from 'utils';

const BTCDividendPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getBTCDividend(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  if (loading) return <BTCLayout loading />;

  return (
    <BTCLayout>
      <Card
        title={formatDate(data.date)}
        extra={<Link to={`/dividends/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('btcdividend.dividends')} value={data.dividends} />
      </Card>
    </BTCLayout>
  );
};

export default BTCDividendPage;
