/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Button } from './';

const Pagination = ({ current, onChange }) => (
  <div
    css={css`
      display: flex;
      margin-top: 20px;
      align-items: center;
    `}
  >
    {current > 1 ? (
      <Button onClick={() => onChange(current - 1)}>上一页</Button>
    ) : null}
    <Button
      onClick={() => onChange(current + 1)}
      css={css`
        margin-left: auto;
      `}
    >
      下一页
    </Button>
  </div>
);

export default Pagination;
