import React, { useState, useEffect } from 'react';
import { Link, Card, Table } from 'components';
import { getBTCDividendList } from 'api';
import { t, formatDate } from 'utils';

const BTCDividendList = ({ title, fundId, page }) => {
  const [state, setState] = useState({ loading: true, data: [] });
  const { loading, data } = state;

  useEffect(() => {
    getBTCDividendList({ fund_id: fundId, page }).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [fundId, page]);

  if (loading) return <Card title={title} loading />;

  return (
    <Card
      title={title}
      extra={
        <Link to={`/dividends/new?fund_id=${fundId}`}>
          {t('btcdividend.new')}
        </Link>
      }
    >
      <Table
        columns={[
          {
            key: 'date',
            title: t('btcdividend.date'),
            render: ({ date }) => formatDate(date)
          },
          { key: 'dividends', title: t('btcdividend.dividends') },
          {
            key: 'action',
            render: ({ key }) => (
              <div className="text-right">
                <Link to={`/dividends/${key}`}>{t('view')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/dividends/${key}/edit`}>{t('edit')}</Link>
              </div>
            )
          }
        ]}
        rows={data.map(d => ({ ...d, key: d.id }))}
      />
    </Card>
  );
};

export default BTCDividendList;
