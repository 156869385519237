import { request, auth } from 'utils';

export const getLogs = params => request.get('/users/logs', params);
export const getUserLogs = id => request.get(`/users/${id}/logs`);

export const getUsers = params => auth.get('/users', params);
export const createUser = params => auth.post('/users', params);
export const getUser = id => auth.get(`/users/${id}`);
export const getCurrentUser = params => auth.get('/self', params);
export const updateUser = (id, params) => auth.put(`/users/${id}`, params);
export const updateUserPassword = (id, data) =>
  auth.put(`/users/${id}/password`, data);
export const updateCurrentUserPassword = data =>
  auth.put(`/users/password`, data);
