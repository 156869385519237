import React, { useState, useEffect } from 'react';
import {
  BondLayout,
  Card,
  Button,
  Link,
  Select,
  Table,
  Pagination
} from 'components';
import { getFund, getBondTaskList, getBondTaskStatuses } from 'api';
import { t, useCurrentUser, getParams, navigate, canEditBondTask } from 'utils';

const BondFundPage = ({ name }) => {
  const [loading, setLoading] = useState(true);
  const [fund, setFund] = useState(null);
  const [statuses, setStatuses] = useState([]);
  const [tasks, setTasks] = useState([]);
  const { user } = useCurrentUser();
  const params = getParams();
  const page = params.page ? parseInt(params.page, 10) : 1;

  useEffect(() => {
    setLoading(true);
    getFund(name).then(({ data: fund }) => {
      setFund(fund);

      getBondTaskStatuses().then(({ data: statuses }) => {
        setStatuses(statuses);

        getBondTaskList({
          fund_id: fund.id,
          status: params.status,
          page
        }).then(({ data: tasks }) => {
          setTasks(tasks);
          setLoading(false);
        });
      });
    });
  }, [name, params.status, page]);

  function handleStatusChange(status) {
    navigate(`/bond/funds/${fund.name}?status=${status}`);
  }

  function handlePageChange(page) {
    navigate(`/bond/funds/${name}?status=${status}&page=${page}`);
  }

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card
        title={
          <Select value={params.status} onChange={handleStatusChange}>
            <option value="">全部</option>
            {statuses.map(({ id, display }) => (
              <option key={id} value={id}>
                {display}
              </option>
            ))}
          </Select>
        }
        extra={
          <Button
            appearance="primary"
            component={Link}
            to={`/bond/tasks/new?fund_id=${fund.id}`}
          >
            新建任务
          </Button>
        }
      >
        <Table
          getRowKey={({ id }) => id}
          rows={tasks}
          columns={[
            { key: 'file_no', title: t('bondtask.file_no') },
            { key: 'securities_code', title: t('bondtask.securities_code') },
            { key: 'securities_name', title: t('bondtask.securities_name') },
            {
              key: 'trade_side',
              title: t('bondtask.trade_side'),
              render: task =>
                t(`constants.bondtask.trade_side.${task.trade_side}`)
            },
            {
              key: 'expectation_price',
              title: t('bondtask.expectation_price')
            },
            {
              key: 'expectation_amount',
              title: t('bondtask.expectation_amount')
            },
            {
              key: 'current_vertex_display',
              title: t('bondtask.current_vertex_display')
            },
            {
              key: 'fund.display',
              title: t('bondtask.fund.display')
            },
            {
              key: 'created.name',
              title: t('bondtask.created.name')
            },
            {
              key: 'action',
              render: task => (
                <div className="text-right">
                  {canEditBondTask(user, task) ? (
                    <Link to={`/bond/tasks/${task.id}/edit`}>{t('edit')}</Link>
                  ) : null}
                  &nbsp;|&nbsp;
                  <Link to={`/bond/tasks/${task.id}`}>{t('view')}</Link>
                </div>
              )
            }
          ]}
        />
      </Card>

      <Pagination current={page} onChange={handlePageChange} />
    </BondLayout>
  );
};

export default BondFundPage;
