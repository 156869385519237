import React, { Fragment } from 'react';
import { Row, Col, TextInput, FormGroup } from 'components';
import { t } from 'utils';

const TaskConfigsFields = ({ task, strategy }) => {
  const { name } = strategy;
  const disabled = task.status === 'paused';

  if (name === 'hsce_grid_arbitrage' || name === 'hsce_position_transit') {
    return (
      <Fragment>
        <Row>
          <Group
            prefix="hsce"
            name="max_volume_per_trade"
            disabled={disabled}
          />
          <Group
            prefix="hsce"
            name="base_volume_percentage"
            disabled={disabled}
          />
          <Group
            prefix="hsce"
            name="counter_volume_percentage"
            disabled={disabled}
          />
          <Group
            prefix="hsce"
            name="counter_market_price_offset"
            disabled={disabled}
          />
        </Row>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Row>
        <Group prefix="btc" name="max_volume_per_trade" disabled={disabled} />
        <Group prefix="btc" name="base_volume_percentage" disabled={disabled} />
        <Group
          prefix="btc"
          name="counter_volume_percentage"
          disabled={disabled}
        />
      </Row>

      <Row>
        <Group prefix="btc" name="taker_price_threshold" disabled={disabled} />
        <Group
          prefix="btc"
          name="base_maker_price_offset"
          disabled={disabled}
        />
      </Row>
      <Row>
        <Group prefix="btc" name="counter_price_spread" disabled={disabled} />
        <Group
          prefix="btc"
          name="counter_market_price_offset"
          disabled={disabled}
        />
      </Row>
    </Fragment>
  );
};

const Group = ({ prefix, name, ...props }) => {
  return (
    <Col>
      <FormGroup
        {...props}
        label={t(`task.configs.${prefix ? `${prefix}.` : ''}${name}`)}
        name={`configs.${name}`}
      />
    </Col>
  );
};

Group.defaultProps = {
  component: TextInput,
  disabled: false
};

export default TaskConfigsFields;
