import React, { useState, useEffect } from 'react';
import { DiscreteColorLegend } from 'react-vis';
import { Card, BTCChart } from 'components';
import { getBTCDiffs } from 'api';
import { t, formatTime, formatAmount, formatTimeInSecond } from 'utils';

const BTCDiffs = ({ span, interval, timer }) => {
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState([]);

  useEffect(() => {
    load({ span, interval }).then(() => {
      setLoading(false);
    });

    const t = setInterval(() => load({ span, interval }), timer);
    return () => clearInterval(t);
  }, [span, interval, timer]);

  function load(params) {
    return getBTCDiffs(params).then(({ data }) => {
      setSeries(
        data[0].Series.map((s, i) => ({
          title:
            t(`constants.contracts.${s.tags.symbol1}`) +
            ' - ' +
            t(`constants.contracts.${s.tags.symbol2}`),
          disabled: series[i] ? series[i].disabled : false,
          data: s.values
            .filter(v => v[1])
            .map(v => ({
              x: new Date(v[0]).getTime(),
              y: v[1]
            }))
        }))
      );
    });
  }

  function handleLegendClick(item, i) {
    series[i].disabled = !series[i].disabled;
    setSeries(series);
  }

  if (loading) {
    return <Card style={{ marginTop: 20 }} loading />;
  }

  return (
    <Card style={{ marginTop: 20 }}>
      <DiscreteColorLegend
        items={series}
        orientation="horizontal"
        onItemClick={handleLegendClick}
      />
      <BTCChart
        id="btc-diffs"
        series={series}
        xAxis={{ tickFormat: formatTime }}
        yAxis={{ tickFormat: formatAmount, tickTotal: 6 }}
        crosshairTitleFormat={d => ({
          title: 'Time',
          value: formatTimeInSecond(d[0].x)
        })}
      />
    </Card>
  );
};

export default BTCDiffs;
