import React from 'react';
import { Select } from 'components';
import { TASK_STATUSES } from 'constants';
import { t } from 'utils';

const TaskStatusSelect = ({ children, ...props }) => (
  <Select {...props}>
    {children}
    {TASK_STATUSES.map(key => (
      <option key={key} value={key}>
        {t(`constants.task.status.${key}`)}
      </option>
    ))}
  </Select>
);

export default TaskStatusSelect;
