import React, { useState, useEffect } from 'react';
import { Layout, Card, TaskForm } from 'components';
import { getTask, updateTask } from 'api';

const TaskEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getTask(id).then(({ data }) => {
      setState({ data, loading: false });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    return updateTask(id, values)
      .then(() => {
        navigate(`/tasks/${id}`);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data: task } = state;

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <Card>
        <TaskForm
          task={task}
          strategy={task.strategy}
          onSubmit={handleSubmit}
        />
      </Card>
    </Layout>
  );
};

export default TaskEditPage;
