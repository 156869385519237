import React from 'react';
import { connect } from 'react-redux';
import { BTCLayout, Card, BTCBalanceForm } from 'components';
import { createBTCBalance } from 'api';
import { t, getParams, startOfToday, navigate } from 'utils';

const BTCBalanceNewPage = ({ fundId }) => {
  function handleSubmit(values, { setSubmitting }) {
    createBTCBalance({ ...values, fund_id: fundId })
      .then(({ data }) => {
        navigate(`/balance/${data.id}`);
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  }

  return (
    <BTCLayout>
      <Card title={t('btcbalance.new')}>
        <BTCBalanceForm
          initialValues={{ date: startOfToday() }}
          onSubmit={handleSubmit}
        />
      </Card>
    </BTCLayout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || ''
  };
};

export default connect(mapStateToProps)(BTCBalanceNewPage);
