import { request } from 'utils';

export const getBTCDiffs = params => request.get('/btc/diffs', params);
export const getBTCPrice = params => request.get('/btc/price', params);
export const getBTCDiff = params => request.get('/btc/diff', params);
export const getBTCVol = params => request.get('/btc/vol', params);
export const updateBTCTask = (id, params) =>
  request.put(`/btc/tasks/${id}`, params);
export const getBTCTaskStatus = (id, params) =>
  request.get(`/btc/tasks/${id}/status`, params);
export const getBTCFundDetails = (id, params) =>
  request.get(`/btc/funds/${id}/details`, params);

export const createBTCDividend = params =>
  request.post('/btc/dividends', params);
export const getBTCDividend = id => request.get(`/btc/dividends/${id}`);
export const getBTCDividendList = params =>
  request.get('/btc/dividends', params);
export const updateBTCDividend = (id, params) =>
  request.put(`/btc/dividends/${id}`, params);

export const createBTCFundUnit = params =>
  request.post('/btc/fund_units', params);
export const getBTCFundUnit = id => request.get(`/btc/fund_units/${id}`);
export const getBTCFundUnitList = params =>
  request.get('/btc/fund_units', params);
export const updateBTCFundUnit = (id, params) =>
  request.put(`/btc/fund_units/${id}`, params);

export const getBTCBalanceList = params => request.get('/btc/balance', params);
export const getBTCBalance = id => request.get(`/btc/balance/${id}`);
export const createBTCBalance = params => request.post('/btc/balance', params);
export const updateBTCBalance = (id, params) =>
  request.put(`/btc/balance/${id}`, params);

export const getBTCFundList = params => request.get('/btc/funds', params);

export const createBTCPingCang = params =>
  request.post('/btc/trade/settle', params);
