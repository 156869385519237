import find from 'lodash/find';
import React, { useState, useEffect } from 'react';
import { getStrategyList } from 'api';
import { Select } from 'components';

const StrategySelect = ({ onChange, fundId, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getStrategyList({ fund_id: fundId }).then(({ data }) => setOptions(data));
  }, [fundId]);

  function handleChange(value, name) {
    onChange(
      value,
      name,
      find(options, d => d.id === value)
    );
  }

  return (
    <Select {...props} style={{ width: '100%' }} onChange={handleChange}>
      <option value="" />
      {options.map(d => (
        <option key={d.id} value={d.id}>
          {d.display}
        </option>
      ))}
    </Select>
  );
};

export default StrategySelect;
