import styled from '@emotion/styled';
import React from 'react';
import { Steps } from 'components';
import { t } from 'utils';

const TaskSteps = ({ current }) => (
  <Container>
    <Steps current={current} steps={t('task.steps')} />
  </Container>
);

TaskSteps.defaultProps = {
  current: 1
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export default TaskSteps;
