import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card } from 'components';
import { t } from 'utils';
import { getMonitor, updateMonitor } from '../../actions';
import MonitorForm from './MonitorForm';

class MonitorEditPage extends Component {
  state = {
    monitor: null
  };

  handleSubmit = (values, { setSubmitting }) => {
    const { monitor } = this.state;

    this.props
      .dispatch(updateMonitor(monitor.id, values))
      // .then(monitor => {
      //   this.setState({ monitor });
      // })
      .then(() => {
        setSubmitting(false);
        this.props.navigate(`/monitors/${monitor.id}`);
      });
  };

  componentDidMount() {
    this.props.dispatch(getMonitor(this.props.id)).then(({ data }) => {
      this.setState({ monitor: data });
    });
  }

  render() {
    const { monitor } = this.state;

    if (!monitor) {
      return <Layout loading />;
    }

    const values = Object.keys(monitor.vars).reduce((x, k) => {
      x[k] = monitor.vars[k].value;
      return x;
    }, {});

    values.id = monitor.id;
    values.template = monitor['template-id'];
    values.status = monitor.status;

    return (
      <Layout title={t('monitor.edit-page')}>
        <Card title={t('monitor.edit-page')} style={{ marginBottom: 20 }}>
          <MonitorForm monitor={values} onSubmit={this.handleSubmit} />
        </Card>
      </Layout>
    );
  }
}

export default connect()(MonitorEditPage);
