import React, { Fragment, useState, useEffect } from 'react';
import {
  MenuList,
  MenuItem,
  MenuButton,
  Dropdown,
  SubMenuItem
} from 'react-menu-list';
import {
  BondLayout,
  Card,
  BondBalanceChart,
  BondBalanceList,
  Button,
  BondBalanceDownloadButton,
  Link
} from 'components';
import { getBondFundList } from 'api';
import { t, getParams, navigate } from 'utils';
import groupBy from 'lodash/groupBy';

const BondBalanceListPage = () => {
  const [state, setState] = useState({
    loading: true,
    data: [],
    funds: [],
    fund: null
  });
  const { loading, data, funds, fund } = state;
  const params = getParams();
  const fundId = params.fund_id || '';

  useEffect(() => {
    setState({ loading: true, data: [], funds: [], fund: null });
    getBondFundList().then(({ data: funds }) => {
      const fund = fundId ? funds.filter(d => d.id === fundId)[0] : funds[0];
      setState({ loading: false, funds, fund, data });
    });
  }, [fundId]);

  function handleFundChange(code) {
    navigate(`/bond/balance?fund_id=${code}`);
  }

  function handlePageChange(page) {
    navigate(`/bond/balance?fund_id=${fund.id}&page=${page}`);
  }

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card
        title={
          <FundMenuList
            value={fund.id}
            onChange={handleFundChange}
            funds={funds}
          />
        }
        extra={
          <Fragment>
            <Button
              appearance="primary"
              component={Link}
              to={`/bond/balance/new?fund_id=${fund.id}`}
            >
              {t('bondbalance.new')}
            </Button>

            <BondBalanceDownloadButton
              fundId={fund.id}
              style={{ marginLeft: 10 }}
            >
              导出 Excel
            </BondBalanceDownloadButton>
          </Fragment>
        }
      >
        <BondBalanceChart fundId={fund.id} />
      </Card>

      <BondBalanceList fundId={fund.id} onPageChange={handlePageChange} />
    </BondLayout>
  );
};

function FundMenuList({ funds, value, onChange }) {
  const items = funds
    .sort((a, b) => (a.display > b.display ? 1 : -1))
    .map(f => {
      const p = f.display.split('-');
      return {
        id: f.id,
        type: p[0],
        name: p[1],
        display: f.display
      };
    });
  const options = groupBy(items, 'type');
  const fund = funds.filter(f => f.id === value)[0];

  return (
    <MenuButton
      className="MenuButton"
      menu={
        <Dropdown>
          <MenuList>
            {Object.keys(options).map(k => {
              return (
                <SubMenuItem
                  key={k}
                  className="SubMenuItem"
                  style={{ cursor: 'pointer', userSelect: 'none' }}
                  highlightedStyle={{ background: '#eee' }}
                  menu={
                    <Dropdown>
                      <MenuList>
                        {options[k].map(option => (
                          <MenuItem
                            key={option.id}
                            className="MenuItem"
                            onItemChosen={() => onChange(option.id)}
                            style={{ cursor: 'pointer', userSelect: 'none' }}
                            highlightedStyle={{ background: '#eee' }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </Dropdown>
                  }
                >
                  {k}
                </SubMenuItem>
              );
            })}
          </MenuList>
        </Dropdown>
      }
    >
      {fund ? fund.display : '选择 '}
    </MenuButton>
  );
}

export default BondBalanceListPage;
