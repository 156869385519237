import React, { useState, useEffect } from 'react';
import { getGatewayList } from 'api';
import { Select } from 'components';

const GatewaySelect = props => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getGatewayList().then(({ data }) => setOptions(data));
  }, []);

  return (
    <Select {...props} style={{ width: '100%' }}>
      <option value="" />
      {options.map(d => (
        <option key={d.id} value={d.id}>
          {d.name}
        </option>
      ))}
    </Select>
  );
};

export default GatewaySelect;
