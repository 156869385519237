import { css, Global } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import {
  HSCELayout,
  HSCEBalanceChart,
  HSCEBalanceDownloadButton,
  Card,
  Link,
  Table,
  Button,
  Pagination,
  Select
} from 'components';
import { t, formatDate, getParams, navigate } from 'utils';
import { getHSCEBalanceList, getHSCEFundList } from 'api';

const HSCEBalanceListPage = () => {
  const [state, setState] = useState({
    loading: true,
    data: [],
    funds: [],
    fund: null
  });
  const { loading, data, funds, fund } = state;
  const params = getParams();
  const page = params.page ? parseInt(params.page, 10) : 1;
  const fundId = params.fund_id || '';

  useEffect(() => {
    setState({ loading: true, data: [], funds: [], fund: null });
    getHSCEFundList().then(({ data: funds }) => {
      funds = funds.sort((a, b) =>
        a.closed === b.closed ? 0 : a.closed ? 1 : -1
      );
      const fund = fundId ? funds.filter(d => d.id === fundId)[0] : funds[0];
      getHSCEBalanceList({ fund_id: fund.id, page }).then(({ data }) => {
        setState({ loading: false, funds, fund, data });
      });
    });
  }, [fundId, page]);

  function handleFundChange(value) {
    navigate(`/balance?fund_id=${value}`);
  }

  function handlePageChange(page) {
    navigate(`/balance?fund_id=${fund.id}&page=${page}`);
  }

  if (loading) {
    return <HSCELayout loading />;
  }

  return (
    <HSCELayout>
      <Global
        styles={css`table {
        th, td {
          text-align: right !important;
        }`}
      />

      <Card
        title={
          <Select value={fund.id} onChange={handleFundChange}>
            {funds.map((d, i) => {
              const option = (
                <option key={d.id} value={d.id}>
                  {d.display}
                </option>
              );

              if (funds[i - 1] && !funds[i - 1].closed && funds[i].closed) {
                return (
                  <Fragment key={d.id}>
                    <option key="disabled" disabled>
                      _________
                    </option>
                    {option}
                  </Fragment>
                );
              }

              return option;
            })}
          </Select>
        }
        extra={
          <Fragment>
            <Button
              appearance="primary"
              component={Link}
              to={`/balance/new?fund_id=${fund.id}`}
            >
              {t('hscebalance.new')}
            </Button>

            <HSCEBalanceDownloadButton
              fundId={fund.id}
              style={{ marginLeft: 10 }}
            >
              导出 Excel
            </HSCEBalanceDownloadButton>
          </Fragment>
        }
      >
        <HSCEBalanceChart fundId={fund.id} />
      </Card>

      <Card>
        <Table
          getRowKey={({ id }) => id}
          rows={data}
          columns={[
            {
              key: 'date',
              title: t('hscebalance.date'),
              render: ({ date }) => formatDate(date)
            },
            {
              key: 'units',
              title: t('hscebalance.units'),
              render: ({ units }) => units.toFixed(2)
            },
            {
              key: 'total',
              title: t('hscebalance.total'),
              render: ({ total }) => total.toFixed(2)
            },
            {
              key: 'nav',
              title: t('hscebalance.nav'),
              render: ({ nav }) => nav.toFixed(4)
            },
            {
              key: 'acc_nav',
              title: t('hscebalance.acc_nav'),
              render: ({ acc_nav }) => acc_nav.toFixed(4)
            },
            {
              key: 'ar',
              title: t('hscebalance.ar'),
              render: ({ ar }) => `${(ar * 100).toFixed(2)}%`
            },
            {
              key: 'action',
              render: ({ id }) => (
                <div className="text-right">
                  <Link to={`/balance/${id}`}>{t('view')}</Link>
                  &nbsp;|&nbsp;
                  <Link to={`/balance/${id}/edit`}>{t('edit')}</Link>
                </div>
              )
            }
          ]}
        />
      </Card>

      <Pagination current={page} onChange={handlePageChange} />
    </HSCELayout>
  );
};

export default HSCEBalanceListPage;
