const config = {
  host:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : process.env.HERMES_API,
  auth_host:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8080'
      : process.env.HERMES_API
};

export default config;
