import styled from '@emotion/styled';
import React, { Component } from 'react';
import { Row, Col, Card, Loading } from 'components';
import { getBTCDiff } from 'api';
import { t } from 'utils';

class BTCStats extends Component {
  state = {
    loading: true,
    stats: []
  };

  load = () => {
    getBTCDiff().then(({ data }) => {
      this.setState({
        loading: false,
        stats: data[0].Series.map(x => ({
          title:
            t(`constants.contracts.${x.values[0][2]}`) +
            ' - ' +
            t(`constants.contracts.${x.values[0][3]}`),
          num: x.values[0][1]
        }))
      });
    });
  };

  componentDidMount() {
    this.load();
    this.timer = setInterval(this.load, this.props.timer);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  render() {
    const { loading, stats } = this.state;

    return (
      <Container>
        {stats.map(s => (
          <Col key={s.title}>
            {loading ? (
              <Card>
                <Loading />
              </Card>
            ) : (
              <Card>
                <Title>{s.title}</Title>
                <Num>{parseFloat(s.num.toFixed(2))}</Num>
              </Card>
            )}
          </Col>
        ))}
      </Container>
    );
  }
}

const Container = styled(Row)`
  margin-bottom: 20px;
`;

const Title = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 12px;
`;

const Num = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 40px;
  line-height: 100px;
  color: #5e72e4;
`;

export default BTCStats;
