import React, { useState, useEffect } from 'react';
import { Select } from 'components';
import { getGridList } from 'api';

const GridSelect = props => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getGridList().then(({ data }) => setOptions(data));
  }, []);

  return (
    <Select style={{ width: '100%' }} {...props}>
      <option value="" />
      {options.map(d => (
        <option key={d.id} value={d.lines}>
          {d.lines}
        </option>
      ))}
    </Select>
  );
};

export default GridSelect;
