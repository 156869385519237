import styled from '@emotion/styled';
import React, { Fragment, useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  Table,
  BTCPingCangForm,
  BTCTradeHistoryTable
} from 'components';
import { t, formatFixed } from 'utils';
import { getBTCFundDetails } from 'api';

const BTCFundDetails = ({ id }) => {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(null);

  function load() {
    return getBTCFundDetails(id).then(({ data }) => {
      // todo: remove to api
      data.bitmex_account.position_list = data.bitmex_account.position_list.filter(
        d => d.amount !== 0
      );
      data.okex_account.position_list = data.okex_account.position_list.filter(
        d => d.amount !== 0
      );

      setDetails(data);
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, [id]);

  if (loading) {
    return <Card loading style={{ marginTop: 20 }} />;
  }

  return (
    <Fragment>
      <Row style={{ marginTop: 20 }}>
        <Col md={6}>
          <Card title={t('btc.bitmex_account')}>
            <Account
              prefix="bitmex"
              account={details.bitmex_account}
              fundId={id}
            />
          </Card>
        </Col>
        <Col md={6}>
          <Card title={t('btc.okex_account')}>
            <Account prefix="okex" account={details.okex_account} fundId={id} />
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col md={6}>
          <Card title={t('btc.local_orders')}>
            {details.local_orders.length ? (
              <Table
                columns={ORDER_COLUMNS}
                rows={details.local_orders.map(d => ({
                  ...d,
                  key: d.order_id
                }))}
              />
            ) : (
              <Placeholder>暂无挂单</Placeholder>
            )}
          </Card>
        </Col>
        <Col md={6}>
          <Card title={t('btc.remote_orders')}>
            {details.remote_orders.length ? (
              <Table
                columns={ORDER_COLUMNS}
                rows={details.remote_orders.map(d => ({
                  ...d,
                  key: d.order_id
                }))}
              />
            ) : (
              <Placeholder>暂无挂单</Placeholder>
            )}
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

const Account = ({ prefix, account, fundId }) => (
  <Fragment>
    {account.balance_list.length ? (
      <Table
        columns={[
          { key: 'margin', title: t(`btc.balance.${prefix}.margin`) },
          {
            key: 'margin_leverage',
            title: t(`btc.balance.${prefix}.margin_leverage`)
          },
          { key: 'money_type', title: t(`btc.balance.${prefix}.money_type`) }
        ]}
        rows={account.balance_list.map((d, i) => ({
          key: i,
          ...d
        }))}
      />
    ) : null}

    {account.position_list.length ? (
      <Table
        columns={[
          { key: 'symbol', title: t('btc.position.symbol') },
          { key: 'amount', title: t('btc.position.amount') },
          prefix === 'okex'
            ? { key: 'available', title: t('btc.position.available') }
            : null,
          {
            key: 'trade_side',
            title: t('btc.position.trade_side'),
            render: ({ trade_side }) => (
              <span
                className={
                  trade_side === 'BUY' ||
                  trade_side === 'OPEN_LONG' ||
                  trade_side === 'SETTLE_SHORT'
                    ? 'red'
                    : 'green'
                }
              >
                {t(`constants.order.trade_side.${trade_side}`)}
              </span>
            )
          },
          {
            key: 'avg_price',
            title: t('btc.position.avg_price'),
            render: ({ avg_price }) => formatFixed(avg_price, 4)
          },
          {
            key: 'pingcang',
            title: '平仓',
            render: row => (
              <BTCPingCangForm prefix={prefix} position={row} fundId={fundId} />
            )
          }
        ].filter(Boolean)}
        rows={account.position_list.map((d, i) => ({
          ...d,
          key: i + ''
        }))}
      />
    ) : null}

    <BTCTradeHistoryTable data={account.trade_history} />
  </Fragment>
);

const TRADE_COLUMNS = [
  { key: 'create_time', title: t('btc.order.create_time') },
  { key: 'symbol', title: t('btc.order.symbol') },
  {
    key: 'filled_volume',
    title: t('btc.order.filled_volume')
  },
  {
    key: 'avg_price',
    title: t('btc.order.avg_price')
  },
  {
    key: 'side',
    title: t('btc.order.side'),
    render: ({ side }) => (
      <span
        className={
          side === 'BUY' || side === 'OPEN_LONG' || side === 'SETTLE_SHORT'
            ? 'red'
            : 'green'
        }
      >
        {t(`constants.order.side.${side}`)}
      </span>
    )
  },
  {
    key: 'status',
    title: t('btc.order.status'),
    render: ({ status }) => t(`constants.order.status.${status}`)
  }
];

const ORDER_COLUMNS = [
  TRADE_COLUMNS[0],
  TRADE_COLUMNS[1],
  { key: 'volume', title: t('btc.order.volume') },
  { key: 'price', title: t('btc.order.price') },
  ...TRADE_COLUMNS.slice(2)
];

const Placeholder = styled.div`
  text-align: center;
  padding: 0 20px;
  color: #999;
`;

export default BTCFundDetails;
