import React, { useState, useEffect } from 'react';
import { Link, Card, Table } from 'components';
import { getContracts } from 'api';
import { t } from 'utils';

const ContractList = () => {
  const [state, setState] = useState({ loading: true, data: [] });

  useEffect(() => {
    getContracts().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  const { loading, data } = state;

  if (loading) return <Card title={t('contract.list')} loading />;

  return (
    <Card
      title={t('contract.list')}
      extra={<Link to="/contracts/new">{t('contract.new')}</Link>}
    >
      <Table
        columns={[
          { key: 'name', title: t('contract.name') },
          {
            key: 'type',
            title: t('contract.type'),
            render: ({ type }) => t(`constants.contract.type.${type}`)
          },
          {
            key: 'display',
            title: t('contract.display')
          },
          {
            key: 'action',
            render: ({ key }) => (
              <div className="text-right">
                <Link to={`/contracts/${key}`}>{t('view')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/contracts/${key}/edit`}>{t('edit')}</Link>
              </div>
            )
          }
        ]}
        rows={data.map(d => ({ ...d, key: d.id }))}
      />
    </Card>
  );
};

export default ContractList;
