import React, { useState, useEffect } from 'react';
import { BondLayout, BondBalanceForm, Card } from 'components';
import { getBondBalance, updateBondBalance } from 'api';
import { t } from 'utils';

const BondBalanceEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getBondBalance(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateBondBalance(id, values)
      .then(() => {
        navigate(`/bond/balance/${id}`);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data } = state;

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card title={t('edit')}>
        <BondBalanceForm initialValues={data} onSubmit={handleSubmit} />
      </Card>
    </BondLayout>
  );
};

export default BondBalanceEditPage;
