import React, { Fragment, useEffect, useState } from 'react';
import { Card, Table, Pagination, Link } from 'components';
import { getBondBalanceList } from 'api';
import { t, formatDate, getParams } from 'utils';

const BondBalanceList = ({ fundId, onPageChange }) => {
  const [state, setState] = useState({ loading: true, data: [] });
  const { loading, data } = state;
  const params = getParams();
  const page = params.page ? parseInt(params.page, 10) : 1;

  useEffect(() => {
    getBondBalanceList({ fund_id: fundId, page }).then(({ data }) => {
      data.forEach(d => (d.date = formatDate(d.date)));
      setState({ loading: false, data });
    });
  }, [fundId, page]);

  if (loading) return <Card loading />;

  return (
    <Fragment>
      <Card>
        <Table
          getRowKey={({ id }) => id}
          rows={data}
          columns={[
            { key: 'date', title: t('bondbalance.date') },
            {
              key: 'share',
              title: t('bondbalance.share')
            },
            {
              key: 'nav',
              title: t('bondbalance.nav'),
              render: ({ nav }) => nav.toFixed(4)
            },
            {
              key: 'acc_nav',
              title: t('bondbalance.acc_nav'),
              render: ({ acc_nav }) => acc_nav.toFixed(4)
            },
            {
              key: 'equity',
              title: t('bondbalance.equity')
            },
            {
              key: 'annualized_returns',
              title: t('bondbalance.annualized_returns'),
              render: ({ annualized_returns }) =>
                `${(annualized_returns * 100).toFixed(2)}%`
            },
            {
              key: 'action',
              render: ({ id }) => (
                <div className="text-right">
                  <Link to={`/bond/balance/${id}`}>{t('view')}</Link>
                  &nbsp;|&nbsp;
                  <Link to={`/bond/balance/${id}/edit`}>{t('edit')}</Link>
                </div>
              )
            }
          ]}
        />
      </Card>
      <Pagination current={page} onChange={onPageChange} />
    </Fragment>
  );
};

export default BondBalanceList;
