import styled from '@emotion/styled';

export const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 4px 0;
  margin-bottom: 25px;
  color: #333;
  border-left: 4px solid #5e72e4;
  padding-left: 10px;
`;

export const Action = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;

  button:nth-of-type(1) {
    margin-right: auto;
  }
`;
