import React from 'react';
import { Form, FormGroup, FormSubmit } from 'components';

const UserPasswordForm = ({ onSubmit }) => {
  return (
    <Form
      initialValues={{ password: '', password_confirm: '' }}
      onSubmit={onSubmit}
    >
      <FormGroup label="新密码" type="password" name="password" />
      <FormGroup label="确认新密码" type="password" name="password_confirm" />
      <FormSubmit>更新密码</FormSubmit>
    </Form>
  );
};

export default UserPasswordForm;
