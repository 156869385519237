import React, { useState, useEffect } from 'react';
import { HSCELayout, HSCEBalanceForm, Card } from 'components';
import { getHSCEBalance, updateHSCEBalance } from 'api';
import { t } from 'utils';

const HSCEBalanceEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getHSCEBalance(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateHSCEBalance(id, values)
      .then(() => {
        navigate(`/balance/${id}`);
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data } = state;

  if (loading) return <HSCELayout loading />;

  return (
    <HSCELayout title={t('hscebalance.edit')}>
      <Card title={t('hscebalance.edit')}>
        <HSCEBalanceForm
          balance={{ ...data, date: new Date(data.date) }}
          onSubmit={handleSubmit}
        />
      </Card>
    </HSCELayout>
  );
};

export default HSCEBalanceEditPage;
