import { useCurrentUser } from 'context';

const AccessControl = ({ children, allowedRoles, renderNoAccess }) => {
  const { user } = useCurrentUser();
  const { role } = user;

  if (allowedRoles.indexOf(role) >= 0) {
    return children;
  }

  if (renderNoAccess) {
    return renderNoAccess({ role });
  }

  return null;
};

AccessControl.defaultProps = {
  allowedRoles: []
};

export default AccessControl;
