import React, { useState, useEffect } from 'react';
import { Layout, Card, Pair, Link } from 'components';
import { getContract } from 'api';
import { t } from 'utils';

const ContractPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getContract(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <Card
        title={data.display || data.name}
        extra={<Link to={`/contracts/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair
          label={t('contract.gateway_id')}
          value={
            <Link to={`/gateways/${data.gateway_id}`}>{data.gateway.name}</Link>
          }
        />
        <Pair label={t('contract.name')} value={data.name} />
        <Pair
          label={t('contract.type')}
          value={t(`constants.contract.type.${data.type}`)}
        />
        <Pair label={t('contract.display')} value={data.display} />
        <Pair label={t('contract.exchange')} value={data.exchange} />
        <Pair label={t('contract.symbol')} value={data.symbol} />
        <Pair label={t('contract.prompt')} value={data.prompt} />
        <Pair label={t('contract.unit_amount')} value={data.unit_amount} />
        <Pair
          label={t('contract.maker_brokerage_rate')}
          value={data.maker_brokerage_rate}
        />
        <Pair
          label={t('contract.taker_brokerage_rate')}
          value={data.taker_brokerage_rate}
        />
      </Card>
    </Layout>
  );
};

export default ContractPage;
