import styled from '@emotion/styled';
import React, { useState } from 'react';
import {
  HSCELayout,
  HSCEStats,
  HSCEPrices,
  HSCEDiffs,
  HSCEExchange
} from 'components';

const HSCEDashboardPage = () => {
  const [index, setIndex] = useState(null);

  return (
    <HSCELayout title="HSCE 控制面板" fluid>
      <HSCEStats />
      <Section>
        <HSCEPrices index={index} setIndex={setIndex} />
      </Section>
      <Section>
        <HSCEDiffs index={index} setIndex={setIndex} />
      </Section>
      <HSCEExchange />
    </HSCELayout>
  );
};

const Section = styled.div`
  margin-bottom: 20px;
`;

export default HSCEDashboardPage;
