import React from 'react';
import { Layout, Card, FundForm, alert } from 'components';
import { createFund } from 'api';
import { t } from 'utils';

const FundNewPage = ({ navigate }) => {
  function handleSubmit(values, { setSubmitting }) {
    return createFund(values)
      .then(() => {
        setSubmitting(false);
        navigate('/funds');
      })
      .catch(() => {
        alert('创建失败');
        setSubmitting(false);
      });
  }

  return (
    <Layout title={t('fund.new')}>
      <Card title={t('fund.new')}>
        <FundForm
          initialValues={{
            name: '',
            display: '',
            manager_ids: [],
            trader_ids: [],
            api: ''
          }}
          onSubmit={handleSubmit}
        />
      </Card>
    </Layout>
  );
};

export default FundNewPage;
