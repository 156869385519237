import styles from './monitor.page.module.scss';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from '@reach/router';
import { Layout, Card, Button } from 'components';
import { t } from 'utils';
import { getMonitor } from '../../actions';
import { deleteMonitor } from '../../monitor.action';

class MonitorPage extends Component {
  state = {
    monitor: null
  };

  componentDidMount() {
    this.props.dispatch(getMonitor(this.props.id)).then(({ data }) => {
      this.setState({ monitor: data });
    });
  }

  handleDelete = () => {
    this.props.dispatch(deleteMonitor(this.props.id)).then(() => {
      this.props.navigate('/monitors');
    });
  };

  render() {
    const { monitor } = this.state;
    const { handleDelete } = this.props;

    if (!monitor) {
      return <Layout loading />;
    }

    return (
      <Layout>
        <div className={styles.header}>
          <div className={styles.id}>{monitor.id}</div>
        </div>
        <VarsTable vars={monitor.vars} className={styles.section} />
        <Card className={styles.section}>
          <div className={styles.pair}>
            <span>
              {'上次启用：' + moment(monitor['last-enabled']).format('LLL')}
            </span>
            {t(`constants.monitor_status.${monitor.status}`)}
          </div>
        </Card>
        <div>
          <Button appearance="primary">
            <Link to={`/monitors/${monitor.id}/edit`}>{t('monitor.edit')}</Link>
          </Button>
          <Button appearance="primary" onClick={this.handleDelete}>
            {t('monitor.delete')}
          </Button>
        </div>
      </Layout>
    );
  }
}

const VarsTable = ({ vars, ...props }) => (
  <Card {...props}>
    {Object.keys(vars).map(key => (
      <div className={styles.pair} key={key}>
        <span>{key}</span>
        {vars[key].value}
      </div>
    ))}
  </Card>
);

export default connect()(MonitorPage);
