import { css, Global } from '@emotion/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  BTCLayout,
  BTCBalanceChart,
  Card,
  Link,
  Table,
  Button,
  Pagination,
  Select
} from 'components';
import { t, formatDate, getParams, navigate } from 'utils';
import { getBTCBalanceList, getBTCFundList } from 'api';

const BTCBalanceListPage = ({ fundId, page }) => {
  const [state, setState] = useState({
    loading: true,
    data: [],
    funds: [],
    fund: null
  });
  const { loading, data, funds, fund } = state;

  useEffect(() => {
    setState({ loading: true, data: [], funds: [], fund: null });
    getBTCFundList().then(({ data: funds }) => {
      const fund = fundId ? funds.filter(d => d.id === fundId)[0] : funds[0];
      getBTCBalanceList({ fund_id: fund.id, page }).then(({ data }) => {
        setState({ loading: false, funds, fund, data });
      });
    });
  }, [fundId, page]);

  function handleFundChange(value) {
    navigate(`/balance?fund_id=${value}`);
  }

  function handlePageChange(page) {
    navigate(`/balance?fund_id=${fund.id}&page=${page}`);
  }

  if (loading) {
    return <BTCLayout loading />;
  }

  return (
    <BTCLayout>
      <Global
        styles={css`table {
        th, td {
          text-align: right !important;
        }`}
      />

      <Card
        title={
          <Select value={fund.id} onChange={handleFundChange}>
            {funds.map(d => (
              <option key={d.id} value={d.id}>
                {d.display}
              </option>
            ))}
          </Select>
        }
        extra={
          <Button
            appearance="primary"
            component={Link}
            to={`/balance/new?fund_id=${fund.id}`}
          >
            {t('btcbalance.new')}
          </Button>
        }
      >
        <BTCBalanceChart fundId={fund.id} />
      </Card>

      <Card>
        <Table
          getRowKey={({ id }) => id}
          columns={[
            { key: 'date', title: t('btcbalance.date') },
            {
              key: 'equity',
              title: t('btcbalance.equity'),
              render: ({ equity }) => equity.toFixed(4)
            },
            {
              key: 'units',
              title: t('btcbalance.units'),
              render: ({ units }) => units.toFixed(4)
            },
            {
              key: 'nav',
              title: t('btcbalance.nav'),
              render: ({ nav }) => nav.toFixed(4)
            },
            {
              key: 'acc_nav',
              title: t('btcbalance.acc_nav'),
              render: ({ acc_nav }) => acc_nav.toFixed(4)
            },
            {
              key: 'annualized_returns',
              title: t('btcbalance.annualized_returns'),
              render: ({ annualized_returns }) =>
                `${(annualized_returns * 100).toFixed(2)}%`
            },
            {
              key: 'action',
              render: ({ id }) => (
                <div className="text-right">
                  <Link to={`/balance/${id}`}>{t('view')}</Link>
                  &nbsp;|&nbsp;
                  <Link to={`/balance/${id}/edit`}>{t('edit')}</Link>
                </div>
              )
            }
          ]}
          rows={data.map(d => {
            d.date = formatDate(d.date);
            return d;
          })}
        />
      </Card>

      <Pagination current={page} onChange={handlePageChange} />
    </BTCLayout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || '',
    page: params.page ? parseInt(params.page, 10) : 1
  };
};

export default connect(mapStateToProps)(BTCBalanceListPage);
