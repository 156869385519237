import styled from '@emotion/styled';

export const Action = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;

  button:nth-of-type(1) {
    margin-right: auto;
  }
`;
