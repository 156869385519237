import React, { useState, useEffect } from 'react';
import { BondLayout, Card, Link, Table, Select, Pagination } from 'components';
import { getBondTaskList, getBondTaskStatuses } from 'api';
import { t, getParams, navigate, canEditBondTask, useCurrentUser } from 'utils';

const BondTaskListPage = () => {
  const [state, setState] = useState({ loading: true, data: [] });
  const [statuses, setStatuses] = useState([]);
  const { loading, data } = state;
  const { user } = useCurrentUser();
  const params = getParams();
  const page = params.page ? parseInt(params.page, 10) : 1;

  useEffect(() => {
    getBondTaskStatuses().then(({ data }) => {
      setStatuses(data);
      getBondTaskList({ status: params.status, page }).then(({ data }) => {
        setState({ loading: false, data });
      });
    });
  }, [params.status, page]);

  function handleStatusChange(status) {
    navigate(`/bond/tasks?status=${status}`);
  }

  function handlePageChange(page) {
    navigate(`/bond/tasks?status=${status}&page=${page}`);
  }

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card
        title={
          <Select onChange={handleStatusChange}>
            <option value="">全部</option>
            {statuses.map(status => (
              <option key={status.id} value={status.id}>
                {status.display}
              </option>
            ))}
          </Select>
        }
        extra={<Link to="/bond/tasks/new">{t('bondtask.new')}</Link>}
      >
        <Table
          getRowKey={({ id }) => id}
          rows={data}
          columns={[
            { key: 'file_no', title: t('bondtask.file_no') },
            { key: 'securities_code', title: t('bondtask.securities_code') },
            { key: 'securities_name', title: t('bondtask.securities_name') },
            { key: 'trade_side', title: t('bondtask.trade_side') },
            {
              key: 'expectation_price',
              title: t('bondtask.expectation_price')
            },
            {
              key: 'expectation_amount',
              title: t('bondtask.expectation_amount')
            },
            {
              key: 'current_vertex_display',
              title: t('bondtask.current_vertex_display')
            },
            {
              key: 'fund.display',
              title: t('bondtask.fund.display')
            },
            {
              key: 'created.name',
              title: t('bondtask.created.name')
            },
            {
              key: 'action',
              render: task => (
                <div className="text-right">
                  {canEditBondTask(user, task) ? (
                    <Link to={`/bond/tasks/${task.id}/edit`}>{t('edit')}</Link>
                  ) : null}
                  &nbsp;|&nbsp;
                  <Link to={`/bond/tasks/${task.id}`}>{t('view')}</Link>
                </div>
              )
            }
          ]}
        />
      </Card>
      <Pagination current={page} onChange={handlePageChange} />
    </BondLayout>
  );
};

export default BondTaskListPage;
