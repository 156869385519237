import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  Row,
  Col,
  GatewayTypeSelect
} from 'components';
import { t } from 'utils';

const GatewayForm = ({ values, onSubmit }) => {
  return (
    <Form initialValues={values} onSubmit={onSubmit}>
      <Row>
        <Col>
          <FormGroup
            label={t('gateway.type')}
            name="type"
            component={GatewayTypeSelect}
          />
        </Col>
        <Col>
          <FormGroup label={t('gateway.name')} name="name" />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup label={t('gateway.pub_url')} name="pub_url" />
        </Col>
        <Col>
          <FormGroup label={t('gateway.req_url')} name="req_url" />
        </Col>
      </Row>
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default GatewayForm;
