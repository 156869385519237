import React from 'react';
import { t } from 'utils';
import { Select } from 'components';

const ACCOUNT_TYPES = ['cats', 'token', 'bitmex', 'okex'];

const AccountTypeSelect = props => {
  return (
    <Select {...props} style={{ width: '100%' }}>
      <option value="" />

      {ACCOUNT_TYPES.map(x => (
        <option key={x} value={x}>
          {t(`constants.account.type.${x}`)}
        </option>
      ))}
    </Select>
  );
};

export default AccountTypeSelect;
