import React from 'react';
import ReactSelect from 'react-select';
import { USER_SPACES } from 'constants';

const UserSpaceSelect = ({ name, value, onChange }) => {
  return (
    <ReactSelect
      isMulti
      value={value
        .map(v => {
          const option = USER_SPACES.filter(d => d === v)[0];
          if (option) {
            return { label: option, value: option };
          }
          return null;
        })
        .filter(d => d)}
      onChange={value =>
        onChange(
          value.map(d => d.value),
          name
        )
      }
      style={{ width: '100%' }}
      options={USER_SPACES.map(d => ({
        label: d,
        value: d
      }))}
    />
  );
};

export default UserSpaceSelect;
