import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  NumberInput,
  GatewaySelect,
  ContractTypeSelect
} from 'components';
import { t } from 'utils';

const ContractForm = ({ contract, onSubmit }) => {
  return (
    <Form initialValues={contract} onSubmit={onSubmit}>
      <FormGroup
        label={t('contract.gateway_id')}
        name="gateway_id"
        component={GatewaySelect}
      />
      <FormGroup label={t('contract.name')} name="name" />
      <FormGroup
        label={t('contract.type')}
        name="type"
        component={ContractTypeSelect}
      />
      <FormGroup label={t('contract.display')} name="display" />
      <FormGroup label={t('contract.exchange')} name="exchange" />
      <FormGroup label={t('contract.symbol')} name="symbol" />
      <FormGroup label={t('contract.prompt')} name="prompt" />
      <FormGroup
        label={t('contract.unit_amount')}
        name="unit_amount"
        component={NumberInput}
      />
      <FormGroup
        label={t('contract.maker_brokerage_rate')}
        name="maker_brokerage_rate"
        component={NumberInput}
      />
      <FormGroup
        label={t('contract.taker_brokerage_rate')}
        name="taker_brokerage_rate"
        component={NumberInput}
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default ContractForm;
