import React, { useState } from 'react';
import { Button } from 'components';
import { getBondBalanceList } from 'api';
import { t, formatDate } from 'utils';

let link = null;

const BondBalanceDownloadButton = ({ fundId, ...props }) => {
  const [loading, setLoading] = useState(false);

  function handleClick(e) {
    e.preventDefault();

    setLoading(true);
    getBondBalanceList({ fund_id: fundId }).then(({ data }) => {
      const csv = `${COLUMNS}\n${format(data)}`;
      const href = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csv)}`;

      init();
      link.setAttribute('href', href);
      link.setAttribute('download', 'bond.csv');

      link.click();
      setLoading(false);
    });
  }

  return <Button {...props} loading={loading} onClick={handleClick} />;
};

function init() {
  if (link) return link;
  link = document.createElement('a');
  document.body.appendChild(link);

  return link;
}

function format(data) {
  return data
    .map(d =>
      [formatDate(d.date), d.share, d.equity, d.nav, d.acc_nav].join(',')
    )
    .join('\r\n');
}

const COLUMNS = [
  t('bondbalance.date'),
  t('bondbalance.share'),
  t('bondbalance.equity'),
  t('bondbalance.nav'),
  t('bondbalance.acc_nav')
].join(',');

export default BondBalanceDownloadButton;
