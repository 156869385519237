import React from 'react';
import { AdminLayout, StrategyList } from 'components';

const StrategyListPage = () => {
  return (
    <AdminLayout>
      <StrategyList />
    </AdminLayout>
  );
};

export default StrategyListPage;
