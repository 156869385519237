import React from 'react';
import { Layout, Card, ContractForm } from 'components';
import { createContract } from 'api';
import { t } from 'utils';

const ContractNewPage = ({ navigate }) => {
  return (
    <Layout title={t('contract.new')}>
      <Card title={t('contract.new')}>
        <ContractForm
          contract={{
            name: '',
            display: '',
            exchange: '',
            symbol: '',
            prompt: '',
            status: 0
          }}
          onSubmit={(values, { setSubmitting }) =>
            createContract(values).then(({ id }) => {
              setSubmitting(false);
              navigate(`/contracts/${id}`);
            })
          }
        />
      </Card>
    </Layout>
  );
};

export default ContractNewPage;
