module.exports = {
	"all": "全部",
	"previous_step": "上一步",
	"next_step": "下一步",
	"confirm": "确认",
	"delete": "删除",
	"submit": "提交",
	"not_empty": "不能为空",
	"type": "类型",
	"param": "参数",
	"history": "历史记录",
	"finish": "停止",
	"pause": "暂停",
	"start": "开始",
	"edit": "编辑",
	"view": "查看",
	"logout": "登出",
	"save": "保存",
	"update": "更新",
	"back": "返回",
	"menu": {
		"hsce": {
			"index": "套利监控",
			"hhi_lz": "罗臻",
			"hhi_hf": "海方",
			"hhi_jh": "景汇",
			"balance": "净值记录"
		},
		"btc": {
			"index": "套利监控",
			"cutter": "自营盘",
			"pts": "Points",
			"shenyu": "神鱼",
			"balance": "净值记录",
			"fund_units": "份额调整",
			"dividends": "分红记录"
		},
		"admin": {
			"index": "管理",
			"users": "用户",
			"monitors": "监控",
			"logs": "日志"
		},
		"bond": {
			"fund": "基金任务",
			"balance": "净值记录"
		},
		"space": {
			"hsce": "国企指数",
			"btc": "比特币",
			"admin": "管理后台",
			"bond": "债券",
			"risk": "风险管理"
		}
	},
	"login": {
		"username": "邮箱/手机号",
		"password": "密码",
		"submit": "登录"
	},
	"hsce": {
		"stats": {
			"exrate": "实时汇率"
		},
		"base_account": "内盘账户",
		"counter_account": "外盘账户",
		"local_orders": "本地挂单",
		"remote_orders": "交易所挂单",
		"balance": {
			"total": "总资产",
			"available": "可用"
		},
		"position": {
			"amount": "持仓数量",
			"available": "可用"
		},
		"order": {
			"create_time": "委托时间",
			"update_time": "更新时间",
			"symbol": "品种",
			"volume": "挂单量",
			"price": "委托价格",
			"filled_volume": "成交量",
			"avg_price": "成交均价",
			"type": "类型",
			"side": "方向",
			"status": "状态"
		}
	},
	"btc": {
		"bitmex_account": "Bitmex 账户",
		"okex_account": "OKEx 账户",
		"local_orders": "本地挂单",
		"remote_orders": "Bitmex交易所挂单",
		"balance": {
			"bitmex": {
				"margin": "保证金",
				"margin_leverage": "保证金杠杆率",
				"money_type": "币种"
			},
			"okex": {
				"margin": "保证金",
				"margin_leverage": "保证金率",
				"money_type": "币种"
			}
		},
		"position": {
			"symbol": "合约",
			"amount": "持仓数量",
			"available": "可平仓",
			"trade_side": "方向",
			"avg_price": "平均价格"
		},
		"order": {
			"create_time": "委托时间",
			"update_time": "更新时间",
			"symbol": "品种",
			"volume": "挂单量",
			"price": "委托价格",
			"filled_volume": "成交量",
			"avg_price": "成交均价",
			"type": "类型",
			"side": "方向",
			"status": "状态"
		}
	},
	"task": {
		"status": "交易状态",
		"position_status": "对冲头寸交易状态",
		"grid_status": "网格交易状态",
		"fund_id": "任务基金",
		"strategy_id": "任务策略",
		"list": "任务列表",
		"new": "新建任务",
		"none": "暂无任务",
		"pause": "暂停任务",
		"finish": "结束任务",
		"resume": "启动任务",
		"steps": [
			{
				"title": "类型",
				"description": "选择策略类型"
			},
			{
				"title": "参数",
				"description": "提供任务参数"
			},
			{
				"title": "确认",
				"description": "确认提交任务"
			}
		],
		"title": {
			"grid": "网格属性",
			"vars": "任务参数",
			"configs": "交易设置"
		},
		"accounts": {
			"base": "被动合约账户组",
			"counter": "主动合约账户组"
		},
		"configs": {
			"btc": {
				"max_volume_per_trade": "被动合约单次最大交易量",
				"base_volume_percentage": "被动合约买卖一计算占比",
				"counter_volume_percentage": "主动合约可交易量计算占比",
				"taker_price_threshold": "主动交易被动合约的价格阈值",
				"base_maker_price_offset": "被动合约挂单价格与买卖一的价差",
				"counter_price_spread": "主动合约用于计算的最大价差",
				"counter_market_price_offset": "主动合约模拟市价单价差"
			},
			"hsce": {
				"max_volume_per_trade": "被动合约单次最大交易量",
				"base_volume_percentage": "被动合约买卖一计算占比",
				"counter_volume_percentage": "主动合约可交易量计算占比",
				"counter_market_price_offset": "主动合约模拟市价单价差"
			}
		},
		"vars": {
			"btc": {
				"base": "被动合约",
				"counter": "主动合约",
				"baseline_diff": "理论基差",
				"allowed_direction": "允许交易方向",
				"grids": "网格",
				"last_direction": "上次交易方向",
				"last_trading_line": "上次所在网格线的基差",
				"insufficient_volume": "未满足量",
				"counter_holdings": "主动合约持仓量",
				"base_holdings": "被动合约持仓量",
				"unhedged_volume": "被动合约未对冲量",
				"highest_trading_line": "允许交易最大基差",
				"lowest_trading_line": "允许交易最小基差",
				"task_volume": "交易量",
				"trading_type": "交易方向",
				"line_count": "网格线数量",
				"line_width": "网格宽度",
				"counter_volume_unit": "单线主动合约交易量",
				"base_contract_holdings": "被动合约持仓量"
			},
			"hsce": {
				"position_transit": {
					"base": "H股指数期货（现持仓）",
					"counter": "H股指数期货（新持仓）",
					"baseline_diff": "换月基差（近月-远月）",
					"task_volume": "交易量（多单为负）"
				},
				"base": "H股ETF",
				"counter": "H股指数期货",
				"baseline_diff": "理论基差",
				"allowed_direction": "交易方向",
				"exchange_rate_today": "当日汇率(港币/人民币)",
				"exchange_rate_yesterday": "昨日汇率(港币/人民币)",
				"net_worth_yesterday": "昨日ETF净值",
				"index_close_yesterday": "昨日国企指数收盘点位",
				"last_trading_line": "最近一次交易穿越网格线",
				"last_direction": "最近一次交易方向",
				"base_holdings": "ETF持仓量(股)",
				"unhedged_base_volume": "ETF未对冲量(股)",
				"insufficient_volume": "线上未满足量(HHI手数)",
				"counter_holdings": "HHI持仓(空单/手)",
				"grids": "网格线",
				"highest_trading_line": "允许交易最大基差",
				"lowest_trading_line": "允许交易最小基差",
				"task_volume": "交易量",
				"filled_volume": "任务完成量"
			}
		}
	},
	"user": {
		"new": "新增用户",
		"edit": "编辑用户",
		"list": "用户列表",
		"name": "姓名",
		"role": "角色",
		"email": "邮箱地址",
		"password": "密码",
		"mobile": "手机号码",
		"logs": "用户操作记录",
		"spaces": "Spaces"
	},
	"fund": {
		"list": "基金管理",
		"new": "新建基金",
		"edit": "编辑基金",
		"name": "名字",
		"task": "任务",
		"display": "显示名称",
		"manager_ids": "基金经理",
		"trader_ids": "基金交易员",
		"api": "API",
		"established_at": "成立日期",
		"closed": "关闭"
	},
	"contract": {
		"new": "新建合约",
		"edit": "编辑合约",
		"list": "合约列表",
		"name": "内部代号",
		"display": "合约显示名称",
		"type": "内部类型",
		"exchange": "交易所",
		"symbol": "交易所代号",
		"prompt": "合约期限",
		"gateway_id": "网关",
		"unit_amount": "Unit Amount",
		"maker_brokerage_rate": "被动成交手续费",
		"taker_brokerage_rate": "主动成交手续费"
	},
	"nav": {
		"fund_id": "基金",
		"date": "记录时间",
		"total": "原始份额",
		"current_value": "当前资产",
		"current_net_value": "当前净值",
		"acc_value": "累计净值",
		"profits": "盈亏类型"
	},
	"profit": {
		"value": "盈亏数值",
		"ratio": "盈亏占比"
	},
	"monitor": {
		"new": "新建监控",
		"list": "监控列表",
		"edit": "编辑",
		"delete": "删除",
		"status": "状态",
		"template": "模版",
		"edit-page": "编辑监控",
		"id": "监控ID",
		"vars": {
			"exchange": "交易所",
			"symbol": "币对",
			"prompt": "期货合约"
		}
	},
	"profit_type": {
		"fund_id": "基金",
		"name": "名字"
	},
	"strategy": {
		"new": "新建策略",
		"edit": "编辑策略",
		"list": "策略列表",
		"type": "策略类型",
		"name": "策略名称",
		"display": "显示名称",
		"fund_ids": "基金"
	},
	"account": {
		"new": "新建账户",
		"edit": "编辑账户",
		"list": "账户列表",
		"type": "类型",
		"name": "名称",
		"fund_id": "基金",
		"setting": {
			"acctType": "acctType",
			"acctName": "acctName",
			"pub_url": "Pub URL",
			"req_url": "Req URL",
			"api_exchange": "交易所",
			"api_key": "Key",
			"api_secret": "Secret"
		}
	},
	"gateway": {
		"new": "新建网关",
		"edit": "编辑网关",
		"list": "网关列表",
		"type": "类型",
		"name": "名称",
		"pub_url": "Pub URL",
		"req_url": "Req URL"
	},
	"grid": {
		"new": "新建网格",
		"edit": "编辑网格",
		"list": "网格列表",
		"lines": "网格线",
		"strategy_id": "策略"
	},
	"hscebalance": {
		"new": "添加净值记录",
		"edit": "编辑净值记录",
		"list": "列表",
		"date": "日期",
		"note": "今日行情与交易描述",
		"units": "产品份额",
		"origin": "投入资产",
		"total": "当日净资产",
		"acc_net": "累计收益",
		"nav": "单位净值",
		"acc_nav": "累计净值",
		"ar": "年化收益率",
		"details": {
			"others": "非经常性损益/港币（O）",
			"dividends": "累计单位分红",
			"div_value": "已分红金额（人民币）",
			"acc_corr": "累计净值修正项",
			"pure_nav": "单位净值（不含O）",
			"pure_acc_nav": "累计净值（不含O）",
			"pure_acc_corr": "累计净值修正项（不含O）",
			"fund_total": "产品汇总（人民币）",
			"np_value": "内盘（人民币）",
			"wp_value": "外盘（美元）",
			"np_origin": "内盘（人民币）",
			"wp_origin": "外盘（港币）",
			"np_units": "内盘（人民币）",
			"wp_units": "外盘（港币）",
			"usdcnh": "当日美元/人民币中间价",
			"hkdcnh": "当日港币/人民币中间价",
			"etf_holdings": "内盘仓位",
			"etf_price": "ETF收盘价格",
			"etf_value": "内盘市值（人民币）",
			"hhi_holdings": "外盘仓位",
			"hhi_value": "外盘市值（人民币）",
			"hhi_price": "HHI收盘点位",
			"origin": "产品汇总（人民币）",
			"units": "产品汇总（人民币）",
			"total": "产品汇总（人民币）"
		}
	},
	"btcbalance": {
		"new": "添加净值记录",
		"edit": "编辑净值记录",
		"list": "列表",
		"date": "日期",
		"nav": "产品净值",
		"acc_nav": "累计净值",
		"units": "产品份额",
		"equity": "账户总权益",
		"annualized_returns": "年化收益率"
	},
	"btcdividend": {
		"new": "新建分红",
		"list": "分红列表",
		"edit": "编辑",
		"date": "分红日期",
		"fund_id": "基金",
		"dividends": "单位分红"
	},
	"btcfundunit": {
		"new": "新建",
		"list": "列表",
		"edit": "编辑",
		"date": "调整日期",
		"units": "调整份额",
		"reason": "调整原因"
	},
	"bondbalance": {
		"new": "新建",
		"edit": "编辑",
		"acc_nav": "累计净值",
		"nav": "单位净值",
		"date": "日期",
		"equity": "当日净资产",
		"share": "份额",
		"annualized_returns": "年化收益率"
	},
	"bondtask": {
		"list": "列表",
		"new": "新建",
		"edit": "编辑",
		"file_no": "编号",
		"securities_code": "证券代码",
		"securities_name": "证券名称",
		"current_vertex_display": "当前状态",
		"fund_id": "基金产品",
		"fund": {
			"display": "基金产品"
		},
		"created": {
			"name": "创建人"
		},
		"trade_side": "交易方向",
		"expectation_price": "拟成交价格(元)",
		"expectation_amount": "拟成交金额(元)",
		"transaction_price": "成交价格(元)",
		"transaction_amount": "成交金额(元)",
		"remarks": "备注",
		"auditors": "可审批人"
	},
	"constants": {
		"bondtask": {
			"trade_side": {
				"buy": "买入",
				"sell": "卖出"
			},
			"status": {
				"v0": "待提交",
				"v1": "交易后提交审批",
				"v2": "交易前审批通过",
				"v3": "交易后提交审批",
				"v4": "交易后审批通过",
				"ve": "交易终止",
				"vd": "已删除",
				"v0-v0": "编辑",
				"v0-v1": "提交审批(交易前)",
				"v1-v0": "驳回(交易前)",
				"v1-ve": "终止交易",
				"v1-v2": "审批通过(交易前)",
				"v2-v3": "提交审批(交易后)",
				"v2-ve": "终止交易",
				"v3-v4": "审批通过(交易后)",
				"v3-v2": "驳回(交易后)",
				"v0-vd": "删除",
				"ve-vd": "删除"
			}
		},
		"user": {
			"role": {
				"admin": "管理员",
				"partner": "合伙人",
				"manager": "基金经理",
				"trader": "交易员",
				"dev": "程序开发",
				"trader_intern": "交易实习",
				"risk": "风险管理"
			}
		},
		"contract": {
			"type": {
				"all": "全部",
				"btc": "BTC",
				"hsce": "HSCE"
			}
		},
		"actions": {
			"create_task": "创建任务",
			"update_task": "更新任务",
			"update_task_status": "更新任务状态",
			"update_task_status_paused": "暂停任务",
			"update_task_status_confirmed": "确认任务",
			"update_task_status_finished": "停止任务",
			"update_task_status_archived": "删除任务",
			"create_fund": "创建基金",
			"update_fund": "更新基金",
			"create_contract": "创建合约",
			"update_contract": "更新合约",
			"create_nav": "添加净值记录",
			"update_nav": "编辑净值记录",
			"delete_nav": "删除净值记录",
			"add_profit": "添加盈亏项",
			"update_profit": "编辑盈亏项",
			"delete_profit": "删除盈亏项",
			"edit_monitor": "编辑监控",
			"create_monitor": "创建监控",
			"delete_monitor": "删除监控",
			"create_strategy": "创建策略",
			"update_strategy": "更新策略",
			"create_account": "创建账号",
			"update_account": "更新账号",
			"create_gateway": "创建网关",
			"update_gateway": "更新网关",
			"create_grid": "创建网格",
			"update_grid": "更新网格",
			"create_user": "创建用户",
			"update_user": "更新用户",
			"create_hsce_balance": "创建净值记录",
			"update_hsce_balance": "更新净值记录",
			"create_bond_task": "创建债券任务",
			"update_bond_task": "更新债券任务",
			"update_bond_task_status": "更新债券任务状态",
			"create_bond_balance": "创建债券净值",
			"update_bond_balance": "更新债券净值"
		},
		"task_types": {
			"position": "对冲头寸",
			"grid": "网格交易"
		},
		"task_order": {
			"trade_side": "订单类型",
			"price": "订单价格",
			"submitted_vol": "挂单数量",
			"committed_vol": "成交数量",
			"status": "订单状态"
		},
		"order_trade_side": {
			"open_long": "开多",
			"open_short": "开空",
			"settle_long": "平空",
			"settle_short": "平多"
		},
		"order_status": {
			"pending_new": "待提交",
			"new": "已提交",
			"partially_filled": "部分成交",
			"filled": "已成交",
			"pending_cancel": "撤单中",
			"partially_canceled": "部分撤单",
			"canceled": "已撤单",
			"replaced": "已替换",
			"stopped": "已停止",
			"rejected": "被拒绝",
			"expired": "已过期"
		},
		"monitor_status": {
			"disabled": "已停止",
			"enabled": "运行中"
		},
		"monitor_template": {
			"future_price_threshold": "期货价格报警",
			"future_difference_threshold": "期货价差报警",
			"future_change_threshold": "期货波动报警"
		},
		"contracts": {
			"BITMEX_XBTUSD": "BitMEX永续合约",
			"BITMEX_XBTH19": "BitMEX季度合约",
			"OKEX_BTCUSD_QUARTERLY": "OKEX季度合约",
			"OKEX_BTCUSD_WEEKLY": "OKEX当周合约",
			"OKEX_BTCUSD_BIWEEKLY": "OKEX次周合约",
			"BITMEX_XBTM19": "BitMEX次季合约",
			"bitmex_XBTUSD_perpetual": "BitMEX永续合约",
			"bitmex_XBTH19_quarterly": "BitMEX季度合约",
			"bitmex_XBTM19_quarterly": "BitMEX次季合约",
			"okex_future_BTCUSD_biweekly": "OKEX次周合约",
			"okex_future_BTCUSD_quarterly": "OKEX季度合约",
			"okex_future_BTCUSD_weekly": "OKEX当周合约"
		},
		"account": {
			"type": {
				"cats": "Cats",
				"token": "Token",
				"okex": "Okex",
				"bitmex": "Bitmex"
			}
		},
		"task": {
			"hsce": {
				"maker": "被动成交",
				"taker": "主动成交",
				"allowed_direction": {
					"BASE_SELL_COUNTER_BUY": "做空ETF, 做多HHI (-1)",
					"BASE_BUY_COUNTER_SELL": "做多ETF, 做空HHI (1)",
					"BOTH": "双向交易"
				},
				"last_direction": {
					"BASE_SELL_COUNTER_BUY": "做空ETF, 做多HHI (-1)",
					"BASE_BUY_COUNTER_SELL": "做多ETF, 做空HHI (1)"
				}
			},
			"btc": {
				"trading_type": {
					"BASE_OPEN_SHORT_COUNTER_OPEN_LONG": "被动合约开空主动合约开多",
					"BASE_OPEN_LONG_COUNTER_OPEN_SHORT": "被动合约开多主动合约开空",
					"BASE_SETTLE_LONG_COUNTER_SETTLE_SHORT": "被动合约平多主动合约平空",
					"BASE_SETTLE_SHORT_COUNTER_SETTLE_LONG": "被动合约平空主动合约平多"
				},
				"allowed_direction": {
					"BOTH": "双向交易",
					"NONE": "限制交易",
					"BASE_SELL_COUNTER_BUY": "做空被动合约，做多主动合约",
					"BASE_BUY_COUNTER_SELL": "做多被动合约，做空主动合约"
				},
				"last_direction": {
					"BASE_SELL_COUNTER_BUY": "被动合约开空",
					"BASE_BUY_COUNTER_SELL": "被动合约开多"
				}
			},
			"status": {
				"pending": "待审核",
				"confirmed": "运行中",
				"finished": "已结束",
				"archived": "已删除",
				"paused": "已暂停"
			},
			"direction": {
				"btc": {
					"BASE_SELL_COUNTER_BUY": "做空被动合约，做多主动合约",
					"BASE_BUY_COUNTER_SELL": "做多被动合约，做空主动合约",
					"BOTH": "双向交易",
					"NONE": "限制交易"
				},
				"hsce": {
					"BASE_SELL_COUNTER_BUY": "做空ETF, 做多HHI (-1)",
					"BASE_BUY_COUNTER_SELL": "做多ETF, 做空HHI (1)",
					"BOTH": "双向交易"
				}
			}
		},
		"order": {
			"type": {
				"LIMIT": "限价",
				"MARKET": "市价"
			},
			"side": {
				"BUY": "买",
				"SELL": "卖",
				"OPEN_LONG": "开多",
				"SETTLE_LONG": "平多",
				"OPEN_SHORT": "开空",
				"SETTLE_SHORT": "平空"
			},
			"trade_side": {
				"OPEN_LONG": "多仓",
				"OPEN_SHORT": "空仓"
			},
			"status": {
				"FILLED": "全成",
				"PARTIALLY_CANCELED": "部撤",
				"TRADING": "已报",
				"PARTIALLY_FILLED": "部成",
				"REJECTED": "拒绝",
				"PENDING_CREATE": "待报",
				"PENDING_CANCEL": "待撤",
				"CANCELED": "全撤"
			}
		}
	}
}