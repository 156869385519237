import { request } from 'utils';

export const getBondFundList = params => request.get('/bond/funds', params);
export const getBondBalanceList = params =>
  request.get('/bond/balance', params);
export const getBondBalance = id => request.get(`/bond/balance/${id}`);
export const createBondBalance = params =>
  request.post('/bond/balance', params);
export const updateBondBalance = (id, params) =>
  request.put(`/bond/balance/${id}`, params);

export const getBondTaskList = params => request.get('/bond/tasks', params);
export const getBondTaskStatuses = params =>
  request.get('/bond/tasks/statuses', params);
export const getBondTask = (id, params) =>
  request.get(`/bond/tasks/${id}`, params);
export const createBondTask = params => request.post('/bond/tasks', params);
export const updateBondTask = (id, params) =>
  request.put(`/bond/tasks/${id}`, params);
export const updateBondTaskStatus = (id, params) =>
  request.put(`/bond/tasks/${id}/status`, params);
export const getBondTaskLogs = (id, params) =>
  request.get(`/bond/tasks/${id}/logs`, params);

export const getBondSecurities = params =>
  request.get('/bond/securities', params);
