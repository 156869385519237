import React, { useState, useEffect } from 'react';
import { Layout, Card, Pair, Link } from 'components';
import { getGrid } from 'api';
import { t } from 'utils';

const GridPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getGrid(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <Layout loading />;

  return (
    <Layout title={data.lines}>
      <Card
        title={data.lines}
        extra={<Link to={`/grids/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('grid.lines')} value={data.lines} />
      </Card>
    </Layout>
  );
};

export default GridPage;
