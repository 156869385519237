import styled from '@emotion/styled';
import React from 'react';
import {
  Row,
  Col,
  TextInput,
  DateInput,
  NumberInput,
  Button,
  Form,
  FormGroup,
  FormSubmit
} from 'components';
import { calculateHsceBalance } from 'api';
import { t, formatFixed } from 'utils';

const HSCEBalanceForm = ({ balance, onSubmit }) => {
  function handleSubmit(values, { setSubmitting, setValues, action }) {
    if (action === 'calculate') {
      calculateHsceBalance(values)
        .then(({ data }) => {
          setValues({
            ...values,
            total: formatFixed(data.total, 2),
            origin: formatFixed(data.origin, 2),
            units: formatFixed(data.units, 2),
            nav: formatFixed(data.nav, 4),
            acc_nav: formatFixed(data.acc_nav, 4),
            acc_net: formatFixed(data.acc_net, 2),
            ar: formatFixed(data.ar, 4),
            details: {
              ...values.details,
              etf_value: formatFixed(data.details.etf_value, 2),
              hhi_value: formatFixed(data.details.hhi_value, 2),
              pure_nav: formatFixed(data.details.pure_nav, 4),
              pure_acc_nav: formatFixed(data.details.pure_acc_nav, 4)
            }
          });

          setSubmitting(false);
        })
        .catch(err => {
          console.error(err);
          setSubmitting(false);
        });
    } else if (action === 'submit') {
      onSubmit(values, { setSubmitting });
    }
  }

  return (
    <Form initialValues={balance} onSubmit={handleSubmit}>
      <Row>
        <Group
          label={t('hscebalance.date')}
          name="date"
          component={DateInput}
        />
        <Group label={t('hscebalance.details.usdcnh')} name="details.usdcnh" />
        <Group label={t('hscebalance.details.hkdcnh')} name="details.hkdcnh" />
      </Row>

      <Title>投入资产</Title>

      <Row>
        <Group
          label={t('hscebalance.details.np_origin')}
          name="details.np_origin"
        />
        <Group
          label={t('hscebalance.details.wp_origin')}
          name="details.wp_origin"
        />
        <Group label={t('hscebalance.details.origin')} name="origin" />
      </Row>

      <Title>产品份额</Title>

      <Row>
        <Group
          label={t('hscebalance.details.np_units')}
          name="details.np_units"
        />
        <Group
          label={t('hscebalance.details.wp_units')}
          name="details.wp_units"
        />
        <Group label={t('hscebalance.details.units')} name="units" />
      </Row>

      <Title>当日资产</Title>

      <Row>
        <Group
          label={t('hscebalance.details.np_value')}
          name="details.np_value"
        />
        <Group
          label={t('hscebalance.details.wp_value')}
          name="details.wp_value"
        />
        <Group label={t('hscebalance.details.total')} name="total" />
      </Row>

      <Title>净值计算</Title>

      <Row>
        <Group label={t('hscebalance.nav')} name="nav" />
        <Group
          label={t('hscebalance.details.dividends')}
          name="details.dividends"
        />
        <Group
          label={t('hscebalance.details.acc_corr')}
          name="details.acc_corr"
        />
        <Group label={t('hscebalance.acc_nav')} name="acc_nav" />
        <Group label={t('hscebalance.ar')} name="ar" />
      </Row>

      <Row>
        <Group label={t('hscebalance.details.others')} name="details.others" />
        <Group
          label={t('hscebalance.details.pure_nav')}
          name="details.pure_nav"
        />
        <Group
          label={t('hscebalance.details.pure_acc_corr')}
          name="details.pure_acc_corr"
        />
        <Group
          label={t('hscebalance.details.pure_acc_nav')}
          name="details.pure_acc_nav"
        />
      </Row>

      <Row>
        <Group label={t('hscebalance.acc_net')} name="acc_net" />
        <Group
          label={t('hscebalance.details.div_value')}
          name="details.div_value"
        />
      </Row>

      <Title>当日收盘仓位</Title>

      <Row>
        <Group
          label={t('hscebalance.details.etf_holdings')}
          name="details.etf_holdings"
        />
        <Group
          label={t('hscebalance.details.etf_price')}
          name="details.etf_price"
        />
        <Group
          label={t('hscebalance.details.etf_value')}
          name="details.etf_value"
        />
      </Row>

      <Row>
        <Group
          label={t('hscebalance.details.hhi_holdings')}
          name="details.hhi_holdings"
        />
        <Group
          label={t('hscebalance.details.hhi_price')}
          name="details.hhi_price"
        />
        <Group
          label={t('hscebalance.details.hhi_value')}
          name="details.hhi_value"
        />
      </Row>

      <FormGroup
        label={t('hscebalance.note')}
        name="note"
        rows={4}
        multiline
        component={TextInput}
      />

      <Submit>
        <FormSubmit action="calculate" component={Button}>
          更新计算
        </FormSubmit>
        <FormSubmit>{t('save')}</FormSubmit>
      </Submit>
    </Form>
  );
};

const Group = props => {
  return (
    <Col>
      <FormGroup {...props} />
    </Col>
  );
};

Group.defaultProps = {
  component: NumberInput
};

const Title = styled.div`
  font-weight: bold;
  font-size: 16px;
  padding: 4px 0;
  margin-bottom: 25px;
  color: #333;
  border-left: 4px solid #5e72e4;
  padding-left: 10px;
`;

const Submit = styled.div`
  text-align: right;
  button {
    margin-left: 12px;
  }
`;

export default HSCEBalanceForm;
