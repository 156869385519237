import React, { useState, useEffect } from 'react';
import { Layout, Link, Card, Pair } from 'components';
import { getAccount } from 'api';
import { t } from 'utils';

const AccountPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getAccount(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <Card
        title={data.name}
        extra={<Link to={`/accounts/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('account.type')} value={data.type} />
        <Pair label={t('account.name')} value={data.name} />
        <Pair label={t('account.fund_id')} value={data.fund_id} />
        <Pair
          label={t('account.setting.acctType')}
          value={data.setting.acctType}
        />
        <Pair
          label={t('account.setting.acctName')}
          value={data.setting.acctName}
        />
        <Pair
          label={t('account.setting.pub_url')}
          value={data.setting.pub_url}
        />
        <Pair
          label={t('account.setting.req_url')}
          value={data.setting.req_url}
        />
        <Pair
          label={t('account.setting.api_key')}
          value={data.setting.api_key}
        />
        <Pair
          label={t('account.setting.api_secret')}
          value={data.setting.api_secret}
        />
      </Card>
    </Layout>
  );
};

export default AccountPage;
