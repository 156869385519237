import moment from 'moment';
import React, { Component } from 'react';
import { Layout, Card } from 'components';
import { getTaskLogs } from 'api';
import { t, taskDiff } from 'utils';

const Diff = ({ left, right }) => {
  const diff = taskDiff(left, right);

  return (
    <table>
      <tbody>
        {Object.keys(diff).map((k, i) => (
          <tr key={i}>
            <td style={{ paddingTop: 0, border: 0 }}>{t(k)}</td>
            <td style={{ paddingTop: 0, textAlign: 'center', border: 0 }}>
              {diff[k][0]}
            </td>
            <td style={{ paddingTop: 0, textAlign: 'center', border: 0 }}>
              {diff[k][1]}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default class TaskLogsPage extends Component {
  state = {
    loading: true,
    logs: []
  };

  componentDidMount() {
    getTaskLogs(this.props.id).then(({ data }) => {
      this.setState({ logs: data, loading: false });
    });
  }

  render() {
    const { logs, loading } = this.state;

    if (loading) {
      return <Layout loading />;
    }

    return (
      <Layout>
        <Card>
          <table>
            <thead>
              <tr>
                <th width="100">用户</th>
                <th>改动</th>
                <th width="180">时间</th>
              </tr>
            </thead>
            <tbody>
              {logs.map(
                ({ id, created_at, user, payload }, i) =>
                  i > 0 && (
                    <tr key={id}>
                      <td>{user.name}</td>
                      <td>
                        <Diff
                          left={logs[i - 1].payload.data}
                          right={payload.data}
                        />
                      </td>
                      <td>{moment(created_at).format('lll')}</td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </Card>
      </Layout>
    );
  }
}
