import React, { useState, useEffect } from 'react';
import { AdminLayout, Card, LogItem } from 'components';
import { getLogs } from 'api';
import { t } from 'utils';

const LogPage = () => {
  const [state, setState] = useState({ loading: true, data: [] });

  useEffect(() => {
    getLogs().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  const { loading, data } = state;

  if (loading) return <AdminLayout loading />;

  return (
    <AdminLayout title={t('menu.admin.logs')}>
      <Card title={t('menu.admin.logs')}>
        {data.map(log => (
          <LogItem key={log.id} log={log} />
        ))}
      </Card>
    </AdminLayout>
  );
};

export default LogPage;
