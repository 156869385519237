/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core';
import { Helmet, Loading, Header, Footer } from 'components';
import RVStyles from 'react-vis-styles';

const Layout = ({ title, fluid, header, children, loading, space }) => {
  return (
    <div
      css={css`
        padding-top: 80px;
        @media (max-width: 768px) {
          padding-left: 0;
        }
      `}
    >
      <Helmet title={title} />
      <RVStyles />
      <Global
        styles={{
          hr: css`
            margin: 20px 0;
            border: 0;
            color: #f00;
            background-color: #eee;
            height: 1px;
          `
        }}
      />
      <Header space={space}>{header}</Header>
      <div
        css={[
          css`
            padding-left: 15px;
            padding-right: 15px;
            max-width: 100%;
            width: 840px;
            margin-left: auto;
            margin-right: auto;
          `,
          fluid &&
            css`
              width: 100%;
            `
        ]}
      >
        {loading ? <Loading style={{ minHeight: 200 }} /> : children}
        <Footer />
      </div>
    </div>
  );
};

Layout.defaultProps = {
  loading: false,
  fluid: false
};

export default Layout;
