import React, { useState, useEffect } from 'react';
import { Card, Pair } from 'components';
import { getBTCTaskStatus } from 'api';
import { t } from 'utils';

const BTCTaskStatus = ({ task }) => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(null);

  function load() {
    return getBTCTaskStatus(task.id).then(({ data }) => {
      setStatus(data);
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, [task.id]);

  if (loading) {
    return <Card title={t('task.status')} loading />;
  }

  if (status.type === 'position') {
    return (
      <Card title={t('task.position_status')}>
        <Pair
          label={t('task.vars.btc.base_holdings')}
          value={status.base_holdings}
        />
        <Pair
          label={t('task.vars.btc.unhedged_volume')}
          value={status.unhedged_volume}
        />
      </Card>
    );
  }

  if (status.type === 'grid') {
    return (
      <Card title={t('task.grid_status')}>
        <Pair
          label={t('task.vars.btc.base_holdings')}
          value={status.base_holdings}
        />
        <Pair
          label={t('task.vars.btc.counter_holdings')}
          value={status.counter_holdings}
        />
        <Pair
          label={t('task.vars.btc.last_trading_line')}
          value={status.last_trading_line}
        />
        <Pair
          label={t('task.vars.btc.last_direction')}
          value={t(
            `constants.task.btc.last_direction.${status.last_direction}`
          )}
        />
        <Pair
          label={t('task.vars.btc.insufficient_volume')}
          value={status.insufficient_volume}
        />
        <Pair
          label={t('task.vars.btc.unhedged_volume')}
          value={status.unhedged_volume}
        />
      </Card>
    );
  }

  return null;
};

export default BTCTaskStatus;
