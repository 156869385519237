import 'cross-fetch/polyfill';
import qs from 'qs';
import { navigate } from 'utils';
import config from 'config';

// todo: share with request
const auth = {
  getHeaders() {
    const token = window.localStorage.getItem('token');
    if (token) {
      return { Authorization: 'Bearer ' + token };
    }
    return {};
  },

  fetch(path, options) {
    return fetch(`${config.auth_host}${path}`, options)
      .then(resp => {
        if (resp.status === 401) {
          window.localStorage.clear();
          navigate('/login', { replace: true });

          throw new Error('401');
        } else if (!resp.ok) {
          throw new Error('network error');
        }

        return resp;
      })
      .then(resp => resp.json())
      .then(data => {
        if (data.status === 'error') {
          throw new Error(data.msg);
        }

        return data;
      });
  },

  get(path, params) {
    const search = params ? `?${qs.stringify(params)}` : '';
    return this.fetch(`${path}${search}`, {
      method: 'GET',
      headers: this.getHeaders()
    });
  },

  post(path, data) {
    return this.fetch(`${path}`, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        ...this.getHeaders(),
        'Content-Type': 'application/json'
      }
    });
  },

  put(path, data) {
    return this.fetch(`${path}`, {
      body: JSON.stringify(data),
      method: 'PUT',
      headers: {
        ...this.getHeaders(),
        'Content-Type': 'application/json'
      }
    });
  },

  delete(path) {
    return fetch(`${path}`, {
      method: 'DELETE',
      headers: this.getHeaders()
    }).then(resp => resp.json());
  }
};

export default auth;
