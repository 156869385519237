/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import {
  Form,
  FormGroup,
  FormSubmit,
  Button,
  Link,
  Row,
  Col,
  NumberInput,
  UserSelect,
  Select,
  FundSelect,
  BondSecuritySelect
} from 'components';
import { t } from 'utils';

const BondTaskForm = ({ fund, ...props }) => {
  return (
    <Form {...props}>
      <FormGroup
        label={t('bondtask.fund_id')}
        name="fund_id"
        component={FundSelect}
      />

      <FormGroup
        label={t('bondtask.auditors')}
        name="auditors"
        component={UserSelect}
        space="bond"
        multiple
      />

      <Row>
        <Col>
          <FormGroup
            name="securities_code"
            label={t('bondtask.securities_code')}
            component={BondSecuritySelect}
          />
        </Col>
        <Col>
          <FormGroup
            name="trade_side"
            label={t('bondtask.trade_side')}
            component={Select}
            fullWidth
          >
            <option value="buy">
              {t('constants.bondtask.trade_side.buy')}
            </option>
            <option value="sell">
              {t('constants.bondtask.trade_side.sell')}
            </option>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col>
          <FormGroup
            name="expectation_price"
            label={t('bondtask.expectation_price')}
            component={NumberInput}
          />
        </Col>
        <Col>
          <FormGroup
            name="expectation_amount"
            label={t('bondtask.expectation_amount')}
            component={NumberInput}
          />
        </Col>
      </Row>

      <FormGroup
        name="remarks"
        label={t('bondtask.remarks')}
        multiline
        rows="5"
      />

      <div
        css={css`
          display: flex;
        `}
      >
        {fund ? (
          <Button
            appearance="minimal"
            component={Link}
            to={`/bond/funds/${fund.name}`}
          >
            {t('back')}
          </Button>
        ) : null}

        <FormSubmit
          css={css`
            margin-left: auto;
          `}
        >
          {t('submit')}
        </FormSubmit>
      </div>
    </Form>
  );
};

export default BondTaskForm;
