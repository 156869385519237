/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useEffect, useState } from 'react';
import { Layout } from 'components';
import { MdCash } from 'icons';
import { getBondFundList } from 'api';
import { Nav, NavItem, Dropdown, DropdownContent, Item, Caret } from './styled';

const BondLayout = ({ children, ...props }) => {
  const [state, setState] = useState({ loading: true, data: [] });
  const { loading, data } = state;

  useEffect(() => {
    getBondFundList().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  if (loading) return null;

  const header = (
    <Nav style={{ width: 200 }}>
      <NavItem href="/bond/balance" Icon={MdCash} text="menu.bond.balance" />
    </Nav>
  );

  return (
    <Layout {...props} space="bond">
      {children}
    </Layout>
  );
};

export default BondLayout;
