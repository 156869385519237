import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  NumberInput,
  DateInput
} from 'components';
import { t } from 'utils';

const BTCFundUnitForm = props => {
  return (
    <Form {...props}>
      <FormGroup
        label={t('btcfundunit.date')}
        name="date"
        component={DateInput}
      />
      <FormGroup
        label={t('btcfundunit.units')}
        name="units"
        component={NumberInput}
      />
      <FormGroup
        label={t('btcfundunit.reason')}
        name="reason"
        multiline
        rows={4}
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default BTCFundUnitForm;
