import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Layout,
  Card,
  Button,
  Task,
  TaskSteps,
  TaskBasicForm,
  TaskForm,
  alert
} from 'components';
import { createTask, getStrategy } from 'api';
import { t } from 'utils';
import { getParams } from 'utils';
import { Action } from './styled';

const DEFAULT_CONFIGS = {
  btc_position_transit: {
    counter_price_spread: '3.0',
    max_volume_per_trade: '100',
    base_maker_price_offset: '0.5',
    taker_price_threshold: '10',
    base_volume_percentage: '0.5',
    counter_volume_percentage: '0.5',
    counter_market_price_offset: '30'
  },

  btc_grid_arbitrage: {
    counter_price_spread: '3.0',
    max_volume_per_trade: '100',
    base_maker_price_offset: '0.5',
    taker_price_threshold: '10',
    base_volume_percentage: '0.5',
    counter_volume_percentage: '0.5',
    counter_market_price_offset: '30'
  },

  hsce_grid_arbitrage: {
    max_volume_per_trade: '900000',
    base_volume_percentage: '0.8',
    counter_volume_percentage: '0.8',
    counter_market_price_offset: '5.0'
  },

  hsce_position_transit: {
    max_volume_per_trade: '3',
    base_volume_percentage: '0.8',
    counter_volume_percentage: '0.8',
    counter_market_price_offset: '5.0'
  }
};

const DEFAULT_VARS = {
  btc_position_transit: {},
  btc_grid_arbitrage: {},
  hsce_grid_arbitrage: {
    base: '510900.SH',
    counter: 'HHI 2011.HKEX',
    allowed_direction: 'BOTH',
    highest_trading_line: 30,
    lowest_trading_line: -30
  },
  hsce_position_transit: {
    base: 'HHI 2011.HKEX',
    counter: 'HHI 2012.HKEX'
  }
};

const TaskNewPage = ({ navigate, fundId, strategyId }) => {
  const [loading, setLoading] = useState(true);
  const [step, setStep] = useState(1);
  const [task, setTask] = useState(null);
  const [strategy, setStrategy] = useState(null);
  const [creating, setCreating] = useState(false);

  useEffect(() => {
    if (fundId && strategyId) {
      setLoading(true);
      getStrategy(strategyId).then(({ data }) => {
        setStrategy(data);
        setTask({
          fund_id: fundId,
          strategy_id: strategyId,
          configs: {
            ...DEFAULT_CONFIGS[data.name]
          },
          vars: {
            ...DEFAULT_VARS[data.name]
          }
        });
        setStep(2);
        setLoading(false);
      });
    } else if (fundId) {
      setTask({ fund_id: fundId });
      setStep(1);
      setLoading(false);
    } else if (strategyId) {
      setTask({ strategy_id: strategyId });
      setStep(1);
      setLoading(false);
    } else {
      setStep(1);
      setLoading(false);
    }
  }, [fundId, strategyId]);

  function submitTask() {
    if (creating) return;

    setCreating(true);
    createTask(task)
      .then(({ data }) => {
        navigate(`/tasks/${data.id}`);
      })
      .catch(err => {
        console.error(err);
        setCreating(false);
        alert('创建失败');
      });
  }

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <TaskSteps current={step} />

      {step === 1 ? (
        <Card>
          <TaskBasicForm
            task={{ ...task }}
            onSubmit={(values, { setSubmitting }) => {
              const { fund_id, strategy_id } = values;
              setSubmitting(false);
              navigate(
                `/tasks/new?fund_id=${fund_id}&strategy_id=${strategy_id}`
              );
            }}
          />
        </Card>
      ) : null}

      {step === 2 ? (
        <Card>
          <TaskForm
            task={{ ...task }}
            strategy={strategy}
            onCancel={() => {
              navigate('/tasks/new');
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTask(values);
              setSubmitting(false);
              setStep(3);
            }}
          />
        </Card>
      ) : null}

      {step === 3 ? (
        <Fragment>
          <Task task={task} strategy={strategy} />
          <Action>
            <Button
              onClick={() => {
                setStep(2);
              }}
            >
              {t('previous_step')}
            </Button>
            <Button appearance="primary" onClick={submitTask}>
              {t('confirm')}
            </Button>
          </Action>
        </Fragment>
      ) : null}
    </Layout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || '',
    strategyId: params.strategy_id || ''
  };
};

export default connect(mapStateToProps)(TaskNewPage);
