import { request } from 'utils';

export const getRiskPrices = params => request.get('/risk/prices', params);

export * from './users';
export * from './tasks';
export * from './navs';
export * from './strategies';
export * from './funds';
export * from './profit_types';
export * from './profits';
export * from './contracts';
export * from './accounts';
export * from './gateways';
export * from './grids';
export * from './hsce';
export * from './btc';
export * from './bond';
