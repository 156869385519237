import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  DateInput,
  NumberInput
} from 'components';
import { t } from 'utils';

const BondBalanceForm = props => {
  return (
    <Form {...props}>
      <FormGroup
        label={t('bondbalance.date')}
        name="date"
        component={DateInput}
      />
      <FormGroup
        label={t('bondbalance.equity')}
        name="equity"
        component={NumberInput}
      />
      <FormGroup
        label={t('bondbalance.share')}
        name="share"
        component={NumberInput}
      />
      <FormGroup
        label={t('bondbalance.nav')}
        name="nav"
        component={NumberInput}
      />
      <FormGroup
        label={t('bondbalance.acc_nav')}
        name="acc_nav"
        component={NumberInput}
      />
      <FormGroup
        label={t('bondbalance.annualized_returns')}
        name="annualized_returns"
        component={NumberInput}
      />
      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default BondBalanceForm;
