/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import get from 'lodash/get';
import { t } from 'utils';

const Pair = ({ children, label, value, ...props }) => {
  if (label) {
    if (value === undefined) return null;

    return (
      <div
        {...props}
        css={css`
          display: flex;
          align-items: center;

          & + & {
            padding-top: 8px;
            border-top: 1px solid #ececec;
            margin-top: 8px;
          }
        `}
      >
        <div
          css={css`
            margin-right: auto;
            font-weight: bold;
          `}
        >
          {label}
        </div>
        {value}
      </div>
    );
  }

  const [target, path, constant] = children;
  const v = get(target, path);

  if (v === undefined) return null;

  return (
    <div
      css={css`
        display: flex;
        align-items: center;

        & + & {
          padding-top: 8px;
          border-top: 1px solid #ececec;
          margin-top: 8px;
        }
      `}
    >
      <div
        css={css`
          margin-right: auto;
          font-weight: bold;
        `}
      >
        {t(path)}
      </div>
      {v !== '' && constant ? t(`constants.${constant}.${v}`) : v}
    </div>
  );
};

export default Pair;
