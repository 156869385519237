import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { Card, Table } from 'components';
import { t } from 'utils';
import { getTaskOrders } from 'api';

const HSCELocalOrdersTable = ({ id, task }) => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState(null);

  function load() {
    if (task && task.status === 'confirmed') {
      return getTaskOrders(task.id).then(({ data }) => {
        setOrders(data);
      });
    }
    return Promise.resolve();
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 2000);
    return () => clearInterval(timer);
  }, [id]);

  if (loading) {
    return <Card loading style={{ marginTop: 20 }} />;
  }

  return (
    <Card title={t('hsce.local_orders')}>
      {orders && orders.length ? (
        <Table
          columns={COLUMNS}
          rows={orders.map(d => ({
            ...d,
            key: d.id
          }))}
        />
      ) : (
        <Placeholder>暂无挂单</Placeholder>
      )}
    </Card>
  );
};

const COLUMNS = [
  { key: 'createAt', title: t('hsce.order.create_time') },
  { key: 'symbol', title: t('hsce.order.symbol') },
  { key: 'volume', title: t('hsce.order.volume') },
  { key: 'price', title: t('hsce.order.price') },
  {
    key: 'filled_volume',
    title: t('hsce.order.filled_volume')
  },
  {
    key: 'side',
    title: t('hsce.order.side'),
    render: ({ side }) => (
      <span className={side === 'BUY' ? 'red' : 'green'}>
        {t(`constants.order.side.${side}`)}
      </span>
    )
  },
  {
    key: 'status',
    title: t('hsce.order.status'),
    render: ({ status }) => t(`constants.order.status.${status}`)
  }
];

const Placeholder = styled.div`
  text-align: center;
  padding: 0 20px;
  color: #999;
`;

export default HSCELocalOrdersTable;
