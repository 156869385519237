import styles from './monitor.page.module.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'components';
import { getMonitorTemplate } from '../../actions';

class MonitorTemplatePage extends Component {
  state = {
    template: null
  };

  componentDidMount() {
    this.props.dispatch(getMonitorTemplate(this.props.id)).then(({ data }) => {
      this.setState({ template: data });
    });
  }

  render() {
    const { template } = this.state;

    if (!template) {
      return <Layout loading />;
    }

    return (
      <Layout>
        <div className={styles.header}>
          <div className={styles.info}>
            <div className={styles.id}>{template.id}</div>
          </div>
        </div>
        <div className={styles.vars}>
          <VarsTable vars={template.vars} />
        </div>
        <div className={styles.stats}>
          <div>{template.status}</div>
          <div>{template.created}</div>
          <div>{template.modified}</div>
        </div>
      </Layout>
    );
  }
}

const VarsTable = ({ vars }) => (
  <table>
    {Object.keys(vars).map(key => (
      <tr key={key}>
        <td>{key}</td>
        <td>{vars[key].value}</td>
        <td>{vars[key].type}</td>
      </tr>
    ))}
  </table>
);

export default connect()(MonitorTemplatePage);
