import React from 'react';
import { DataTable as Table } from 'components';
import { t } from 'utils';

const { Head, Body, Row, TextHeaderCell, TextCell } = Table;

const BTCTradeHistoryTable = ({ data }) => {
  if (!data.length) return null;

  return (
    <Table>
      <Head>
        {COLUMNS.map(({ key, title, ...props }) => (
          <TextHeaderCell key={key} {...props}>
            {title}
          </TextHeaderCell>
        ))}
      </Head>
      <Body style={{ height: 240 }}>
        {data.map(d => (
          <Row key={d.order_id}>
            <TextCell style={{ minWidth: 150 }}>{d.create_time}</TextCell>
            <TextCell style={{ minWidth: 110 }}>{d.symbol}</TextCell>
            <TextCell>{d.filled_volume}</TextCell>
            <TextCell>{d.avg_price}</TextCell>
            <TextCell style={{ maxWidth: 45 }}>
              <span
                className={
                  d.side === 'BUY' ||
                  d.side === 'OPEN_LONG' ||
                  d.side === 'SETTLE_SHORT'
                    ? 'red'
                    : 'green'
                }
              >
                {t(`constants.order.side.${d.side}`)}
              </span>
            </TextCell>
            <TextCell style={{ maxWidth: 45 }}>
              {t(`constants.order.status.${d.status}`)}
            </TextCell>
          </Row>
        ))}
      </Body>
    </Table>
  );
};

const COLUMNS = [
  {
    key: 'create_time',
    title: t('btc.order.create_time'),
    style: { minWidth: 150 }
  },
  { key: 'symbol', title: t('btc.order.symbol'), style: { minWidth: 110 } },
  {
    key: 'filled_volume',
    title: t('btc.order.filled_volume')
  },
  {
    key: 'avg_price',
    title: t('btc.order.avg_price')
  },
  {
    key: 'side',
    title: t('btc.order.side'),
    style: { maxWidth: 45 }
  },
  {
    key: 'status',
    title: t('btc.order.status'),
    style: { maxWidth: 45 }
  }
];

export default BTCTradeHistoryTable;
