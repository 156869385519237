import { request } from 'utils';

import { MONITOR_VARS } from './constants';

export const getMonitors = () => () => request.get('/monitors');

export const getMonitorTemplates = () => () =>
  request.get('/monitors/templates');

export const getMonitorTemplate = id => () =>
  request.get(`/monitors/templates/${id}`);

export const createMonitor = monitor => () => {
  var display = monitor.exchange + '_' + monitor.symbol + '_' + monitor.prompt;
  display = display.toUpperCase();
  const id = (monitor.template + '_' + display).toUpperCase();
  const idVar = monitor.template.toUpperCase();
  const vars = {
    idVar: genVar(idVar, 'string'),
    exchange: genVar(monitor.exchange.toLowerCase(), 'string'),
    symbol: genVar(monitor.symbol, 'string'),
    prompt: genVar(monitor.prompt, 'string'),
    display: genVar(display, 'string'),
    upperLimit: genVar(monitor.upperLimit, 'float'),
    lowerLimit: genVar(monitor.lowerLimit, 'float'),
    suppress: genVar('30m', 'duration')
  };
  const params = {
    'template-id': monitor.template,
    id: id,
    status: monitor.status,
    vars: vars
  };
  return request.post('/monitors', params);
};

export const getMonitor = id => () => request.get(`/monitors/${id}`);

export const updateMonitorStatus = (id, status) => () =>
  request.put(`/monitors/${id}/${status}`);

export const updateMonitor = (id, monitor) => () => {
  var display = monitor.exchange + '_' + monitor.symbol + '_' + monitor.prompt;
  display = display.toUpperCase();
  const idVar = monitor.template.toUpperCase();
  const vars = {
    idVar: genVar(idVar, 'string'),
    exchange: genVar(monitor.exchange.toLowerCase(), 'string'),
    symbol: genVar(monitor.symbol, 'string'),
    prompt: genVar(monitor.prompt, 'string'),
    display: genVar(display, 'string'),
    upperLimit: genVar(monitor.upperLimit, 'float'),
    lowerLimit: genVar(monitor.lowerLimit, 'float'),
    suppress: genVar('30m', 'duration')
  };
  const params = {
    status: monitor.status,
    vars: vars
  };
  return request.put(`/monitors/${id}`, params);
};

export const deleteMonitor = id => () => request.delete(`/monitors/${id}`);

const genVar = (value, type) => {
  return {
    type: type,
    value: value
  };
};
