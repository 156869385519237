import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import { getUsers } from 'api';
import { Select } from 'components';

const UserSelect = ({ multiple, role, space, ...props }) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getUsers({ role, space }).then(({ data }) => setOptions(data));
  }, [role, space]);

  if (multiple) {
    return (
      <ReactSelect
        isMulti
        value={props.value
          .map(id => {
            const option = options.filter(d => d.id === id)[0];
            if (option) {
              return { label: option.name, value: option.id };
            }
            return null;
          })
          .filter(d => d)}
        onChange={v =>
          props.onChange(
            v.map(d => d.value),
            props.name
          )
        }
        style={{ width: '100%' }}
        options={options.map(d => ({
          label: d.name,
          value: d.id
        }))}
      />
    );
  }

  return (
    <Select style={{ width: '100%' }} {...props}>
      <option value="" />
      {options.map(d => (
        <option key={d.name} value={d.id}>
          {d.name}
        </option>
      ))}
    </Select>
  );
};

export default UserSelect;
