import React from 'react';
import { Select } from 'components';

// todo: locale
const GatewayTypeSelect = props => {
  return (
    <Select {...props} style={{ width: '100%' }}>
      <option value="" />
      <option value="MD">MD</option>
      <option value="OU">OU</option>
      <option value="tradeAPI">tradeAPI</option>
    </Select>
  );
};

export default GatewayTypeSelect;
