/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, Fragment } from 'react';
import {
  Row,
  Col,
  Button,
  BTCAllowedDirectionSelect,
  TextInput,
  confirm,
  alert,
  Form,
  FormGroup,
  FormSubmit
} from 'components';
import { updateTaskStatus, updateBTCTask } from 'api';
import {
  t,
  useCurrentUser,
  canPauseTask,
  canResumeTask,
  canFinishTask
} from 'utils';

const BTCTaskForm = ({ task, fund, strategy }) => {
  const [pausing, setPausing] = useState(false);
  const [resuming, setResuming] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const { user } = useCurrentUser();

  function pauseTask() {
    if (pausing) return;
    confirm('暂停任务?').then(() => {
      updateTaskStatus(task.id, 'paused').then(() => {
        setPausing(false);
        window.location.reload();
      });
    });
  }

  function resumeTask() {
    if (resuming) return;
    confirm('启动任务?').then(() => {
      updateTaskStatus(task.id, 'confirmed').then(() => {
        setResuming(false);
        window.location.reload();
      });
    });
  }

  function finishTask() {
    if (finishing) return;
    confirm('停止任务?').then(() => {
      updateTaskStatus(task.id, 'finished').then(() => {
        setFinishing(false);
        window.location.reload();
      });
    });
  }

  function handleSubmit(values, { setSubmitting }) {
    updateBTCTask(task.id, values)
      .then(() => {
        window.location.reload();
        setSubmitting(false);
      })
      .catch(() => {
        alert('更新错误');
        setSubmitting(false);
      });
  }

  return (
    <Form
      initialValues={{
        baseline_diff: task.vars.baseline_diff,
        task_volume: task.vars.task_volume,
        allowed_direction: task.vars.allowed_direction
      }}
      onSubmit={handleSubmit}
    >
      <TaskFields strategy={strategy} task={task} />

      <div
        css={css`
          text-align: right;
          margin-top: 10px;
          button {
            margin-left: 10px;
          }
        `}
      >
        {task.status === 'confirmed' && canPauseTask(user, fund) ? (
          <Button onClick={pauseTask} loading={pausing}>
            {t('task.pause')}
          </Button>
        ) : null}
        {task.status === 'paused' && canResumeTask(user, fund) ? (
          <Button onClick={resumeTask} loading={resuming}>
            {t('task.resume')}
          </Button>
        ) : null}

        {canFinishTask(user, fund) ? (
          <Button onClick={finishTask} loading={finishing}>
            {t('task.finish')}
          </Button>
        ) : null}

        <FormSubmit>{t('update')}</FormSubmit>
      </div>
    </Form>
  );
};

const TaskFields = ({ strategy, task }) => {
  if (strategy.name === 'btc_position_transit') {
    return (
      <Fragment>
        <Row>
          <Group
            label={t('task.vars.btc.baseline_diff')}
            name="baseline_diff"
          />
          <Group label={t('task.vars.btc.task_volume')} name="task_volume" />
        </Row>

        <Row style={{ marginBottom: 15 }}>
          <Col>
            <b>{t('task.vars.btc.base')}</b>
            <br />
            {t(`constants.contracts.${task.vars.base}`)}
          </Col>
          <Col>
            <b>{t('task.vars.btc.counter')}</b>
            <br />
            {t(`constants.contracts.${task.vars.counter}`)}
          </Col>
          <Col>
            <b>{t('task.vars.btc.trading_type')}</b>
            <br />
            {t(`constants.task.btc.trading_type.${task.vars.trading_type}`)}
          </Col>
        </Row>
      </Fragment>
    );
  } else if (strategy.name === 'btc_grid_arbitrage') {
    return (
      <Fragment>
        <Row>
          <Group
            label={t('task.vars.btc.baseline_diff')}
            name="baseline_diff"
          />
          <Group
            label={t('task.vars.btc.allowed_direction')}
            name="allowed_direction"
            component={BTCAllowedDirectionSelect}
          />
        </Row>

        <Row style={{ marginBottom: 15 }}>
          <Col>
            <b>{t('task.vars.btc.base')}</b>
            <br />
            {t(`constants.contracts.${task.vars.base}`)}
          </Col>
          <Col>
            <b>{t('task.vars.btc.counter')}</b>
            <br />
            {t(`constants.contracts.${task.vars.counter}`)}
          </Col>
        </Row>
        <Row>
          <Col>
            <b>{t('task.vars.btc.line_count')}</b>
            <br />
            {task.vars.line_count}
          </Col>
          <Col>
            <b>{t('task.vars.btc.line_width')}</b>
            <br />
            {task.vars.line_width}
          </Col>
          <Col>
            <b>{t('task.vars.btc.counter_volume_unit')}</b>
            <br />
            {task.vars.counter_volume_unit}
          </Col>
        </Row>
      </Fragment>
    );
  }

  return null;
};

const Group = props => {
  return (
    <Col>
      <FormGroup {...props} />
    </Col>
  );
};

Group.defaultProps = {
  component: TextInput
};

export default BTCTaskForm;
