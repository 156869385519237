import isEmpty from 'lodash/isEmpty';
import React from 'react';
import {
  Form,
  FormGroup,
  FormSubmit,
  Row,
  Col,
  FundSelect,
  StrategySelect,
  useForm
} from 'components';
import { t } from 'utils';

const TaskBasicForm = ({ task, onSubmit }) => {
  function validate(values) {
    const errors = {};
    if (!values.fund_id) {
      errors.fund_id = t('not_empty');
    }

    if (!values.strategy_id) {
      errors.strategy_id = t('not_empty');
    }
    return errors;
  }

  return (
    <Form initialValues={task} onSubmit={onSubmit} validate={validate}>
      <Row>
        <Col>
          <FormGroup
            label={t('task.fund_id')}
            name="fund_id"
            component={FundSelect}
          />
        </Col>
        <Col>
          <FormGroup
            label={t('task.strategy_id')}
            component={TaskStrategySelect}
            name="strategy_id"
          />
        </Col>
      </Row>

      <div className="text-right">
        <FormSubmit>{t('next_step')}</FormSubmit>
      </div>
    </Form>
  );
};

const TaskStrategySelect = props => {
  const { getValue } = useForm();

  return <StrategySelect {...props} fundId={getValue('fund_id')} />;
};

export default TaskBasicForm;
