import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormSubmit,
  StrategySelect
} from 'components';
import { t } from 'utils';

const GridForm = ({ values, onSubmit }) => {
  return (
    <Form initialValues={values} onSubmit={onSubmit}>
      <Row>
        <Col>
          <FormGroup label={t('grid.lines')} name="lines" />
        </Col>
        <Col>
          <FormGroup
            label={t('grid.strategy_id')}
            name="strategy_id"
            component={StrategySelect}
          />
        </Col>
      </Row>

      <FormSubmit>{t('submit')}</FormSubmit>
    </Form>
  );
};

export default GridForm;
