import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout, Card } from 'components';
import { createMonitor } from '../../actions';
import MonitorForm from './MonitorForm';

class MonitorNewPage extends Component {
  handleSubmit = (monitor, { setSubmitting }) => {
    this.props.dispatch(createMonitor(monitor)).then(data => {
      setSubmitting(false);
      this.props.navigate(`/monitors/${data.data.id}`);
    });
  };

  render() {
    return (
      <Layout>
        <Card>
          <MonitorForm
            monitor={{
              template: 'future_price_threshold',
              exchange: 'BITMEX',
              prompt: 'perpetual',
              symbol: 'XBTUSD',
              status: 'enabled'
            }}
            onSubmit={this.handleSubmit}
          />
        </Card>
      </Layout>
    );
  }
}

export default connect()(MonitorNewPage);
