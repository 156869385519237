export const canCreateTask = (user, fund) =>
  user.role === 'admin' ||
  fund.manager_ids.indexOf(user.id) >= 0 ||
  fund.trader_ids.indexOf(user.id) >= 0;

export const canEditTask = (user, fund) =>
  user.role === 'admin' || fund.manager_ids.indexOf(user.id) >= 0;

export const canDeleteTask = (user, fund) =>
  user.role === 'admin' || fund.manager_ids.indexOf(user.id) >= 0;

export const canPauseTask = (user, fund) =>
  user.role === 'admin' ||
  fund.manager_ids.indexOf(user.id) >= 0 ||
  fund.trader_ids.indexOf(user.id) >= 0;

export const canResumeTask = (user, fund) =>
  user.role === 'admin' ||
  fund.manager_ids.indexOf(user.id) >= 0 ||
  fund.trader_ids.indexOf(user.id) >= 0;

export const canFinishTask = (user, fund) =>
  user.role === 'admin' || fund.manager_ids.indexOf(user.id) >= 0;

export const canConfirmTask = (user, fund) =>
  user.role === 'admin' || fund.manager_ids.indexOf(user.id) >= 0;

export const canEditBondTask = (user, task) => task.current_vertex_id === 'v0';

export const canCloseFund = (user, fund) =>
  fund && (user.role === 'admin' || fund.manager_ids.indexOf(user.id) >= 0);
