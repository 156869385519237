import styles from './monitor-item.module.scss';
import moment from 'moment';
import React from 'react';
import { Card } from 'components';
import { t, navigate } from 'utils';

const MonitorItem = ({ monitor }) => (
  <Card
    key={monitor.id}
    className={styles.container}
    interactive
    onClick={() => navigate(`/monitors/${monitor.id}`)}
  >
    <div className={styles.info}>
      <div className={styles.title}>
        <span>{monitor.display}</span>
        <span>{'超出范围: ('}</span>
        <span className={styles.status}>
          {monitor.lowerLimit + ', ' + monitor.upperLimit}
        </span>
        <span>{') 报警'}</span>
      </div>
      <div className={styles.status}>
        {moment(monitor.modified).format('LLL')}
      </div>
    </div>
    <div className={styles.status}>
      {t(`constants.monitor_status.${monitor.status}`)}
    </div>
  </Card>
);

export default MonitorItem;
