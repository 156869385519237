/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { useState, useEffect } from 'react';
import { BTCLayout, Card, Link, Pair, SimpleFormat } from 'components';
import { getBTCFundUnit } from 'api';
import { t, formatDate } from 'utils';

const BTCFundUnitPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getBTCFundUnit(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  if (loading) return <BTCLayout loading />;

  return (
    <BTCLayout>
      <Card
        title={formatDate(data.date)}
        extra={<Link to={`/fund_units/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('btcfundunit.units')} value={data.units} />

        <label
          css={css`
            display: block;
            margin-bottom: 8px;
            color: #333;
            font-weight: bold;
          `}
        >
          {t('btcfundunit.reason')}
        </label>
        <div
          css={css`
            margin-bottom: 12px;
            min-height: 37px;
          `}
        >
          <SimpleFormat>{data.reason}</SimpleFormat>{' '}
        </div>
      </Card>
    </BTCLayout>
  );
};

export default BTCFundUnitPage;
