import * as d3 from 'd3';
import React, { useState, useEffect } from 'react';
import {
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
  LineSeries,
  Crosshair
} from 'react-vis';

const LEFT = 55;
const RIGHT = 10;
const TOP = 10;
const BOTTOM = 40;
const HEIGHT = 300;

const BTCChart = ({ id, series, type, xAxis, yAxis, crosshairTitleFormat }) => {
  const [crosshair, setCrosshair] = useState([]);

  useEffect(() => {
    if (!id) return;
    if (!series[0].data.length) return;

    const ys = series.map(s => s.data.map(d => d.y));
    const minY = d3.min(ys, a => d3.min(a));
    const maxY = d3.max(ys, a => d3.max(a));

    const scaleY = d3
      .scaleLinear()
      .domain([minY, maxY])
      .range([HEIGHT - TOP - BOTTOM, 0]);

    const svg = d3.select(`#${id} svg`);
    let cross = svg.select('g.cross');
    cross = cross.size() > 0 ? cross : svg.append('g').attr('class', 'cross');

    let line = cross.select('line');
    line =
      line.size() > 0
        ? line
        : cross
            .append('line')
            .attr('x1', 0)
            .attr('y1', 0)
            .attr('x2', 0)
            .attr('y2', 0)
            .style('stroke', 'gray')
            .style('stroke-width', '1px')
            .style('stroke-dasharray', '5,5')
            .style('display', 'none');

    let text = cross.select('text');
    text =
      text.size() > 0
        ? text
        : cross
            .append('text')
            .style('font-size', '10px')
            .style('stroke', 'gray')
            .style('stroke-width', '0.5px');

    svg
      .on('mousemove', function() {
        const [_, y] = d3.mouse(this);
        const width = svg.node().clientWidth;

        line
          .attr('x1', LEFT)
          .attr('y1', y)
          .attr('x2', width - RIGHT)
          .attr('y2', y)
          .style('display', 'block');

        text
          .attr('transform', `translate(${LEFT}, ${y - 5})`)
          .text(scaleY.invert(y - TOP));
      })
      .on('mouseover', function() {
        cross.style('display', 'block');
      })
      .on('mouseout', function() {
        cross.style('display', 'none');
      });

    return () => {
      svg
        .on('mousemove', null)
        .on('mouseover', null)
        .on('mouseout', null);
    };
  }, [id, series]);

  function handleMouseLeave() {
    setCrosshair([]);
  }

  function handleNearestX(value, { index }) {
    setCrosshair(series.map(s => s.data[index]));
  }

  return (
    <div id={id}>
      <FlexibleWidthXYPlot
        height={HEIGHT}
        onMouseLeave={handleMouseLeave}
        margin={{ left: LEFT, right: RIGHT, top: TOP, bottom: BOTTOM }}
      >
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis {...xAxis} />
        <YAxis {...yAxis} />

        {type === 'bar' ? (
          <VerticalBarSeries
            data={series[0].data}
            onNearestX={handleNearestX}
            style={{
              strokeLinejoin: 'round',
              strokeWidth: 1
            }}
          />
        ) : (
          <LineSeries
            data={series[0].data}
            curve="curveMonotoneX"
            onNearestX={handleNearestX}
            {...(series[0].disabled ? { opacity: 0.2 } : null)}
          />
        )}
        {series.slice(1).map((s, i) => (
          <LineSeries
            key={i}
            data={s.data}
            curve="curveMonotoneX"
            {...(series[i + 1].disabled ? { opacity: 0.2 } : null)}
          />
        ))}
        <Crosshair
          values={crosshair}
          crosshairTitleFormat={crosshairTitleFormat}
          titleFormat={() => null}
          itemsFormat={values =>
            values.map((v, i) => ({
              title: series[i].title,
              value: v ? v.y : ''
            }))
          }
        />
      </FlexibleWidthXYPlot>
    </div>
  );
};

export default BTCChart;
