import styles from './monitor-item.module.scss';
import React from 'react';
import { Card } from 'components';
import { navigate } from 'utils';

const MonitorTemplateItem = ({ template }) => (
  <Card
    key={template.id}
    className={styles.container}
    interactive
    onClick={() => navigate(`/monitors/templates/${template.id}`)}
  >
    <div className={styles.info}>
      <div className={styles.id}>{template.id}</div>
      <div className={styles.status}>{template.status}</div>
      <div className={styles.status}>{template.modified}</div>
    </div>
  </Card>
);

export default MonitorTemplateItem;
