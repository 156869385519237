import React from 'react';
import { t } from 'utils';
import {
  Button,
  TaskConfigsFields,
  TaskVarsFields,
  Form,
  FormSubmit
} from 'components';
import { Action, Title } from './styled';

const TaskForm = ({ task, strategy, onSubmit, onCancel }) => {
  function handleChange(name, value, { setValue }) {
    if (name === 'vars.base') {
      if (value.match(/^okex/i)) {
        setValue('configs.max_volume_per_trade', '100');
        setValue('configs.base_maker_price_offset', '0.05');
      } else if (value.match(/^bitmex/i)) {
        setValue('configs.max_volume_per_trade', '10000');
        setValue('configs.base_maker_price_offset', '0.5');
      }
    }

    setValue(name, value);
  }

  return (
    <Form initialValues={task} onSubmit={onSubmit} onChange={handleChange}>
      <Title>{t('task.title.vars')}</Title>
      <TaskVarsFields task={task} strategy={strategy} />

      <Title>{t('task.title.configs')}</Title>
      <TaskConfigsFields task={task} strategy={strategy} />

      {task.id ? (
        <FormSubmit>{t('confirm')}</FormSubmit>
      ) : (
        <Action>
          <Button type="button" onClick={onCancel}>
            {t('previous_step')}
          </Button>
          <FormSubmit>{t('confirm')}</FormSubmit>
        </Action>
      )}
    </Form>
  );
};

// todo
function validate(values) {
  const { task_type } = this.props.task;
  const { trading_type, task, config, grid } = values;
  const errors = {};

  if (task_type === 'position' && !trading_type) {
    errors.trading_type = t('not_empty');
  }

  Object.keys(task).forEach(k => {
    if (k !== 'contract_pair') {
      if (task[k] === '' || typeof task[k] !== 'number') {
        errors[`task.${k}`] = t('not_empty');
      }
    }
  });

  if (!task.contract_pair.base) {
    errors['task.contract_pair.base'] = t('not_empty');
  }

  if (!task.contract_pair.counter) {
    errors['task.contract_pair.counter'] = t('not_empty');
  }

  Object.keys(config).forEach(k => {
    if (config[k] === '' || typeof config[k] !== 'number') {
      errors[`config.${k}`] = t('not_empty');
    }
  });

  if (task_type === 'grid') {
    Object.keys(grid).forEach(k => {
      if (grid[k] === '' || typeof grid[k] !== 'number') {
        errors[`grid.${k}`] = t('not_empty');
      }

      if (k === 'base_contract_holdings' && grid[k] < 0) {
        errors[`grid.${k}`] = '不能为负';
      }
    });
  }

  return errors;
}

export default TaskForm;
