import 'moment';
import 'moment/locale/zh-cn';
import I18n from 'i18n-js';
import zh from '../../locales/zh.yml';

window.I18n = I18n;

I18n.fallbacks = true;
I18n.locale = 'zh';
I18n.defaultLocale = 'zh';
I18n.translations = {
  zh
};

const missing = I18n.missingTranslation;

I18n.missingTranslation = function(...args) {
  const s = missing.bind(I18n)(...args);
  console.warn(s);
  return s;
};

if (process.env.NODE_ENV === 'production') {
  I18n.missingBehaviour = 'guess';
}

export const updateConstants = (name, values) => {
  Object.keys(I18n.translations).forEach(k => {
    const trans = I18n.translations[k];
    trans.constants[name] = trans.constants[name] || {};

    if (values && values.length) {
      values.forEach(x => {
        trans.constants[name][x.name] = x.display;
      });
    }
  });
};

export const t = x => I18n.t(x);
export default I18n;
