import React, { useState, useEffect } from 'react';
import { Card, SeriesChart } from 'components';
import { getHSCEExchange } from 'api';

const HSCEExchange = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(null);

  function load() {
    return getHSCEExchange().then(({ data }) => {
      setData(
        data[0].Series.map(s => ({
          title: s.columns[1],
          data: s.values
            .map(v => ({
              x: new Date(v[0]).getTime(),
              y: v[1]
            }))
            .filter(({ y }) => y)
        }))
      );
    });
  }

  useEffect(() => {
    load().then(() => setLoading(false));
    const timer = setInterval(load, 5000);
    return () => clearInterval(timer);
  }, []);

  if (loading) return <Card loading />;

  return (
    <Card>
      <SeriesChart series={data} index={index} setIndex={setIndex} />
    </Card>
  );
};

export default HSCEExchange;
