import React, { Fragment, Component } from 'react';
import { request } from 'utils';
import { Table, Loading } from 'components';

class CloseHighStockList extends Component {
  state = {
    loading: true,
    data: [],
    selected: null,
    selectedLoading: false,
    selectedData: []
  };

  load = () => {
    this.setState({ loading: true });
    request.query(QUERY, { date: this.props.date }).then(({ data }) => {
      this.setState({ loading: false, data: data.ag_stock_daily });
    });
  };

  loadStock = code => {
    this.setState({ selected: code, selectedLoading: true });

    request
      .query(
        `
        query($code: String!, $date: date!) {
          ts_top_inst(
            where: { ts_code: { _eq: $code }, trade_date: { _eq: $date } }
          ) {
            exalter
            buy
            buy_rate
            sell
            sell_rate
            net_buy
          }
        }
      `,
        { date: this.props.date, code }
      )
      .then(({ data }) => {
        this.setState({
          selectedLoading: false,
          selectedData: data.ts_top_inst
        });
      });
  };

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.load();
    }
  }

  render() {
    const { loading, data } = this.state;

    if (loading) return <Loading />;

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>ts_code</th>
              <th>open</th>
              <th>high</th>
              <th>close</th>
              <th>low</th>
            </tr>
          </thead>
          <tbody>
            {data.map(row => (
              <Fragment key={row.ts_code}>
                <tr onClick={() => this.loadStock(row.ts_code)}>
                  <td>{row.ts_code}</td>
                  <td>{row.open}</td>
                  <td>{row.high}</td>
                  <td>{row.close}</td>
                  <td>{row.low}</td>
                </tr>
                {this.state.selected === row.ts_code ? (
                  <tr>
                    <td colSpan="5">
                      {this.state.selectedLoading ? (
                        <Loading />
                      ) : (
                        <Table
                          columns={[
                            { key: 'exalter' },
                            { key: 'buy' },
                            { key: 'buy_rate' },
                            { key: 'sell' },
                            { key: 'sell_rate' },
                            { key: 'net_buy' }
                          ]}
                          rows={this.state.selectedData}
                        />
                      )}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

const QUERY = `
  query($date: date!) {
    ag_stock_daily(
      where: { close_high: { _eq: true }, trade_date: { _eq: $date } }
    ) {
      ts_code
      open
      high
      close
      low
    }
  }
`;

export default CloseHighStockList;
