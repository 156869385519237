import React from 'react';
import {
  Row,
  Col,
  Form,
  FormGroup,
  FormSubmit,
  FundSelect,
  AccountTypeSelect
} from 'components';
import { t } from 'utils';

const AccountForm = ({ values, onSubmit }) => (
  <Form initialValues={values} onSubmit={onSubmit}>
    <Row>
      <Col>
        <FormGroup
          label={t('account.type')}
          name="type"
          component={AccountTypeSelect}
        />
      </Col>
      <Col>
        <FormGroup label={t('account.name')} name="name" />
      </Col>
      <Col>
        <FormGroup
          label={t('account.fund_id')}
          name="fund_id"
          component={FundSelect}
          style={{ width: '100%' }}
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <FormGroup
          label={t('account.setting.acctType')}
          name="setting.acctType"
        />
      </Col>
      <Col>
        <FormGroup
          label={t('account.setting.acctName')}
          name="setting.acctName"
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <FormGroup
          label={t('account.setting.pub_url')}
          name="setting.pub_url"
          style={{ width: '100%' }}
        />
      </Col>
      <Col>
        <FormGroup
          label={t('account.setting.req_url')}
          name="setting.req_url"
        />
      </Col>
    </Row>

    <Row>
      <Col>
        <FormGroup
          label={t('account.setting.api_key')}
          name="setting.api_key"
        />
      </Col>

      <Col>
        <FormGroup
          label={t('account.setting.api_secret')}
          name="setting.api_secret"
        />
      </Col>
    </Row>

    <FormSubmit>{t('submit')}</FormSubmit>
  </Form>
);

export default AccountForm;
