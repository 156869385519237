import React, { useState, useEffect } from 'react';
import { BTCLayout, Link, Card, Pair } from 'components';
import { getBTCBalance } from 'api';
import { t, formatDate } from 'utils';

const BTCBalancePage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getBTCBalance(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <BTCLayout loading />;

  return (
    <BTCLayout>
      <Card
        title={formatDate(data.date)}
        extra={<Link to={`/balance/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('btcbalance.date')} value={formatDate(data.date)} />
        <Pair label={t('btcbalance.equity')} value={data.equity} />
        <Pair label={t('btcbalance.units')} value={data.units} />
        <Pair label={t('btcbalance.nav')} value={data.nav} />
        <Pair label={t('btcbalance.acc_nav')} value={data.acc_nav} />
      </Card>
    </BTCLayout>
  );
};

export default BTCBalancePage;
