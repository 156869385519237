import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'components';
import { t } from 'utils';
import { getMonitorTemplates } from '../../actions';
import MonitorTemplateItem from './MonitorTemplateItem';

class MonitorTemplateListPage extends Component {
  state = {
    loading: true,
    templates: []
  };

  load = () => {
    this.setState({ loading: true });
    this.props.dispatch(getMonitorTemplates()).then(({ data }) => {
      this.setState({ templates: data, loading: false });
    });
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const { loading, templates } = this.state;

    if (loading) {
      return <Layout loading />;
    }

    return (
      <Layout title={t('monitor.list')}>
        <div>
          {templates.map(template => (
            <MonitorTemplateItem key={template.id} template={template} />
          ))}
        </div>
      </Layout>
    );
  }
}

export default connect()(MonitorTemplateListPage);
