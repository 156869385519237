import React, { useState, useEffect } from 'react';
import { Link, Card, Table } from 'components';
import { getFundList } from 'api';
import { t, formatDate } from 'utils';

const FundList = () => {
  const [state, setState] = useState({ loading: true, data: [] });
  const { loading, data } = state;

  useEffect(() => {
    getFundList().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  if (loading) return <Card title={t('fund.list')} loading />;

  return (
    <Card
      title={t('fund.list')}
      extra={<Link to="/funds/new">{t('fund.new')}</Link>}
    >
      <Table
        getRowKey={({ id }) => id}
        rows={data}
        columns={[
          { key: 'name', title: t('fund.name') },
          { key: 'display', title: t('fund.display') },
          {
            key: 'established_at',
            title: t('fund.established_at'),
            render: ({ established_at }) => formatDate(established_at)
          },
          {
            key: 'action',
            render: ({ id }) => (
              <div className="text-right">
                <Link to={`/funds/${id}`}>{t('view')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/funds/${id}/edit`}>{t('edit')}</Link>
              </div>
            )
          }
        ]}
      />
    </Card>
  );
};

export default FundList;
