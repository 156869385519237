import React, { useState, useEffect } from 'react';
import { Layout, Link, Card, Pair } from 'components';
import { getGateway } from 'api';
import { t } from 'utils';

const GatewayPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getGateway(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  const { loading, data } = state;

  if (loading) return <Layout loading />;

  return (
    <Layout>
      <Card
        title={data.name}
        extra={<Link to={`/gateways/${data.id}/edit`}>{t('edit')}</Link>}
      >
        <Pair label={t('gateway.type')} value={data.type} />
        <Pair label={t('gateway.name')} value={data.name} />
        <Pair label={t('gateway.pub_url')} value={data.pub_url} />
        <Pair label={t('gateway.req_url')} value={data.req_url} />
      </Card>
    </Layout>
  );
};

export default GatewayPage;
