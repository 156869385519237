import React, { useState, useEffect } from 'react';
import {
  AdminLayout,
  Card,
  alert,
  UserForm,
  UserPasswordForm
} from 'components';
import { getUser, updateUser, updateUserPassword } from 'api';
import { t } from 'utils';

const UserEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getUser(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function update(values, { setSubmitting }) {
    return updateUser(id, values).then(() => {
      setSubmitting(false);
      navigate(`/users/${id}`);
    });
  }

  function updatePassword({ password, password_confirm }, { setSubmitting }) {
    if (!password) {
      return alert('密码不能为空');
    }

    if (password !== password_confirm) {
      return alert('密码不一致');
    }

    return updateUserPassword(id, { password }).then(() => {
      alert('密码更新');
      setSubmitting(false);
    });
  }

  const { loading, data } = state;

  if (loading) {
    return <AdminLayout loading />;
  }

  return (
    <AdminLayout title={t('user.edit')}>
      <Card title={t('user.edit')}>
        <UserForm user={data} onSubmit={update} />
      </Card>

      <Card title="修改密码">
        <UserPasswordForm onSubmit={updatePassword} />
      </Card>
    </AdminLayout>
  );
};

export default UserEditPage;
