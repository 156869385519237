/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import moment from 'moment';
import range from 'lodash/range';
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Form,
  FormGroup,
  FormSubmit,
  Select,
  TextInput
} from '@swiftcarrot/kit';

const BondTaskDialog = ({ onCancel, onSubmit, ...props }) => {
  function handleSubmit({ log_entry_created }, { action, setSubmitting }) {
    if (action == 'cancel') {
      onCancel();
      setSubmitting(false);
    } else {
      onSubmit(
        { log_entry_created: log_entry_created.toDate() },
        { action, setSubmitting }
      );
    }
  }

  return (
    <Dialog {...props}>
      <Form
        initialValues={{ log_entry_created: moment() }}
        onSubmit={handleSubmit}
      >
        <DialogHeader>确认操作</DialogHeader>
        <DialogBody>
          <FormGroup
            label="操作时间"
            name="log_entry_created"
            component={DateTimeInput}
          />
        </DialogBody>
        <DialogFooter>
          <FormSubmit appearance="default" action="cancel">
            取消
          </FormSubmit>
          <FormSubmit appearance="primary" style={{ marginLeft: 8 }}>
            确定
          </FormSubmit>
        </DialogFooter>
      </Form>
    </Dialog>
  );
};

// todo: move to kit
const DateTimeInput = ({ value, onChange }) => {
  return (
    <div>
      <TextInput
        type="date"
        value={value.format('YYYY-MM-DD')}
        onChange={v => {
          const [y, m, d] = v.split('-');
          value
            .year(y)
            .month(m - 1)
            .date(d);
          onChange(value);
        }}
      />
      <Select value={value.hours()} onChange={i => onChange(value.hours(i))}>
        {range(0, 24).map(i => (
          <option key={i}>{i}</option>
        ))}
      </Select>
      时
      <Select
        value={value.minutes()}
        onChange={i => onChange(value.minutes(i))}
      >
        {range(0, 60).map(i => (
          <option key={i}>{i}</option>
        ))}
      </Select>
      分
      <Select
        value={value.seconds()}
        onChange={i => onChange(value.seconds(i))}
      >
        {range(0, 60).map(i => (
          <option key={i}>{i}</option>
        ))}
      </Select>
      秒
    </div>
  );
};

export default BondTaskDialog;
