/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Fragment } from 'react';
import styled from '@emotion/styled';
import { Location } from '@reach/router';
import { Link } from 'components';
import { t } from 'utils';

export const Nav = ({ children, ...props }) => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 400px;
        height: 100%;
        max-width: 100%;
        margin-right: 12px;
      `}
    >
      {children}
    </div>
  );
};

export const NavItem = ({
  href,
  Icon,
  text,
  children,
  component,
  ...props
}) => {
  return (
    <Location>
      {({ location }) =>
        jsx(
          component,
          {
            ...props,
            to: href,
            css: [
              css`
                display: flex;
                align-items: center;
                color: #5e72e4;
                opacity: 0.7;
                height: 100%;
                cursor: pointer;

                &:hover {
                  opacity: 1;
                }

                svg {
                  width: 18px;
                }

                @media (max-width: 576px) {
                  svg {
                    display: none;
                  }
                }
              `,
              location.pathname === href &&
                css`
                  opacity: 1;
                  font-weight: bold;
                  box-shadow: inset 0px -3px 0px #5e72e4;
                `
            ]
          },
          <Fragment>
            <Icon
              css={css`
                margin-right: 10px;
                color: #5e72e4;
                fill: currentColor;
                width: 28px;
              `}
            />
            <span
              css={css`
                font-weight: 600;
              `}
            >
              {t(text)}
            </span>

            {children}
          </Fragment>
        )
      }
    </Location>
  );
};

NavItem.defaultProps = {
  component: Link
};

export const Dropdown = styled.div`
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  perspective: 1000px;
`;

export const DropdownContent = styled.div`
  padding: 10px;
  transform-origin: 0px 0px 0px;
  background-color: rgb(255, 255, 255);
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.1) 0px 50px 100px,
    rgba(50, 50, 93, 0.15) 0px 15px 35px, rgba(0, 0, 0, 0.1) 0px 5px 15px;
  border-radius: 4px;
  overflow: hidden;
`;

export const Item = styled(Link)`
  display: block;
  width: 180px;
  padding: 5px 8px;
  border-radius: 5px;

  &:hover {
    background-color: #eee;
  }
`;

export const Caret = styled.div`
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  top: -20px;
  left: calc(50% - 10px);
  z-index: 1;
`;
