import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { HSCELayout, Card, HSCEBalanceForm } from 'components';
import { createHSCEBalance, getHsceBalanceTemplate } from 'api';
import { t, getParams, startOfToday } from 'utils';

const HSCEBalanceNewPage = ({ navigate, fundId }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getHsceBalanceTemplate(fundId).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [fundId]);

  function handleSubmit(values, { setSubmitting }) {
    createHSCEBalance({ ...values, fund_id: fundId })
      .then(({ data }) => {
        navigate(`/balance/${data.id}`);
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  }

  if (loading) return <HSCELayout loading />;

  return (
    <HSCELayout title={t('hscebalance.new')}>
      <Card title={data.fund.display}>
        <HSCEBalanceForm
          balance={{ ...data, date: startOfToday() }}
          onSubmit={handleSubmit}
        />
      </Card>
    </HSCELayout>
  );
};

const mapStateToProps = (state, { location }) => {
  const params = getParams(location);
  return {
    fundId: params.fund_id || ''
  };
};

export default connect(mapStateToProps)(HSCEBalanceNewPage);
