/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Select, BTCLayout, BTCStats, BTCDiffs } from 'components';
import { t, getParams, navigate } from 'utils';

const BTCDashboardPage = () => {
  const parmas = getParams();
  const span = parmas.span || '4h';
  const interval = INTERVALS[span];

  function handleSelectChange(value) {
    navigate(`/?span=${value}`);
  }

  return (
    <BTCLayout fluid>
      <div
        css={css`
          margin-bottom: 20px;
          display: flex;
          align-items: center;
        `}
      >
        <div
          css={css`
            margin-right: auto;
          `}
        >
          {t('menu.btc.index')}
        </div>
        <IntervalSelect value={span} onChange={handleSelectChange} />
      </div>

      <BTCStats span={span} interval={interval} timer={5000} />
      <BTCDiffs span={span} interval={interval} timer={5000} />
    </BTCLayout>
  );
};

const INTERVALS = {
  '1h': '10s',
  '4h': '40s',
  '12h': '2m',
  '24h': '4m',
  '7d': '28m'
};

const IntervalSelect = ({ value, onChange }) => {
  return (
    <Select value={value} onChange={onChange}>
      <option value="1h">1小时</option>
      <option value="4h">4小时</option>
      <option value="12h">12小时</option>
      <option value="24h">1天</option>
      <option value="7d">7天</option>
    </Select>
  );
};

export default BTCDashboardPage;
