import React, { useState, useEffect } from 'react';
import { Card, Link, Table } from 'components';
import { getAccountList } from 'api';
import { t } from 'utils';

const AccountList = () => {
  const [state, setState] = useState({ loading: true, data: [] });

  useEffect(() => {
    getAccountList().then(({ data }) => {
      setState({ loading: false, data });
    });
  }, []);

  const { loading, data } = state;

  if (loading) return <Card title={t('account.list')} loading />;

  return (
    <Card
      title={t('account.list')}
      extra={<Link to="/accounts/new">{t('account.new')}</Link>}
    >
      <Table
        columns={[
          { key: 'type', title: t('account.type') },
          { key: 'name', title: t('account.name') },
          {
            key: 'action',
            render: ({ key }) => (
              <div className="text-right">
                <Link to={`/accounts/${key}`}>{t('view')}</Link>
                &nbsp;|&nbsp;
                <Link to={`/accounts/${key}/edit`}>{t('edit')}</Link>
              </div>
            )
          }
        ]}
        rows={data.map(d => ({ ...d, key: d.id }))}
      />
    </Card>
  );
};

export default AccountList;
