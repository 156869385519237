/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { Card, Avatar, Link } from 'components';
import { getBondTaskLogs } from 'api';
import { t } from 'utils';

const BondTaskLogList = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: [] });
  const { loading, data } = state;

  useEffect(() => {
    getBondTaskLogs(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  if (loading) return <Card title="编辑历史" loading />;

  return (
    <Card title="编辑历史">
      {data.map((log, i) => {
        const prev = data[i + 1]
          ? data[i + 1].payload.data.current_vertex_id
          : null;

        return (
          <Container key={log.id}>
            {log.user && <Avatar id={log.user.id} />}
            {log.user && (
              <Name to={`/users/${log.user.id}`}>{log.user.name}</Name>
            )}
            {prev ? (
              <span>
                {t(
                  `constants.bondtask.status.${prev}-${log.payload.data.current_vertex_id}`
                )}
              </span>
            ) : (
              <Action>{t(`constants.actions.${log.action}`)}</Action>
            )}
            <Time>{moment(log.created).format('LLL')}</Time>
          </Container>
        );
      })}
    </Card>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #ececec;
`;

const Name = styled(Link)`
  margin-left: 10px;
  margin-right: 10px;
`;

const Action = styled.span`
  margin-right: 10px;
`;

const Time = styled.span`
  margin-left: auto;
`;

export default BondTaskLogList;
