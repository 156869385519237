import React from 'react';
import { Layout, Card, AccountForm } from 'components';
import { createAccount } from 'api';
import { t } from 'utils';

const AccountNewPage = ({ navigate }) => {
  return (
    <Layout title={t('account.new')}>
      <Card title={t('account.new')}>
        <AccountForm
          values={{
            fund_id: '',
            name: '',
            type: '',
            setting: {
              acctType: '',
              acctName: '',
              pub_url: '',
              req_url: ''
            }
          }}
          onSubmit={(values, { setSubmitting }) => {
            createAccount(values)
              .then(() => {
                setSubmitting(false);
                navigate('/accounts');
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        />
      </Card>
    </Layout>
  );
};

export default AccountNewPage;
