import styled from '@emotion/styled';
import React from 'react';
import config from 'config';

const Footer = () => {
  return (
    <Container>
      Copyright &copy;
      {new Date().getFullYear()} {config.footer}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 12px;
  color: #999;
`;

export default Footer;
