/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link } from '@reach/router';
import { t, avatarUrl } from 'utils';
import { useCurrentUser } from 'context';
import { Dropdown, DropdownContent, Item, Caret } from './styled';
import config from 'config';

const Header = ({ children, space, dispatch, ...props }) => {
  const { user, setToken } = useCurrentUser();
  const spaces = user.spaces || [];

  return (
    <div
      {...props}
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid #ececec;
        z-index: 10;
        display: flex;
        align-items: center;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
        background-color: #fff;
      `}
    >
      <Logo to="/">
        <img src={config.auth_logo} height="30" />
      </Logo>

      {children}

      <div
        css={css`
          margin-left: auto;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
        `}
      >
        <div
          css={css`
            position: relative;
            &:hover {
              .dropdown {
                display: block;
              }
            }
          `}
        >
          <img src={avatarUrl(user.id)} />
          <Dropdown className="dropdown">
            <Caret />
            <DropdownContent
              css={css`
                position: relative;
                right: 77px;
              `}
            >
              {user.role === 'admin' ? (
                <Item to="/admin">{t('menu.space.admin')}</Item>
              ) : null}

              <Item to="/settings/password">修改密码</Item>

              <Item
                to="/"
                onClick={e => {
                  e.preventDefault();
                  setToken(null);
                }}
              >
                {t('logout')}
              </Item>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const Logo = styled(Link)`
  margin-right: 20px;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  opacity: 0.7;
  cursor: pointer;

  // todo: active
  &:hover {
    opacity: 1;
  }

  @media (max-width: 576px) {
    display: none;
  }
`;

export default Header;
