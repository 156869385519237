import React, { useState, useEffect } from 'react';
import { AdminLayout, Card, FundForm } from 'components';
import { getFund, updateFund } from 'api';
import { t } from 'utils';

const FundEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getFund(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateFund(id, values)
      .then(() => {
        setSubmitting(false);
        navigate(`/funds/${id}`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data } = state;

  if (loading) return <AdminLayout loading />;

  return (
    <AdminLayout title={t('fund.edit')}>
      <Card title={t('fund.edit')}>
        <FundForm
          fund={data}
          initialValues={{
            ...data,
            established_at: new Date(data.established_at)
          }}
          onSubmit={handleSubmit}
        />
      </Card>
    </AdminLayout>
  );
};

export default FundEditPage;
