import { LOGIN, LOGOUT } from './types';

const initialState = {
  token: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'user_save':
      return { ...state, ...action.payload };
    case LOGIN.SUCCESS:
      window.localStorage.user = JSON.stringify(action.payload);
      return { ...state, ...action.payload };
    case LOGOUT:
      window.localStorage.clear();
      return { token: null };
    default:
      return state;
  }
};

export default userReducer;
