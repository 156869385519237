import { startOfDay, setMinutes, setHours } from 'date-fns';
import { max } from 'd3-array';
import React, { Fragment, useState, useEffect } from 'react';
import {
  FlexibleWidthXYPlot,
  DiscreteColorLegend,
  XAxis,
  YAxis,
  LineSeries,
  HorizontalGridLines,
  VerticalGridLines,
  Crosshair
} from 'react-vis';
import { formatTime } from 'utils';
import { Loading } from 'components';
import { getRiskPrices } from 'api';

const HSCERiskChart = () => {
  const [index, setIndex] = useState(null);
  const [state, setState] = useState({ loading: true, series: [] });
  const { loading, series } = state;

  useEffect(() => {
    getRiskPrices().then(({ data }) => {
      const first = new Date(data[0].Series[0].values[0][0]);
      const day = startOfDay(first);
      const s1 = setMinutes(setHours(day, 9), 30).getTime();
      const s2 = setMinutes(setHours(day, 15), 30).getTime();
      const start = setMinutes(setHours(day, 11), 30).getTime();
      const end = setHours(day, 13).getTime();
      const diff = end - start;
      const series = data[0].Series.map(s => ({
        title: s.tags.symbol,
        data: s.values.map(v => ({
          x: new Date(v[0]).getTime(),
          y: v[1]
        }))
      }));

      const y1 = max(series[0].data, d => d.y);
      const y2 = max(series[1].data, d => d.y);
      const scale = y1 / y2;

      setState({
        loading: false,
        scale,
        start,
        end,
        diff,
        s1,
        s2,
        series: [
          {
            title: series[0].title,
            data: series[0].data
              .filter(
                d => typeof d.y === 'number' && (d.x <= start || d.x >= end)
              )
              .map(d => ({
                x: d.x >= end ? d.x - diff : d.x,
                y: d.y
              }))
          },
          {
            title: series[1].title,
            data: series[1].data
              .filter(
                d => typeof d.y === 'number' && (d.x <= start || d.x >= end)
              )
              .map(d => ({
                x: d.x >= end ? d.x - diff : d.x,
                y: d.y * scale
              }))
          }
        ]
      });
    });
  }, []);

  const [disabled, setDisabled] = useState({});

  function handleLegendClick(item, i) {
    if (disabled[i]) {
      setDisabled({ ...disabled, [i]: false });
    } else {
      setDisabled({ ...disabled, [i]: true });
    }
  }

  function handleMouseLeave() {
    setIndex(null);
  }

  function handleNearestX(value, { index }) {
    setIndex(index);
  }

  const items = series.map(({ title }, i) => ({
    title,
    disabled: disabled[i]
  }));

  if (loading) return <Loading />;

  return (
    <Fragment>
      <DiscreteColorLegend
        items={items}
        orientation="horizontal"
        onItemClick={handleLegendClick}
      />

      <FlexibleWidthXYPlot
        height={300}
        margin={{ left: 55, right: 55, top: 10, bottom: 40 }}
        xDomain={[state.s1, state.s2 - state.diff]}
        onMouseLeave={handleMouseLeave}
      >
        <Crosshair
          values={index >= 0 ? series.map(s => s.data[index]) : null}
          titleFormat={values => ({
            title: '时间',
            value: formatTime(
              values[0].x >= state.start
                ? values[0].x + state.diff
                : values[0].x
            )
          })}
          itemsFormat={values => [
            {
              title: series[0].title,
              value: values[0].y.toFixed(3)
            },
            {
              title: series[1].title,
              value: (values[1].y / state.scale).toFixed(2)
            }
          ]}
        />
        <HorizontalGridLines />
        <VerticalGridLines />
        <XAxis
          tickFormat={x => formatTime(x > state.start ? x + state.diff : x)}
          tickValues={[state.s1, state.start, state.s2 - state.diff]}
        />
        <YAxis
          orientation="left"
          title={series[0].title}
          tickFormat={y => y.toFixed(3)}
        />
        <YAxis
          orientation="right"
          title={series[1].title}
          tickFormat={y => (y / state.scale).toFixed(2)}
        />
        <LineSeries
          data={series[0].data}
          curve="curveMonotoneX"
          onNearestX={handleNearestX}
          {...(disabled[0] ? { opacity: 0.2 } : null)}
        />
        <LineSeries
          data={series[1].data}
          curve="curveMonotoneX"
          {...(disabled[1] ? { opacity: 0.2 } : null)}
        />
      </FlexibleWidthXYPlot>
    </Fragment>
  );
};

export default HSCERiskChart;
