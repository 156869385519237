import React from 'react';
import { Layout, Card, Form, FormGroup, FormSubmit, alert } from 'components';
import { updateCurrentUserPassword } from 'api';

const SettingPasswordPage = () => {
  function handleSubmit(
    { old_password, password, password_confirm },
    { setSubmitting }
  ) {
    if (!password) {
      return alert('新密码不能为空');
    }

    if (password !== password_confirm) {
      return alert('新密码不一致');
    }

    updateCurrentUserPassword({ old_password, password })
      .then(() => {
        alert('更新成功');
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        alert('更新失败');
        setSubmitting(false);
      });
  }

  return (
    <Layout>
      <Card title="修改密码">
        <Form
          initialValues={{
            old_password: '',
            password: '',
            password_confirm: ''
          }}
          onSubmit={handleSubmit}
        >
          <FormGroup label="旧密码" type="password" name="old_password" />
          <FormGroup label="新密码" type="password" name="password" />
          <FormGroup
            label="确认新密码"
            type="password"
            name="password_confirm"
          />
          <FormSubmit>更新密码</FormSubmit>
        </Form>
      </Card>
    </Layout>
  );
};

export default SettingPasswordPage;
