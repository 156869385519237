import React, { useState, useEffect } from 'react';
import { BondLayout, Card, BondTaskForm } from 'components';
import { getBondTask, updateBondTask } from 'api';
import { t, navigate } from 'utils';

const BondTaskEditPage = ({ id }) => {
  const [state, setState] = useState({ loading: true, data: null });
  const { loading, data } = state;

  useEffect(() => {
    getBondTask(id).then(({ data }) => {
      setState({ data, loading: false });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    return updateBondTask(id, values)
      .then(() => {
        navigate(`/bond/tasks/${id}`);
        setSubmitting(false);
      })
      .catch(err => {
        console.error(err);
        setSubmitting(false);
      });
  }

  if (loading) return <BondLayout loading />;

  return (
    <BondLayout>
      <Card title={t('bondtask.edit')}>
        <BondTaskForm
          fund={data.task.fund}
          initialValues={data.task}
          onSubmit={handleSubmit}
        />
      </Card>
    </BondLayout>
  );
};

export default BondTaskEditPage;
