import React from 'react';
import { Router } from '@reach/router';
import {
  LogPage,
  AdminDashboardPage,
  GridNewPage,
  GridEditPage,
  GridPage,
  GridListPage,
  UserNewPage,
  UserListPage,
  UserPage,
  UserEditPage,
  FundListPage,
  FundPage,
  FundNewPage,
  FundEditPage,
  StrategyNewPage,
  StrategyEditPage,
  StrategyPage,
  StrategyListPage,
  ContractListPage,
  ContractNewPage,
  ContractPage,
  ContractEditPage,
  MonitorTemplateListPage,
  MonitorTemplatePage,
  MonitorListPage,
  MonitorPage,
  MonitorNewPage,
  MonitorEditPage,
  AccountNewPage,
  AccountEditPage,
  AccountPage,
  AccountListPage,
  GatewayNewPage,
  GatewayEditPage,
  GatewayPage,
  GatewayListPage,
  SettingPasswordPage,
  TaskNewPage,
  TaskPage,
  TaskListPage,
  TaskEditPage,
  TaskLogsPage,
  NotFoundPage
} from 'pages';

const AdminRouter = () => {
  return (
    <Router>
      <AdminDashboardPage path="/admin" />

      <TaskNewPage path="/tasks/new" />
      <TaskPage path="/tasks/:id" />
      <TaskEditPage path="/tasks/:id/edit" />
      <TaskListPage path="/tasks" />
      <TaskLogsPage path="/tasks/:id/logs" />

      <GridNewPage path="/grids/new" />
      <GridEditPage path="/grids/:id/edit" />
      <GridPage path="/grids/:id" />
      <GridListPage path="/grids" />

      <UserNewPage path="/users/new" />
      <UserListPage path="/users" />
      <LogPage path="/users/logs" />
      <UserPage path="/users/:id" />
      <UserEditPage path="/users/:id/edit" />

      <FundListPage path="/funds" />
      <FundPage path="/funds/:id" />
      <FundNewPage path="/funds/new" />
      <FundEditPage path="/funds/:id/edit" />

      <StrategyNewPage path="/strategiesnew" />
      <StrategyEditPage path="/strategies:id/edit" />
      <StrategyPage path="/strategies:id" />
      <StrategyListPage path="/strategies" />

      <StrategyNewPage path="/strategies/new" />
      <StrategyEditPage path="/strategies/:id/edit" />
      <StrategyPage path="/strategies/:id" />
      <StrategyListPage path="/strategies" />

      <ContractListPage path="/contracts" />
      <ContractNewPage path="/contracts/new" />
      <ContractPage path="/contracts/:id" />
      <ContractEditPage path="/contracts/:id/edit" />

      <MonitorTemplateListPage path="/monitors/templates" />
      <MonitorTemplatePage path="/monitors/templates/:id" />
      <MonitorListPage path="/monitors" />
      <MonitorPage path="/monitors/:id" />
      <MonitorNewPage path="/monitors/new" />
      <MonitorEditPage path="/monitors/:id/edit" />

      <AccountNewPage path="/accounts/new" />
      <AccountEditPage path="/accounts/:id/edit" />
      <AccountPage path="/accounts/:id" />
      <AccountListPage path="/accounts" />

      <GatewayNewPage path="/gateways/new" />
      <GatewayEditPage path="/gateways/:id/edit" />
      <GatewayPage path="/gateways/:id" />
      <GatewayListPage path="/gateways" />

      <SettingPasswordPage path="/settings/password" />
      <NotFoundPage default />
    </Router>
  );
};

export default AdminRouter;
