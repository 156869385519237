import React from 'react';
import { Layout, ContractList } from 'components';
import { t } from 'utils';

const ContractListPage = () => {
  return (
    <Layout title={t('contract.list')}>
      <ContractList />
    </Layout>
  );
};

export default ContractListPage;
