import qs from 'qs';
import config from 'config';

export { t } from 'locales';
export { navigate } from '@reach/router';
export request from './request';
export auth from './auth';
export colors from './colors';
export * from './date';

export const avatarUrl = id => `${config.auth_host}/avatar/${id}.svg`;

export const formatFixed = (x, fixed) =>
  parseFloat(parseFloat(x).toFixed(fixed));

export const formatAmount = (x, fixed = 2) => {
  if (x >= 1000 && x < 1000000) {
    return `${formatFixed(x / 1000, fixed)}K`;
  } else if (x >= 1000000 && x < 1000000000) {
    return `${formatFixed(x / 1000000, fixed)}M`;
  } else if (x > 1000000000) {
    return `${formatFixed(x / 1000000000, fixed)}B`;
  }

  return x;
};

export const getParams = (location = window.location) => {
  const params = location.search ? qs.parse(location.search.substr(1)) : null;
  if (params) {
    return params;
  }
  return {};
};

export const flatten = (target, opts) => {
  opts = opts || {};

  var delimiter = opts.delimiter || '.';
  var maxDepth = opts.maxDepth;
  var output = {};

  function step(object, prev, currentDepth) {
    currentDepth = currentDepth || 1;
    Object.keys(object).forEach(function(key) {
      var value = object[key];
      var isarray = opts.safe && Array.isArray(value);
      var type = Object.prototype.toString.call(value);
      var isobject = type === '[object Object]' || type === '[object Array]';

      var newKey = prev ? prev + delimiter + key : key;

      if (
        !isarray &&
        isobject &&
        Object.keys(value).length &&
        (!opts.maxDepth || currentDepth < maxDepth)
      ) {
        return step(value, newKey, currentDepth + 1);
      }

      output[newKey] = value;
    });
  }

  step(target);

  return output;
};

export const taskDiff = (left, right) => {
  left = flatten({ ...left.payload, status: left.status });
  right = flatten({ ...right.payload, status: right.status });

  const diff = Object.keys(left).reduce((x, k) => {
    if (left[k] !== right[k]) {
      x[k] = [left[k], right[k]];
    }

    return x;
  }, {});

  return diff;
};

export { useCurrentUser } from 'context';

export * from './rules';
