import React, { useState, useEffect } from 'react';
import { Layout, Card, GatewayForm } from 'components';
import { getGateway, updateGateway } from 'api';
import { t } from 'utils';

const GatewayEditPage = ({ id, navigate }) => {
  const [state, setState] = useState({ loading: true, data: null });

  useEffect(() => {
    getGateway(id).then(({ data }) => {
      setState({ loading: false, data });
    });
  }, [id]);

  function handleSubmit(values, { setSubmitting }) {
    updateGateway(id, values)
      .then(() => {
        setSubmitting(false);
        navigate(`/gateways/${id}`);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }

  const { loading, data } = state;

  if (loading) return <Layout loading />;

  return (
    <Layout title={t('gateway.edit')}>
      <Card title={t('gateway.edit')}>
        <GatewayForm values={data} onSubmit={handleSubmit} />
      </Card>
    </Layout>
  );
};

export default GatewayEditPage;
